// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";
// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Pricing page components
import Header from "layouts/main/components/Header";
import FooterThree from "layouts/main/components/FooterThree";

import CtaOne from "layouts/main/components/CtaOne";
import CtaThree from "layouts/main/components/CtaThree";
import FeaturesOne from "layouts/main/components/FeaturesOne";
import FeatureTwo from "layouts/main/components/FeaturesTwo";
import AboutUs from "./AboutUs";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { useEffect, useRef } from "react";

// rellax
import Rellax from "rellax";

// typed-js
import * as Typed from "typed.js";

// Images
//import bgImage from "assets/images/bg-reset-cover.jpeg";
import bgImage from "assets/images/row-of-dumbbells.jpg";
function Features() {
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  return (
    <PageLayout>
      <Header active="Features"/>
      <br>
      </br>
      <br>
      </br>
      <MKBox
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
            Features Fit to Scale Your Business<span ref={typedJSRef} />
            </MKTypography>
            
            {/*
            <MKButton color="default" onClick={navigateFreeTrial} sx={{ color: ({ palette: { dark } }) => dark.main }}>
              start free trial
            </MKButton>
            */}
            {/*
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
              Find us on
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <TwitterIcon />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <InstagramIcon />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <LinkedInIcon />
              </MKTypography>
            </MKBox>*/}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          container
          item
          xs={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ mx: "auto", textAlign: "left" }}
        >
          <AboutUs />
          {/*<FeatureTwo /> */}
          </Grid>
          <CtaThree />
      </Card>
      
      <FooterThree />
    </PageLayout>
  );
}

export default Features;
