/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";
import { useNavigate } from "react-router-dom"; 

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import WorkoutProgramTableCell from "layouts/dashboards/trainer/components/WorkoutProgramTable/WorkoutProgramCell";
import { func } from "joi";

function DashboardCard({ title, rows, shadow, leftButtonText, leftButtonLink, rightButtonText, rightButtonLink }) {

  const navigate = useNavigate();
  const navigateToLeftButtonLink = () => navigate(leftButtonLink);
  const navigateToRightButtonLink = () => {
    if (rightButtonLink === "/dashboards/trainer/workout-create") {
      localStorage.removeItem('workout');
    } else if (rightButtonLink === "/dashboards/trainer/program-create") {
      localStorage.removeItem('program');
    }
    navigate(rightButtonLink);
  }
  
  const renderTableCells = rows.map((row, key) => {
    const tableRows = [];
    const rowKey = `row-${key}`;

    Object.entries(row).map(([cellTitle, cellContent]) =>
      Array.isArray(cellContent)
        ? tableRows.push(
            <WorkoutProgramTableCell
              key={cellContent[1]}
              title={cellTitle}
              content={cellContent[1]}
              image={cellContent[0]}
              noBorder={key === rows.length - 1}
            />
          )
        : tableRows.push(
            <WorkoutProgramTableCell
              key={cellContent}
              title={cellTitle}
              content={cellContent}
              noBorder={key === rows.length - 1}
            />
          )
    );

    return <TableRow key={rowKey}>{tableRows}</TableRow>;
  });

  return (
    <TableContainer sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <Grid mx={3}>
        <Table>
          {title ? (
            <TableHead>
              <MDBox component="tr" width="max-content" display="block" mb={1.5}>
                <MDTypography variant="h6" component="td">
                  {title}
                </MDTypography>
              </MDBox>
            </TableHead>
          ) : null}
          <TableBody>{useMemo(() => renderTableCells, [rows])}</TableBody>
        </Table>
      </Grid>
      <Grid px={3} /*px={6}*/ my={1} align="right">
      { leftButtonText && leftButtonLink &&
        
            <MDButton
              variant="gradient"
              color="secondary"
              size="small"
              onClick={navigateToLeftButtonLink}
              px={3}
            >
              {leftButtonText}
            </MDButton>
      }
      &nbsp;
      { rightButtonText && rightButtonLink &&
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              onClick={navigateToRightButtonLink}
              px={3}
            >
              {rightButtonText}
            </MDButton>
      }
      </Grid>
    </TableContainer>
  );
}

// Setting default values for the props of SalesTable
DashboardCard.defaultProps = {
  title: "",
  rows: [{}],
  shadow: true,
};

// Typechecking props for the SalesTable
DashboardCard.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object),
  shadow: PropTypes.bool,
};

export default DashboardCard;
