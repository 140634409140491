import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import DataTable from "examples/Tables/DataTable";
import axios from "axios";

// Data
import dataTableData from "layouts/pages/applications/data-tables/data/dataTableData";
// import workoutTableData from "layouts/pages/applications/data-tables/data/workoutTableData";

function Workouts() {

  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToDashboard = () => navigate("/dashboards/trainer");
  const navigateToCreateWorkout = () => {
    localStorage.removeItem('workout');
    navigate("/dashboards/trainer/workout-create");
  }

  const [workoutTableData, setWorkoutTableData] = useState({
    columns: [
      { Header: "name", accessor: "name", width: "30%" },
      { Header: "emphasis", accessor: "emphasis", width: "25%"},
      { Header: "exercises", accessor: "exercises", width: "25%"},
      { Header: "edit", accessor: "edit", width: "10" },
      { Header: "delete", accessor: "delete", width: "10%" },
    ],
    rows: [],
  });

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await setWorkouts();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/dashboards/trainer/workouts`]);
  
  async function setWorkouts() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/workouts`, {
      refreshToken: refreshToken,
      queryParams: {}
    }, {
      headers: {
        "Authorization": "Bearer " + accessToken,
      }
    })
    .then((response) => {
      for (var i=0; i<response.data.length; i++) {
        // append any new information we want to view or otherwise need from the workout
        response.data[i].link = `/dashboards/trainer/workout/${response.data[i].id}`;
        response.data[i].workoutId = response.data[i].id;
        response.data[i].editButtonFunction = "navigateToEditWorkout";
        response.data[i].workoutName = response.data[i].name;
        response.data[i].deleteButtonFunction = "pressDeleteWorkout";
        if (response.data[i].exercises && response.data[i].exercises.length > 0) {
          response.data[i].numExercises = response.data[i].exercises.length;
        } else {
          response.data[i].numExercsies = 0;
        }
      }
      setWorkoutTableData({
        "columns": [
          { Header: "name", accessor: "name", width: "30%" },
          { Header: "emphasis", accessor: "emphasis", width: "25%"},
          { Header: "exercises", accessor: "exercises", width: "25%"},
          { Header: "edit", accessor: "edit", width: "10" },
          { Header: "delete", accessor: "delete", width: "10%" },
        ],
        "rows": response.data,
      });
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject();
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <Grid mt={6}>
            <MDBox pb={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                My Workouts
              </MDTypography>
              <MDTypography variant="button" color="text">
                Create, view, or edit a workout to use in your programs
              </MDTypography>
            </MDBox>
            <Grid pb={1}>
              <MDButton
                variant="gradient"
                color="secondary"
                size="small"
                onClick={navigateBack}
                pr={3}
              >
                Back
              </MDButton>
              &nbsp;
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={navigateToCreateWorkout}
              >
                Create
              </MDButton>
            </Grid>
            
            <DataTable table={workoutTableData} />
        </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Workouts;
