
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import axios from "axios";
import { ConstructionOutlined } from "@mui/icons-material";

function Clients({ user }) {
    /* Navigation */
    const navigate = useNavigate();
  
    const [clientTableData, setClientTableData] = useState({
      columns: [
        { Header: "client", accessor: "name", width: "40%" },
        { Header: "sex", accessor: "sex", width: "20%" },
        { Header: "age", accessor: "age", width: "20%" },
        { Header: "program", accessor: "programName", width: "20%" },
      ],
      rows: [],
    });

    function _calculateAge(birthday) { // birthday is a date
      var ageDifMs = Date.now() - new Date(birthday).getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
  
    useEffect(() => {
      const abortController = new AbortController();
      const refreshToken = localStorage.getItem("refresh_token");
      const accessToken = localStorage.getItem("access_token");
      if (!(refreshToken === undefined || accessToken === undefined)) {
        const fetchData = async() => await getClients();
        fetchData().catch(console.error);
      }
      return () => {
        abortController.abort();
      };
    }, [`${process.env.REACT_APP_API_URL}/v1/subscription/trainees`]);
    
    async function getClients() {
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/subscription/trainees`, {
        refreshToken: refreshToken,
      }, {
        headers: {
          "Authorization": "Bearer " + accessToken,
        }
      })
      .then((response) => {
        for (var i=0; i<response.data.length; i++) {
          // append any new information we want to view or otherwise need from the client
          response.data[i].age = _calculateAge(response.data[i].dateOfBirth);
          response.data[i].link = `/dashboards/trainer/client/${response.data[i]._id}`;
        }
        setClientTableData({
          columns: [
            { Header: "client", accessor: "name", width: "40%" },
            { Header: "sex", accessor: "sex", width: "20%" },
            { Header: "age", accessor: "age", width: "20%" },
            { Header: "program", accessor: "programName", width: "20%"},
          ],
          rows: response.data,
        });
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject();
      });
    }

    return (
      <DataTable table={clientTableData} />
    );
}

export default Clients;
