import { useState } from "react";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Snackbar from "@mui/material/Snackbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { useMaterialUIController } from "context";
import axios from "axios";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Validation
import validator from "validator";

function DataSubjectAccessRequestForm() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [snackbarText, setSnackbarText] = useState();
  const [show, setShow] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState();
  const toggleSnackbar = () => setShow(!show);
  const hideSnackbar = () => setShow(false);

  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [requestAs, setRequestAs] = useState("");
  const [law, setLaw] = useState("")
  const [requestTo, setRequestTo] = useState("");
  const [text, setText] = useState();
  const [confirmOne, setConfirmOne] = useState();
  const [confirmTwo, setConfirmTwo] = useState();
  const [confirmThree, setConfirmThree] = useState();

  const [validFullName, setValidFullName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validRequestAs, setValidRequestAs] = useState(true);
  const [validLaw, setValidLaw] = useState(true);
  const [validRequestTo, setValidRequestTo] = useState(true);
  const [validText, setValidText] = useState(true);
  const [validConfirmOne, setValidConfirmOne] = useState(false);
  const [validConfirmTwo, setValidConfirmTwo] = useState(false);
  const [validConfirmThree, setValidConfirmThree] = useState(false);


  const errorStyle = ({
    palette: { error },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: error.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
    },
  });

  const successStyle = ({
    palette: { success },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: success.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
    },
  });

  const toastTemplate = (
    <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
      {snackbarText}
      <CloseIcon
        fontSize="medium"
        sx={{ ml: 4, mr: -1, cursor: "pointer" }}
        onClick={toggleSnackbar}
      />
    </MKBox>
  );

  const sleep = ms => new Promise(r => setTimeout(r, ms));

  const sendContactUsEmail = async (e) => {
    e.preventDefault();

    try {
        if (fullName == null || fullName == "") {
            setValidFullName(false)
            throw Error
        }
        if (email == null || !validator.isEmail(email)) {
            setValidEmail(false)
            throw Error
        }
        if (requestAs == null || requestAs == "") {
            setValidRequestAs(false)
            throw Error
        }
        if (law == null || law == "") {
            setValidLaw(false)
            throw Error
        }
        if (requestTo == null || requestTo == "") {
            setValidRequestTo(false)
            throw Error
        }
        if (text == null || text == "") {
            setValidText(false)
            throw Error
        }
        if (confirmOne == null || confirmOne == false) {
            setValidConfirmOne(false)
            throw Error
        }
        if (confirmTwo == null || confirmTwo == false) {
            setValidConfirmTwo(false)
            throw Error
        }
        if (confirmThree == null || confirmThree == false) {
            setValidConfirmThree(false)
            throw Error
        }
        const message = "Name: " + fullName + "\n" +
            "Request as: " + requestAs + "\n" +
            "Law: " + law + "\n" +
            "Request to: " + requestTo + "\n" +
            "Details:" + text + "\n"
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/send-contact-us-email`, {
            fullName: fullName,
            email: email,
            text: message,
        });
        if (response.status == 200) {
            setShow(true);
            setSnackbarColor("success");
            setSnackbarText("Your message was sent successfully.");
            await sleep(1000);
            window.location.reload();
        }
    } catch (err) {
        console.log(err);
        setShow(true);
        setSnackbarColor("error");
        setSnackbarText("There was a problem sending your message. Make sure all required fields are filled before submission.");
    }
  }

  
  return (
    <MKBox component="section" py={0}>
      <MKBox component="section">
        <Container>
            {
                snackbarColor === "success" && 
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={show}
                    autoHideDuration={3000}
                    onClose={hideSnackbar}
                    message={toastTemplate}
                    action={toggleSnackbar}
                    sx={successStyle}
                />
            }
            {
                snackbarColor === "error" && 
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={show}
                    autoHideDuration={3000}
                    onClose={hideSnackbar}
                    message={toastTemplate}
                    action={toggleSnackbar}
                    sx={errorStyle}
                />
            }
            
        </Container>
      </MKBox>
      <MKBox py={3}>
        <MKBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <MKBox width="100%" component="form" method="post" autocomplete="off">
                    <MKBox p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        <MKTypography variant="p">Your name</MKTypography><br></br>
                        <MKInput 
                            label="Enter your name"
                            fullWidth
                            value={fullName}
                            error={!validFullName}
                            onChange={(e) => {
                                setFullName(e.target.value);
                                if (e.target.value) {
                                    if (e.target.value.length > 50) {
                                        // if not valid name
                                        if (validName) {
                                        setValidFullName(false);
                                        }
                                    } else {
                                        setValidFullName(true);
                                    }
                                } else {
                                    setValidFullName(false);
                                }
                            }}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <MKTypography variant="p">What email do you use to login to Fit to Scale?</MKTypography><br></br>
                        <MKInput
                            type="email"
                            label="Enter your email address"
                            fullWidth 
                            value={email}
                            error={!validEmail}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                if (e.target.value && validator.isEmail(e.target.value)) {
                                    setValidEmail(true);
                                } else {
                                    setValidEmail(false);
                                }
                            }}/>
                        </Grid>
                        <Grid item xs={12}>
                        <MKTypography variant="p">You are submitting this request as</MKTypography><br></br>
                            <MKInput
                                type="radio"
                                name="request_as"
                                error={!validRequestAs}
                                onChange={(e) => {
                                    console.log("e.target.value: ", e)
                                    setRequestAs("Person");
                                    setValidRequestAs(true);
                                }}/>
                            <InputLabel> The person, or the parent /guardian of the person whose name appears above.</InputLabel>
                        <MKInput
                            type="radio"
                            name="request_as"
                            error={!validRequestAs}
                            onChange={(e) => {
                                setRequestAs("Agent");
                                setValidRequestAs(true);
                            }}/>
                        <InputLabel> An agent authorized by the consumer to make this request on their behalf.</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <MKBox mb={2}>
                                <MKTypography variant="p">Under the rights of which law are you making this request?</MKTypography><br></br>
                                <Select
                                value={law}
                                label=""
                                error={!validLaw}
                                onChange={(e) => {
                                    setLaw(e.target.value);
                                    setValidLaw(true);
                                }}
                                >
                                <MenuItem value="ccpa">CCPA</MenuItem>
                                <MenuItem value="gdpr">GDPR</MenuItem>
                                <MenuItem value="other">Other</MenuItem>
                                </Select>
                            </MKBox>
                        </Grid>
                        <Grid item xs={12}>
                        <MKTypography variant="p">I am submitting a request to _________</MKTypography><br></br>
                        <MKInput
                            type="radio"
                            name="request_to"
                            error={!validRequestTo}
                            onChange={(e) => {
                                setRequestTo("Know what information is being collected from me");
                                setValidRequestTo(true);
                            }}/>
                        <InputLabel> Know what information is being collected from me</InputLabel>
                        <br></br>
                        <MKInput
                            type="radio"
                            name="request_to"
                            error={!validRequestTo}
                            onChange={(e) => {
                                setRequestTo("Have my information deleted");
                                setValidRequestTo(true);
                            }}/>
                        <InputLabel> Have my information deleted</InputLabel>
                        <br></br>
                        <MKInput
                            type="radio"
                            name="request_to"
                            error={!validRequestTo}
                            onChange={(e) => {
                                setRequestTo("Opt out of having my data sold to third parties");
                                setValidRequestTo(true);
                            }}/>
                        <InputLabel> Opt out of having my data sold to third parties</InputLabel>
                        <br></br>
                        <MKInput
                            type="radio"
                            name="request_to"
                            error={!validRequestTo}
                            onChange={(e) => {
                                setRequestTo("Opt in to the sale of my personal data");
                                setValidRequestTo(true);
                            }}/>
                        <InputLabel> Opt in to the sale of my personal data</InputLabel>
                        <br></br>
                        <MKInput
                            type="radio"
                            name="request_to"
                            error={!validRequestTo}
                            onChange={(e) => {
                                setRequestTo("Access my personal information");
                                setValidRequestTo(true);
                            }}/>
                        <InputLabel> Access my personal information</InputLabel>
                        <br></br>
                        <MKInput
                            type="radio"
                            name="request_to"
                            error={!validRequestTo}
                            onChange={(e) => {
                                setRequestTo("Fix inaccurate information");
                                setValidRequestTo(true);
                            }}/>
                        <InputLabel> Fix inaccurate information</InputLabel>
                        <br></br>
                        <MKInput
                            type="radio"
                            name="request_to"
                            error={!validRequestTo}
                            onChange={(e) => {
                                setRequestTo("Receive a copy of my personal information");
                                setValidRequestTo(true);
                            }}/>
                        <InputLabel> Receive a copy of my personal information</InputLabel>
                        <br></br>
                        <MKInput
                            type="radio"
                            name="request_to"
                            error={!validRequestTo}
                            onChange={(e) => {
                                setRequestTo("Opt out of having my data shared for cross-context behavioral advertising");
                                setValidRequestTo(true)
                            }}/>
                        <InputLabel> Opt out of having my data shared for cross-context behavioral advertising</InputLabel>
                        <br></br>
                        <MKInput
                            type="radio"
                            name="request_to"
                            error={!validRequestTo}
                            onChange={(e) => {
                                setRequestTo("Limit the use and disclosure of my sensitive personal information");
                                setValidRequestTo(true);
                            }}/>
                        <InputLabel> Limit the use and disclosure of my sensitive personal information</InputLabel>
                        <br></br>
                        <MKInput
                            type="radio"
                            name="request_to"
                            error={!validRequestTo}
                            onChange={(e) => {
                                setRequestTo("Other (please specify in the comment box below)");
                                setValidRequestTo(true);
                            }}/>
                        <InputLabel> Other (please specify in the comment box below)</InputLabel>
                        <br></br>
                        </Grid>
                        <Grid item xs={12}>
                            <MKTypography>Please leave details regarding action request or question.</MKTypography>
                            <Grid item xs={12}>
                                <MKInput
                                    label=""
                                    multiline
                                    fullWidth
                                    rows={6}
                                    value={text}
                                    error={!validText}
                                    onChange={(e) => {
                                        setText(e.target.value);
                                        if (e.target.value) {
                                            if (e.target.value.length > 250) {
                                                // if not valid name
                                                if (validName) {
                                                    setValidText(false);
                                                }
                                            } else {
                                                setValidText(true);
                                            }
                                        } else {
                                            setValidText(false);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <MKTypography>I confirm that</MKTypography>
                            <MKBox display="flex" alignItems="center" ml={-1}>
                                <Checkbox
                                    error={!validConfirmOne}
                                    inputProps={{ error: true }}
                                    onChange={(e) => {
                                        setConfirmOne(e.target.checked)
                                        setValidConfirmOne(true)
                                    }}
                                />
                                <MKTypography
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                >
                                    Under penalty of perjury, I declare all the above information to be true and accurate.
                                </MKTypography>
                            </MKBox>
                            <MKBox display="flex" alignItems="center" ml={-1}>
                                <Checkbox
                                    error={!validConfirmTwo}
                                    inputProps={{ error: true }}
                                    onChange={(e) => {
                                        setConfirmTwo(e.target.checked)
                                        setValidConfirmTwo(true)
                                    }}
                                />
                                <MKTypography
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                >
                                    I understand that the deletion or restriction of my personal data is irreversible and may result in the termination of services with Fit to Scale.
                                </MKTypography>
                            </MKBox>
                            <MKBox display="flex" alignItems="center" ml={-1}>
                                <Checkbox
                                    error={!validConfirmThree}
                                    inputProps={{ error: true }}
                                    onChange={(e) => {
                                        setConfirmThree(e.target.checked)
                                        setValidConfirmThree(true)
                                    }}
                                />
                                <MKTypography
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                >
                                    I understand that I will be required to validate my request by email, and I may be contacted in order to complete the request.
                                </MKTypography>
                            </MKBox>
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} my={6}>
                        <MKButton type="submit" variant="gradient" color="info" onClick={sendContactUsEmail}>
                        Submit
                        </MKButton>
                    </Grid>
                    </MKBox>
                </MKBox>
            </Grid>
          </Grid>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

export default DataSubjectAccessRequestForm;
