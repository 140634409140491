// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from "@mui/icons-material/Pinterest";
import GitHubIcon from "@mui/icons-material/GitHub";

// Material Kit 2 PRO React examples
import CenteredFooter from "examples/Footers/CenteredFooter";

function TrainerFooter({name, twitterLink, instagramLink, linkedInLink}) {
  const company = { href: "/", name: name };
  
  /*const socials = [
    {
      icon: <TwitterIcon fontSize="small" />,
      link: twitterLink,
    },
    {
      icon: <InstagramIcon fontSize="small" />,
      link: instagramLink,
    },
    {
        icon: <LinkedInIcon fontSize="small" />,
        link: linkedInLink,
    }
  ];*/

  return <CenteredFooter company={company} socials={[]} />;
}

export default TrainerFooter;
