import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MKBox from "components/MKBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Snackbar from "@mui/material/Snackbar";
import FormField from "layouts/account/components/FormField";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import axios from "axios";
import { ConstructionOutlined, LocalConvenienceStoreOutlined, SettingsSystemDaydreamSharp } from "@mui/icons-material";
// @mui icons
import CloseIcon from "@mui/icons-material/Close";
//import exerciseTableData from "layouts/pages/applications/data-tables/data/exerciseTableData";

async function editWorkout(workoutInfo) {
  if (workoutInfo.name === undefined || workoutInfo.name.trim().length === 0) {
    const message = "please enter a workout name.";
    return { type: ["name"], message: [message] };
  } else if (workoutInfo.name.length > 32) {
    const message = "Please limit the workout name to 32 characters or less.";
    return { type: ["name"], message: [message] };
  }
  if (workoutInfo.emphasis === null || workoutInfo.emphasis === undefined) {
    workoutInfo.emphasis = "";
  } else if (workoutInfo.emphasis.length > 32) {
    const message = "Please limit the emphasis to 32 characters or less.";
    return { type: ["emphasis"], message: [message] };
  }
  //  workoutInfo.emphasis = undefined;
  const accessToken = localStorage.getItem("access_token");
  var response = await axios.patch(`${process.env.REACT_APP_API_URL}/v1/trainer/workout/${workoutInfo.id}`, {
    name: workoutInfo.name,
    emphasis: workoutInfo.emphasis,
    exercises: workoutInfo.exercises,
    refreshToken: workoutInfo.refreshToken,
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }).then((response) => {
    return response;
  }).catch((err) => {
    console.log(err);
    var message = "Please fill in all missing values before saving.";
    return { type: ["exercises"], message: [message] }
  });
  return response;
}

function EditWorkout() {
  const params = useParams();
  var workoutId = params.workoutId;
  var workoutLocal = JSON.parse(localStorage.getItem('workout'));
  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigateToWorkouts = () => navigate("/dashboards/trainer/workouts");
  const navigateToEditAddExercise = () => navigate(`/dashboards/trainer/workout/${workoutId}/add-exercise`);
  /* Values */
  const [workoutName, setWorkoutName] = useState();
  const [emphasis, setEmphasis] = useState();
  const newExercise = JSON.parse(searchParams.get("exercise"));

  const [showSnackbar, setShowSnackbar] = useState(false);
  const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
  const hideSnackbar = () => setShowSnackbar(false);
  const [snackbarText, setSnackbarText] = useState();

  const [exerciseTableData, setExerciseTableData] = useState({
    columns: [
      { Header: "exercise name", accessor: "exerciseName", width: "15%" },
      { Header: "muscle group", accessor: "muscleGroup", width: "15%" },
      { Header: "rep type", accessor: "type", width: "15%"},
      { Header: "sets", accessor: "sets", width: "15%" },
      { Header: "reps/interval", accessor: "reps", width: "15%" },
      { Header: "rest", accessor: "rest", width: "15%"},
      { Header: "delete", accessor: "delete", width: "10%"},
    ],
    rows: [],
  });
  /* Validation */
  const [validName, setValidName] = useState(true);
  const [validEmphasis, setValidEmphasis] = useState(true);
  const [validExercises, setValidExercises] = useState();
  /* Text */
  const [nameHelpText, setNameHelpText] = useState();
  const [emphasisHelpText, setEmphasisHelpText] = useState();
  const [exercisesHelpText, setExercisesHelpText] = useState();

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async() => await setExercises();
    fetchData().catch(console.error);
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/dashboards/trainer/workout/${workoutId}`]);

  async function setExercises() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    workoutLocal = JSON.parse(localStorage.getItem('workout'));

    if (!workoutLocal) {
        await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/workout/${workoutId}`, {
            refreshToken,
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        .then((response) => {
            for (var i=0; i<response.data.length; i++) {        
              // var exerciseData = JSON.stringify(response.data[i]);
              response.data[i].link = `/dashboards/trainer/exercise/${response.data[i].id}`;
              response.data[i].deleteButtonFunction = "editDeleteExercise";
              var theEmphasis;
              if (i == 0) {
                  var workoutName = response.data[i].workoutName;
                  setEmphasis(response.data[i].emphasis);
                  theEmphasis = response.data[i].emphasis;
                  setWorkoutName(workoutName);
                  // remove the empty exercise from view
                  if (response.data[i].id == null) {
                    response.data = [];
                  } 
              }
            }
            setExerciseTableData({
              columns: [
                { Header: "exercise name", accessor: "exerciseName", width: "15%" },
                { Header: "muscle group", accessor: "muscleGroup", width: "15%" },
                { Header: "rep type", accessor: "type", width: "15%"},
                { Header: "sets", accessor: "sets", width: "15%" },
                { Header: "reps/interval", accessor: "reps", width: "15%" },
                { Header: "rest", accessor: "rest", width: "15%"},
                { Header: "delete", accessor: "delete", width: "10%"},
              ],
              rows: response.data,
            });
            localStorage.setItem('workout', JSON.stringify({ workoutName: workoutName, emphasis: theEmphasis, exercises: response.data }));
           
            return Promise.resolve();
        })
        .catch((err) => {
          console.log(err);
          return Promise.reject();
        });
    } else {
        // populate exercise table data from local storage
        for (var i=0; i<workoutLocal.exercises.length; i++) {        
            // var exerciseData = JSON.stringify(response.data[i]);
            workoutLocal.exercises[i].link = `/dashboards/trainer/exercise/${workoutLocal.exercises[i].id}`;
            workoutLocal.exercises[i].deleteButtonFunction = "editDeleteExercise";
        }
        var theWorkoutName =  workoutLocal.workoutName;
        var theEmphasis = workoutLocal.emphasis;
        setWorkoutName(theWorkoutName);
        setEmphasis(theEmphasis);
        setExerciseTableData({
            columns: [
              { Header: "exercise name", accessor: "exerciseName", width: "15%" },
              { Header: "muscle group", accessor: "muscleGroup", width: "15%" },
              { Header: "rep type", accessor: "type", width: "15%"},
              { Header: "sets", accessor: "sets", width: "15%" },
              { Header: "reps/interval", accessor: "reps", width: "15%" },
              { Header: "rest", accessor: "rest", width: "15%"},
              { Header: "delete", accessor: "delete", width: "10%"},
            ],
            rows: workoutLocal.exercises,
        });
        return Promise.resolve();
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    var exerciseIds;
    if (localStorage.getItem('workout') === null) {
      exerciseIds = {exercises: []};
    } else {
      exerciseIds = JSON.parse(localStorage.getItem('workout'));
    }
    // verify and get the exercises from the list of ids
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const response1 = await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/exercises-by-ids`, {
        //workoutId: params.workoutId,
        exerciseIds: exerciseIds.exercises,
        refreshToken: refreshToken,
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    var exercises = response1.data;
    if (emphasis === null) {
      setEmphasis("");
    }
    const response = await editWorkout({
      id: params.workoutId,
      name: workoutName,
      emphasis: emphasis,
      exercises: exercises,
      refreshToken: refreshToken,
    });
    if (response.type && response.message) {
      for (let i = 0; i < response.type.length; i += 1) {
        if (response.type.length === response.message.length) {
          switch (response.type[i]) {
            case "name":
              setValidName(false);
              setNameHelpText(response.message[i]);
              return;
            case "exercises":
              setSnackbarText(response.message[i]);
              setShowSnackbar(true);
              return;
            default:
              break;
          }
        }
      }
    }
    
    if (response.status && response.status === 200) {
      setWorkoutName("");
      setEmphasis("");
      setExerciseTableData({
        columns: [
          { Header: "exercise name", accessor: "exerciseName", width: "15%" },
          { Header: "muscle group", accessor: "muscleGroup", width: "15%" },
          { Header: "rep type", accessor: "type", width: "15%"},
          { Header: "sets", accessor: "sets", width: "15%" },
          { Header: "reps/interval", accessor: "reps", width: "15%" },
          { Header: "rest", accessor: "rest", width: "15%"},
          { Header: "delete", accessor: "delete", width: "10%"},
        ],
        rows: [],
      });
      localStorage.removeItem('workout');
      navigateToWorkouts();
    }
  }

  const errorStyle = ({
    palette: { error },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: error.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
    },
  });

  const toastTemplate = (
    <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
      {snackbarText}
      <CloseIcon
        fontSize="medium"
        sx={{ ml: 4, mr: -1, cursor: "pointer" }}
        onClick={toggleSnackbar}
      />
    </MKBox>
  );

  return (
    <DashboardLayout>
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={hideSnackbar}
          message={toastTemplate}
          action={toggleSnackbar}
          sx={errorStyle}
      />
      <DashboardNavbar />
        <Grid mt={6}>
            <MDBox pb={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Edit Workout
              </MDTypography>
              <MDTypography variant="button" color="text">
                Customize your workout by choosing from your exercises
              </MDTypography>
            </MDBox>
            <MDBox component="form" pb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Workout Name"
                    //placeholder={workoutName}
                    value={workoutName}
                    error={!validName}
                    helperText={nameHelpText}
                    onChange={(e)=>{
                      setWorkoutName(e.target.value);
                      if (e.target.value.length > 0) {
                        if (e.target.value.length > 32) {
                          if (validName) {
                            setValidName(false);
                            setNameHelpText("Please limit the workout name to 32 characters or less.");
                          }
                        } else {
                          setValidName(true);
                          setNameHelpText("");
                        }
                      } else {
                        setValidName(false);
                        setNameHelpText("please enter a workout name.");
                      }
                      var workoutLocal = JSON.parse(localStorage.getItem('workout'));
                      workoutLocal.workoutName = e.target.value;
                      localStorage.setItem('workout', JSON.stringify(workoutLocal));
                    }
                  }/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Emphasis (optional)"
                    value={emphasis}
                    error={!validEmphasis}
                    helperText={emphasisHelpText}
                    onChange={(e)=>{
                      setEmphasis(e.target.value);
                      if (e.target.value) {
                        if (e.target.value.length > 32) {
                          if (validEmphasis) {
                            setValidEmphasis(false);
                            setEmphasisHelpText("Please limit the emphasis to 32 characters or less.");
                          }
                        } else {
                          setValidEmphasis(true);
                          setEmphasisHelpText("");
                        }
                      } else {
                        setValidEmphasis(true);
                        setEmphasisHelpText("");
                      }
                      var workoutLocal = JSON.parse(localStorage.getItem('workout'));
                      workoutLocal.emphasis = e.target.value;
                      localStorage.setItem('workout', JSON.stringify(workoutLocal));
                    }
                  }/>
                </Grid>
              </Grid>
            </MDBox>
            <Grid pb={1}>
              <MDButton
                  variant="gradient"
                  color="secondary"
                  size="small"
                  onClick={navigateBack}
                  pr={3}
                  >
                  Back
              </MDButton>
              &nbsp;
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={navigateToEditAddExercise}
                pr={3}
              >
                Add Exercise
              </MDButton>
              </Grid>
              <Grid pb={1}>
                <DataTable table={exerciseTableData} />
              </Grid>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={handleSubmit}
                pr={3}
              >
                Save
              </MDButton>
          </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default EditWorkout;
