/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Snackbar from "@mui/material/Snackbar";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MKBox from "components/MKBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/account/components/FormField";
// Validation
import validator from "validator";
// Data
import selectData from "layouts/account/settings/components/BasicInfo/data/selectData";
// Restful API
import axios from "axios";
// @mui icons
import CloseIcon from "@mui/icons-material/Close";

async function updateUser(userInfo) {
  // TODO (ereilly89): Ensure we have each of these checks similarly implemented in the API
  if (userInfo.firstName === undefined || userInfo.firstName.trim().length === 0) {
    const message = "please enter your first name.";
    return { type: ["firstName"], message: [message] };
  } else if (userInfo.firstName.trim().length > 24) {
    const message = "please limit your first name to the first 24 characters.";
    return { type: ["firstName"], message: [message] };
  }

  if (userInfo.lastName === undefined || userInfo.lastName.trim().length === 0) {
    const message = "please enter your last name.";
    return { type: ["lastName"], message: [message] };
  } else if (userInfo.lastName.trim().length > 24) {
    const message = "please limit your last name to the first 24 characters.";
    return { type: ["lastName"], message: [message] };
  }
  if (userInfo.sex === undefined || (userInfo.sex.trim() !== "Male" && userInfo.sex.trim() !== "Female" && userInfo.sex.trim() !== "")) {
    const message = "please enter your sex.";
    return { type: ["sex"], message: [message] };
  }

  if (userInfo.birthMonth === "" || userInfo.birthMonth === undefined) {
      const message = "please enter a valid month";
      return { type: ["birthMonth"], message: [message] };
  }

  if (userInfo.birthDay == "" || isNaN(userInfo.birthDay) || parseInt(userInfo.birthDate) > 31) {
    const message = "please enter a valid birth day";
    return { type: ["birthDay"], message: [message] };
  }

  if (userInfo.birthYear == "" || isNaN(userInfo.birthYear) || isNaN(parseInt(userInfo.birthYear)) || parseInt(userInfo.birthYear) < 1900 || parseInt(userInfo.birthYear) > new Date().getFullYear()) {
    const message = "please enter a valid birth year";
    return { type: ["birthYear"], message: [message] };
  }

  if (userInfo.location === undefined || userInfo.location === "") {
    const message = "please enter a location.";
    return { type: ["location"], message: [message] };
  } else if (userInfo.location.trim().length > 25) {
    const message = "please limit the location to the first 25 characters.";
    return { type: ["location"], message: [message] };
  }
  
  if (userInfo.phoneNumber === undefined || !validator.isMobilePhone(userInfo.phoneNumber)) {
    const message = "please enter a valid phone number.";
    return { type: ["phoneNumber"], message: [message] };
  }

  if (userInfo.description === undefined || userInfo.description === "") {
    const message = "please enter your profile description.";
    return { type: ["description"], message: [message] };
  } else if (userInfo.description.trim().length > 256) {
    const message = "please limit your profile description to 256 characters.";
    return { type: ["description"], message: [message] };
  }
  
  if (userInfo.firstName !== "" || userInfo.lastName !== "" || userInfo.phoneNumber !== "" || userInfo.location !== "" || userInfo.sex !== "" || userInfo.birthMonth !== "" || userInfo.birthDay !== "" || userInfo.birthYear !== "" || userInfo.description !== "") {
    const accessToken = localStorage.getItem('access_token');
    var updateValues = {};

    if (userInfo.birthMonth !== "" && userInfo.birthDay !== "" && userInfo.birthyear !== "") {
      var month = "";
      switch(userInfo.birthMonth) {
        case "January":
          month = 1;
          break;
        case "February":
          month = 2;
          break;
        case "March":
          month = 3;
          break;
        case "April":
          month = 4;
          break;
        case "May":
          month = 5;
          break;
        case "June":
          month = 6;
          break;
        case "July":
          month = 7;
          break;
        case "August":
          month = 8;
          break;
        case "September":
          month = 9;
          break;
        case "October":
          month = 10;
          break;
        case "November":
          month = 11;
          break;
        case "December":
          month = 12;
          break;
      }
      userInfo.birthMonth = month;
      const dateOfBirth = new Date(userInfo.birthMonth + "/" + userInfo.birthDay + "/" + userInfo.birthYear);
      updateValues.dateOfBirth = dateOfBirth;
    }
    updateValues.name = userInfo.name;
    updateValues.firstName = userInfo.firstName;
    updateValues.lastName = userInfo.lastName;
    updateValues.phoneNumber = userInfo.phoneNumber;
    updateValues.location = userInfo.location;
    updateValues.sex = userInfo.sex;
    updateValues.description = userInfo.description;
    updateValues.refreshToken = userInfo.refreshToken;
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainee/profile`, updateValues, {
        headers: {
            "Authorization": "Bearer " + accessToken,
        }
    });
    return response;
  } else {
    const message = "nothing to update.";
    return { type: ["main"], message: [message] };
  }
}

function BasicInfo({ user }) {
  const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [sex, setSex] = useState(user.sex);
  const [birthMonth, setBirthMonth] = useState(months[new Date(user.dateOfBirth).getMonth()]);
  const [birthDay, setBirthDay] = useState(new Date(user.dateOfBirth).getDate());
  const [birthYear, setBirthYear] = useState(new Date(user.dateOfBirth).getFullYear());
  const [location, setLocation] = useState(user.location);
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [description, setDescription] = useState(user.description);

  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validSex, setValidSex] = useState(true);
  const [validBirthMonth, setValidBirthMonth] = useState(true);
  const [validBirthYear, setValidBirthYear] = useState(true);
  const [validBirthDay, setValidBirthDay] = useState(true);
  const [validLocation, setValidLocation] = useState(true);
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [validDescription, setValidDescription] = useState(true);

  const [firstNameHelpText, setFirstNameHelpText] = useState();
  const [lastNameHelpText, setLastNameHelpText] = useState();
  const [sexHelpText ,setSexHelpText] = useState();
  const [birthMonthHelpText, setBirthMonthHelpText] = useState();
  const [birthDayHelpText, setBirthDayHelpText] = useState();
  const [birthYearHelpText, setBirthYearHelpText] = useState();
  const [locationHelpText, setLocationHelpText] = useState();
  const [phoneNumberHelpText, setPhoneNumberHelpText] = useState();
  const [descriptionHelpText, setDescriptionHelpText] = useState();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
  const hideSnackbar = () => setShowSnackbar(false);
  const [snackbarColor, setSnackbarColor] = useState();
  const [snackbarText, setSnackbarText] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const refreshToken = localStorage.getItem("refresh_token");
    const response = await updateUser({
      name: (firstName + " " + lastName).trim(),
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      location: location,
      sex: sex,
      birthMonth: birthMonth,
      birthDay: birthDay,
      birthYear: birthYear,
      description: description,
      refreshToken: refreshToken,
    });

    if (response.type && response.message) {
      for (let i = 0; i < response.type.length; i += 1) {
        if (response.type.length === response.message.length) {
          switch (response.type[i]) {
            case "firstName":
              setValidFirstName(false);
              setFirstNameHelpText(response.message[i]);
              return;
            case "lastName":
              setValidLastName(false);
              setLastNameHelpText(response.message[i]);
              return;
            case "sex":
              setValidSex(false);
              setSexHelpText(response.message[i]);
              return;
            case "birthMonth":
              setValidBirthMonth(false);
              setBirthMonthHelpText(response.message[i]);
              return;
            case "birthDay":
              setValidBirthDay(false);
              setBirthDayHelpText(response.message[i]);
              return;
            case "birthYear":
              setValidBirthYear(false);
              setBirthYearHelpText(response.message[i]);
              return;
            case "location":
              setValidLocation(false);
              setLocationHelpText(response.message[i]);
              return;
            case "phoneNumber":
              setValidPhoneNumber(false);
              setPhoneNumberHelpText(response.message[i]);
              return;
            case "description":
              setValidDescription(false);
              setDescriptionHelpText(response.message[i]);
              return;
            default:
              break;
          }
        }
      }
    }
    if (response.status === 201) {
      setSnackbarColor("success");
      setSnackbarText("Your profile was saved successfully.");
      setShowSnackbar(true);
    } else {
      setSnackbarColor("error");
      setSnackbarText("There was an error saving your profile.");
      setShowSnackbar(true);
    }
  };
 
  const errorStyle = ({
    palette: { error },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: error.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
    },
  });

const successStyle = ({
palette: { success },
borders: { borderRadius },
typography: { size },
boxShadows: { lg },
}) => ({
"& .MuiPaper-root": {
    backgroundColor: success.main,
    borderRadius: borderRadius.lg,
    fontSize: size.sm,
    fontWeight: 400,
    boxShadow: lg,
    px: 2,
    py: 0.5,
},
});

const toastTemplate = (
<MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
    {snackbarText}
    <CloseIcon
    fontSize="medium"
    sx={{ ml: 4, mr: -1, cursor: "pointer" }}
    onClick={toggleSnackbar}
    />
</MKBox>

);
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      {
          snackbarColor === "success" && 
          <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={showSnackbar}
              autoHideDuration={3000}
              onClose={hideSnackbar}
              message={toastTemplate}
              action={toggleSnackbar}
              sx={successStyle}
          />
      }
      {
          snackbarColor === "error" && 
          <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={showSnackbar}
              autoHideDuration={3000}
              onClose={hideSnackbar}
              message={toastTemplate}
              action={toggleSnackbar}
              sx={errorStyle}
          />
      }
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          { user &&
          <Grid item xs={12} sm={6}>
            <FormField
              label="First Name"
              defaultValue={user.firstName}
              error={!validFirstName}
              helperText={firstNameHelpText}
              onChange={(e)=>{
                setFirstName(e.target.value);
                if (e.target.value != "" && e.target.value.length <= 24) {
                  setValidFirstName(true);
                  setFirstNameHelpText("");
                } else {
                  setValidFirstName(false);
                }
              }
            }/>
          </Grid>
          }
          { user &&
            <Grid item xs={12} sm={6}>
              <FormField
                label="Last Name"
                defaultValue={user.lastName}
                error={!validLastName}
                helperText={lastNameHelpText}
                onChange={(e)=>{
                  setLastName(e.target.value);
                  if (e.target.value != "" && e.target.value.length <= 24) {
                    setValidLastName(true);
                    setLastNameHelpText("");
                  } else {
                    setValidLastName(false);
                  }
                }
              }/>
            </Grid>
          }
          <Grid item xs={12}>
            <Grid container spacing={3}>
              { user &&
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    defaultValue={user.sex}
                    options={selectData.gender}
                    renderInput={(params) => (
                      <FormField {...params}
                        label="I'm"
                        InputLabelProps={{ shrink: true }}
                        error={!validSex}
                        helperText={sexHelpText}
                        onChange={(e)=>{
                          setSex(e.target.value);
                          if (e.target.value != "") {
                            setValidSex(true);
                            setSexHelpText("");
                          } else {
                            setValidSex(false);
                          }
                        }}
                        onSelect={(e)=>{
                          setSex(e.target.value);
                          setValidSex(true);
                          setSexHelpText("");
                        }}
                      />
                    )}
                  />
                </Grid>
              }
              <Grid item xs={12} sm={8}>
                <Grid container spacing={3}>
                  { user &&
                    <Grid item xs={12} sm={5}>
                      <Autocomplete
                        defaultValue={birthMonth}
                        options={selectData.birthDate}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label="Birth Date"
                            InputLabelProps={{ shrink: true }}
                            error={!validBirthMonth}
                            helperText={birthMonthHelpText}
                            onChange={(e)=>{
                              setBirthMonth(e.target.value);
                              if (e.target.value != "") {
                                setValidBirthMonth(true);
                                setBirthMonthHelpText("");
                              } else {
                                setValidBirthMonth(false);
                              }
                            }}
                            onSelect={(e)=>{
                              setBirthMonth(e.target.value);
                              setValidBirthMonth(true);
                              setBirthMonthHelpText("");
                            }}
                          />
                        )}
                      />
                    </Grid>
                  }
                  { user &&
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        defaultValue={birthDay ? birthDay : ""}
                        options={selectData.days}
                        renderInput={(params) => (
                          <FormField {...params} 
                            InputLabelProps={{ shrink: true }} 
                            error={!validBirthDay}
                            helperText={birthDayHelpText}
                            onChange={(e)=> {
                              setBirthDay(e.target.value);
                              if (e.target.value != "") {
                                setValidBirthDay(true);
                                setBirthDayHelpText("");
                              } else {
                                setValidBirthDay(false);
                              }
                            }}
                            onSelect={(e)=>{
                              setBirthDay(e.target.value);
                              setValidBirthDay(true);
                              setBirthDayHelpText("");
                            }}
                          />
                        )}
                      />
                    </Grid>
                  }
                  { user &&
                    <Grid item xs={12} sm={3}>
                      <Autocomplete
                        defaultValue={birthYear ? birthYear : ""}
                        options={selectData.years}
                        renderInput={(params) => (
                          <FormField {...params}
                            InputLabelProps={{ shrink: true }} 
                            error={!validBirthYear}
                            helperText={birthYearHelpText}
                            onChange={(e)=> {
                              setBirthYear(e.target.value);
                              if (e.target.value != "") {
                                setValidBirthYear(true);
                                setBirthYearHelpText("");
                              } else {
                                setValidBirthYear(false);
                              }
                            }}
                            onSelect={(e)=>{
                              setBirthYear(e.target.value);
                              setValidBirthYear(true);
                              setBirthYearHelpText("");
                            }}
                          />
                        )}
                      />
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          { user &&
          <Grid item xs={12} sm={6}>
            <FormField
              label="Your location"
              defaultValue={user.location}
              error={!validLocation}
              helperText={locationHelpText}
              onChange={(e)=>{
                setLocation(e.target.value);
                if (e.target.value != "" && e.target.value.length <= 24) {
                  setValidLocation(true);
                  setLocationHelpText("");
                } else {
                  setValidLocation(false);
                }
              }
            }/>
          </Grid>
          }
          { user &&
            <Grid item xs={12} sm={6}>
              <FormField
                label="Phone Number"
                defaultValue={user.phoneNumber}
                inputProps={{ type: "number" }}
                error={!validPhoneNumber}
                helperText={phoneNumberHelpText}
                onChange={(e)=> {
                  setPhoneNumber(e.target.value);
                  if (e.target.value != "" && validator.isMobilePhone(e.target.value)) {
                    setValidPhoneNumber(true);
                    setPhoneNumberHelpText("");
                  } else {
                    setValidPhoneNumber(false);
                  }
                }
              }
              />
            </Grid>
          }
          { user &&
            <Grid item xs={12} sm={12}>
              <FormField
                label="Description"
                defaultValue={user.description}
                inputProps={{ type: "string" }}
                error={!validDescription}
                helperText={descriptionHelpText}
                onChange={(e)=> {
                  setDescription(e.target.value);
                  if (e.target.value != "" && e.target.value.length <= 256) {
                    setValidDescription(true);
                    setDescriptionHelpText("");
                  } else {
                    setValidDescription(false);
                  }
                }
              }
              />
            </Grid>
          }
          {/*
          <Grid item xs={12} md={12}>
            <Autocomplete
              multiple
              defaultValue={["react", "angular"]}
              options={selectData.skills}
              renderInput={(params) => <FormField label="Goals" {...params} InputLabelProps={{ shrink: true }} />}
            />
          </Grid>
          */}
          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
              <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
              >
                  <MDBox ml="auto">
                      <MDButton variant="gradient" color="dark" size="small" onClick={handleSubmit}>
                        update profile
                      </MDButton>
                  </MDBox>
              </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
