import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MKBox from "components/MKBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import CustomModal from "./components/CustomModal";
import { toggleModal } from "./components/CustomModal";

function Program() {
  const params = useParams();
  const [modalMessage, setModalMessage] = useState();
  const [modalButtonColor, setModalButtonColor] = useState();
  const [modalButtonText, setModalButtonText] = useState();
  const [modalButtonFunction, setModalButtonFunction] = useState();
  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToPrograms = () => navigate("/dashboards/trainer/programs");

  const [programName, setProgramName] = useState();
  const [programDescription, setProgramDescription] = useState();
  const navigateToDashboard = () => navigate("/dashboards/trainer");
  // const navigateToCreateExercise = () => navigate("/dashboards/trainer/exercise-create");
  const navigateToEditProgram = () => {
    localStorage.removeItem('program');
    navigate(`/dashboards/trainer/program/${params.programId}/edit`);
  }

  const queryString = window.location.href;
  const s = queryString.split("/");
  const programId = s[s.length-1];

  const [workoutTableData, setWorkoutTableData] = useState({
    columns: [
      { Header: "workout", accessor: "workoutName", width: "40%" },
      { Header: "emphasis", accessor: "emphasis", width: "30%" },
      { Header: "exercises", accessor: "exercises", width: "30%"},
    ],
    rows: [],
  });

  const pressDeleteProgram = async () => {
    var result;
    var accessToken = localStorage.getItem('access_token');
    var refreshToken = localStorage.getItem('refresh_token');
    result = await axios.post(`${process.env.REACT_APP_API_URL}/v1/subscription/program/${programId}`, {
      refreshToken: refreshToken,
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
    if (result && result.data && result.data.programId) {
      setModalMessage("You have one or more clients assigned to this program. Please reassign all clients using this program to a different one before deleting.");
      setModalButtonColor("info");
      setModalButtonText("view clients");
      setModalButtonFunction("navigateToClients");
      toggleModal();
    } else {
      setModalMessage("Are you sure you want to delete this program?");
      setModalButtonColor("error");
      setModalButtonText("delete");
      setModalButtonFunction("deleteProgram");
      toggleModal();
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await setWorkouts();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/trainer/program/${programId}`]);
  
  async function setWorkouts() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/program/${programId}`, {
      refreshToken: refreshToken,
    }, {
      headers: {
        "Authorization": "Bearer " + accessToken,
      }
    })
    .then((response) => {
      for (var i=0; i<response.data.length; i++) {
        // append any new information we want to view or otherwise need from the exercise
        response.data[i].link = `/dashboards/trainer/workout/${response.data[i].id}`;
        if ( i == 0) {
          var theProgramName = response.data[i].programName;
          var theProgramDescription = response.data[i].programDescription;
          setProgramName(theProgramName);
          setProgramDescription(theProgramDescription);
          // remove the empty workout from view
          if (response.data[i].id == null) {
            response.data = [];
          }
        }
      }
  
      setWorkoutTableData({
        "columns": [
          { Header: "workout", accessor: "workoutName", width: "40%" },
          { Header: "emphasis", accessor: "emphasis", width: "30%" },
          { Header: "exercises", accessor: "exercises", width: "30%"},
        ],
        "rows": response.data,
      });
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject();
    });
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
         <MKBox component="section">
          <Container>
            <CustomModal
              message={modalMessage}
              buttonColor={modalButtonColor}
              buttonText={modalButtonText}
              buttonFunction={modalButtonFunction}
              redirect="/dashboards/trainer/programs"
              toDelete={programId}
            />
          </Container>
        </MKBox>
        <Grid mt={6}>
            <MDBox pb={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {programName}
              </MDTypography>
              <MDTypography variant="button" color="text">
                {programDescription}
              </MDTypography>
            </MDBox>
            <Grid pb={1}>
              <MDButton
                variant="gradient"
                color="secondary"
                size="small"
                onClick={navigateBack}
                pr={3}
              >
                Back
              </MDButton>
              &nbsp;
              <MDButton
                onClick={navigateToEditProgram}
                variant="gradient"
                color="warning"
                size="small"
              >
                edit
              </MDButton>
              &nbsp;
              <MDButton
                onClick={pressDeleteProgram}
                variant="gradient"
                color="error"
                size="small"
              >
                delete
              </MDButton>
            </Grid>
            <DataTable table={workoutTableData} />
        </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Program;
