/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import ClientTable from "layouts/dashboards/trainer/components/ClientTable";
import WorkoutProgramTable from "layouts/dashboards/trainer/components/WorkoutProgramTable";
import DashboardCard from "layouts/dashboards/trainer/components/DashboardCard";
import SimpleBlogCard from "layouts/dashboards/trainer/components/SimpleBlogCard";
import FoodPlanTable from "layouts/dashboards/trainer/components/FoodPlanTable";
import DataTable from "examples/Tables/DataTable";
import Clients from "layouts/dashboards/trainer/components/Clients";
import Products from "layouts/dashboards/trainer/components/Products";
// Data
import TraineeLineChartData from "layouts/dashboards/trainer/data/TraineeLineChartData";
import salesByAgeData from "layouts/dashboards/trainer/data/salesByAgeData";
import clientTableData from "layouts/dashboards/trainer/data/clientTableData";
import workoutProgramTableData from "layouts/dashboards/trainer/data/workoutProgramTableData";
import foodPlanTableData from "layouts/dashboards/trainer/data/foodPlanTableData";

function TrainerDashboard(user) {
  user = user.user;
  let [detailsSubmitted, setDetailsSubmitted] = useState(true);
  // DefaultStatisticsCard state for the dropdown value
  const [salesDropdownValue, setSalesDropdownValue] = useState("6 May - 7 May");
  const [customersDropdownValue, setCustomersDropdownValue] = useState("6 May - 7 May");
  const [revenueDropdownValue, setRevenueDropdownValue] = useState("6 May - 7 May");

  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState(null);
  const [customersDropdown, setCustomersDropdown] = useState(null);
  const [revenueDropdown, setRevenueDropdown] = useState(null);

  const [viewPageLink, setViewPageLink] = useState("");
  const [editPageLink, setEditPageLink] = useState("");
  const [onboardingUrl, setOnboardingUrl] = useState("");

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }) => setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openCustomersDropdown = ({ currentTarget }) => setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({ currentTarget }) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openRevenueDropdown = ({ currentTarget }) => setRevenueDropdown(currentTarget);
  const closeRevenueDropdown = ({ currentTarget }) => {
    setRevenueDropdown(null);
    setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
        const fetchData = async() => await getAccount();
        fetchData().catch(console.error);
    }
    return () => {
        abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/stripe/account/${user.accountId}`]);

  async function getAccount() {
    if (user.urlPrefix) {
      setViewPageLink(`/pages/${user.urlPrefix}`);
    } else {
      setViewPageLink(`/pages`);
    }
    setEditPageLink(`/dashboards/trainer/page/edit`);
    
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/stripe/account/${user.accountId}`, {
      refreshToken: refreshToken,
    }, {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
      }
    })
    .then((response) => {
      if (response.data.account.details_submitted) {
          setDetailsSubmitted(true);
      } else {
          setDetailsSubmitted(false);
      }
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject();
    });

    await axios.post(`${process.env.REACT_APP_API_URL}/v1/stripe/account_link`,{
      account: user.accountId,
      refresh_url: `${process.env.REACT_APP_FRONT_END_URL}/account/settings`,
      return_url: `${process.env.REACT_APP_FRONT_END_URL}/account/settings`,
      type: 'account_onboarding',
    },{
      headers: {
          "Authorization": "Bearer " + accessToken,
      }
    }).then((resp) => {
      setOnboardingUrl(resp.data.accountLink.url);
      return Promise.resolve();
    }).catch((err) => {
      console.log(err);
      return Promise.reject();
    });
  }


  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Monthly Revenue"
                count="Coming Soon"
                /*
                percentage={{
                  color: "success",
                  value: "+12%",
                  label: "since last month",
                }}
                */
                dropdown={{
                  action: openCustomersDropdown,
                  menu: renderMenu(customersDropdown, closeCustomersDropdown),
                  value: customersDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Monthly Subscribers"
                count="Coming Soon"
                /*
                percentage={{
                  color: "success",
                  value: "+12%",
                  label: "since last month",
                }}
                */
                dropdown={{
                  action: openCustomersDropdown,
                  menu: renderMenu(customersDropdown, closeCustomersDropdown),
                  value: customersDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Monthly private sessions"
                count="Coming Soon"
                /*
                percentage={{
                  color: "success",
                  value: "+14%",
                  label: "since last month",
                }}
                */
                dropdown={{
                  action: openCustomersDropdown,
                  menu: renderMenu(customersDropdown, closeCustomersDropdown),
                  value: customersDropdownValue,
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={12}>
              <TraineeLineChartData />
            </Grid>
            {/*
              <Grid item xs={12} lg={4}>
                <HorizontalBarChart title="Sales by age" chart={salesByAgeData} />
              </Grid>
            */}
          </Grid>
        </MDBox>
        {/*<MDBox pt={3} ml={3} lineHeight={1}>
          <MDTypography variant="h5" fontWeight="medium" id="my-programs">
            My Programs
          </MDTypography>
        </MDBox>
          */}
        <MDBox my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SimpleBlogCard action={{type: "internal", color: "info", leftButtonLink: "/dashboards/trainer/exercises", rightButtonLink: "/dashboards/trainer/exercise-create"}} title="Exercises" rows={[]/*workoutProgramTableData*/} leftButtonText="View Exercises" rightButtonText="Create"/>
            </Grid>
            <Grid item xs={12} sm={4}>
              <SimpleBlogCard action={{type: "internal", color: "info", leftButtonLink: "/dashboards/trainer/workouts", rightButtonLink: "/dashboards/trainer/workout-create"}} title="Workouts" rows={[]} leftButtonText="View Workouts" rightButtonText="Create"/>
            </Grid>
            <Grid item xs={12} sm={4}>
              <SimpleBlogCard action={{type: "internal", color: "info", leftButtonLink: "/dashboards/trainer/programs", rightButtonLink: "/dashboards/trainer/program-create"}} title="Programs" rows={[]} leftButtonText="View Programs" rightButtonText="Create"/>
            </Grid>
            
          </Grid>
        </MDBox>
        { user.fg_accept_payments == true &&
        <MDBox my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              {!detailsSubmitted &&
                <SimpleBlogCard action={{type: "external", color: "info", leftButtonLink: "/dashboards/trainer/page", rightButtonLink: onboardingUrl}} title="Stripe Onboarding" rows={[]/*workoutProgramTableData*/} rightButtonText="Complete"/> 
              }
              {detailsSubmitted &&
                 <SimpleBlogCard action={{type: "internal", color: "info", leftButtonLink: "/learn-more", rightButtonLink: "/dashboards/trainer/page-create"}} title="" description="You're all set to start accepting payments from clients." leftButtonText="Learn More" rows={[]/*workoutProgramTableData*/}/> 
              }
              </Grid>
            <Grid item xs={12} lg={4}>
              <Grid>
                <SimpleBlogCard action={{type: "internal", color: "info", leftButtonLink: "/dashboards/trainer/products", rightButtonLink: "/dashboards/trainer/product-create"}} title="Products" rows={[]/*workoutProgramTableData*/} leftButtonText="View Products" rightButtonText="Create"/>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid>
                  <SimpleBlogCard action={{type: "internal", color: "info", leftButtonLink: viewPageLink, rightButtonLink: editPageLink}} title="Landing Page" rows={[]/*workoutProgramTableData*/} leftButtonText="View Page" rightButtonText="Edit"/>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
        }
        <MDBox my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Clients user={user} />
            </Grid>
            {/*}
            <Grid item xs={12} sm={6}>
              <Products user={user} />
            </Grid>
            */}
          </Grid>
        </MDBox>
        
        {/*
          <MDBox my={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <DashboardCard title="Foods" rows={[]} leftButtonText="View Foods" leftButtonLink="/dashboards/trainer/food-create" rightButtonText="Create" rightButtonLink="/dashboards/trainer/food-create" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DashboardCard title="Diets" rows={[]} leftButtonText="View Diets" leftButtonLink="/dashboards/trainer/diet-create" rightButtonText="Create" rightButtonLink="/dashboards/trainer/diet-create" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DashboardCard title="Meal plans" rows={[]} leftButtonText="View Meal Plans" leftButtonLink="/dashboards/trainer/meal-plan-create" rightButtonText="Create" rightButtonLink="/dashboards/trainer/meal-plan-create" />
              </Grid>
            </Grid>
          </MDBox>
        */}
        
        
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TrainerDashboard;
