import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import DataTable from "examples/Tables/DataTable";
import axios from "axios";
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Data
//import exerciseTableData from "layouts/pages/applications/data-tables/data/exerciseTableData";

function TodaysWorkoutProgram2() {
  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToDashboard = () => navigate("/dashboards/trainer");
  const navigateToCreateExercise = () => navigate("/dashboards/trainer/exercise-create");
  const [emphasis, setEmphasis] = useState();

  const [exerciseTableData, setExerciseTableData] = useState({
    columns: [
      { Header: "name", accessor: "name", width: "20%"},
      { Header: "group", accessor: "muscleGroup", width: "20%"},
      { Header: "sets", accessor: "sets", width: "15%"},
      { Header: "reps/interval", accessor: "reps", width: "15%"}, // # reps or time
      { Header: "rest", accessor: "rest", width: "15%"},
      //{ Header: "weight", accessor: "weight", width: "15%"},
    ],
    rows: [],
  });

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await setExercises();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/dashboards/trainee/daily-exercises`]);

  async function setExercises() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainee/daily-exercises`, {
      refreshToken: refreshToken,
    }, {
      headers: {
        "Authorization": "Bearer " + accessToken,
      }
    })
    .then((response) => {
      var emphasis;
      for (var i=0; i<response.data.length; i++) {
        // append any new information we want to view or otherwise need from the exercise
        response.data[i].link = `/dashboards/trainee/exercise/${response.data[i].id}`;
        response.data[i].disabledSetsAndReps = true;
        if (response.data[i].type && response.data[i].type === "interval") {
            response.data[i].reps = `${response.data[i].time} sec.`;
        }
        if (i == 0) {
            setEmphasis(response.data[i].emphasis);
        }
      }
      setExerciseTableData({
        columns: [
            { Header: "name", accessor: "name", width: "20%"},
            { Header: "group", accessor: "muscleGroup", width: "20%"},
            { Header: "sets", accessor: "sets", width: "15%"},
            { Header: "reps/interval", accessor: "reps", width: "15%"}, // # reps or time
            { Header: "rest", accessor: "rest", width: "15%"},
            //{ Header: "weight", accessor: "weight", width: "15%"},
          ],
        rows: response.data,
      });
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
  }

  return (
        
    <Grid mt={3} mb={6}>

        { exerciseTableData.rows.length === 0 &&
                
            <DefaultInfoCard
            title="Trainee Dashboard"
            description="You haven't been assigned a workout program yet."
            icon={<Icon fontSize="medium">dashboard</Icon>}
            color="success"
            />
        }

        { exerciseTableData.rows.length > 0 &&
        <Grid>
            <MDBox pb={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
                Today's Workout
            </MDTypography>
            <MDTypography variant="button" color="text">
                {emphasis}
            </MDTypography>
            </MDBox>
            <Grid pb={1}>
            </Grid>
            <DataTable table={exerciseTableData} />
        </Grid>
        }
        
        
    </Grid> 
        
  );
}

export default TodaysWorkoutProgram2;
