/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React related
import React, { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import PricingCards from "layouts/main/complete-registration/components/PricingCards";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

import Header from "layouts/main/components/Header";
import Footer from "layouts/main/components/Footer";
import FooterThree from "layouts/main/components/FooterThree";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Validation
import validator from "validator";

// API
import axios from "axios";

async function createUser(userInfo) {
  try {
    if (userInfo.name === undefined || userInfo.name.trim().length === 0) {
      const message = "please enter your full name.";
      return { type: ["name"], message: [message] };
    }
    if (userInfo.email === undefined || userInfo.email.trim().length === 0) {
      const message = "please enter an email address.";
      return { type: ["email"], message: [message] };
    }
    if (!validator.isEmail(userInfo.email)) {
      const message = "please enter a valid email.";
      return { type: ["email"], message: [message] };
    }
    if (userInfo.password === undefined || userInfo.password.trim().length === 0) {
      const message = "please enter a password.";
      return { type: ["password"], message: [message] };
    }
    if (!userInfo.password.match(/\d/) || !userInfo.password.match(/[a-zA-Z]/)) {
      const message = "password must contain at least one letter and one number.";
      return { type: ["password"], message: [message] };
    }
    if (userInfo.policy === undefined || userInfo.policy === false) {
      const message = "agree to the terms and conditions to create an account.";
      return { type: ["policy"], message: [message] };
    }

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/register`, {
      name: userInfo.name,
      email: userInfo.email,
      password: userInfo.password,
      accountType: "Trainer",
      termsAndConditions: userInfo.termsAndConditions,
    });

    return response;
  } catch (error) {
    return { type: ["email"], message: ["this email has already been taken."] };
  }
}

function CompleteRegistration({ user }) {
  /* Values */
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [policy, setPolicy] = useState();
  /* Validation */
  const [validName, setValidName] = useState();
  const [validEmail, setValidEmail] = useState();
  const [validPassword, setValidPassword] = useState();
  const [validPolicy, setValidPolicy] = useState();
  /* Text */
  const [nameHelpText, setNameHelpText] = useState();
  const [emailHelpText, setEmailHelpText] = useState();
  const [passwordHelpText, setPasswordHelpText] = useState();
  /* Navigation */
  const navigate = useNavigate();

  const [prices, setPrices] = useState(["0", "25", "49"]);

  const navigateToAccountSettings = async () => navigate("/account/settings");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await createUser({
      name,
      email,
      password,
      accountType,
      policy,
    });
    if (response.type && response.message) {
      for (let i = 0; i < response.type.length; i += 1) {
        if (response.type.length === response.message.length) {
          switch (response.type[i]) {
            case "name":
              setValidName(false);
              setNameHelpText(response.message[i]);
              break;
            case "email":
              setValidEmail(false);
              setEmailHelpText(response.message[i]);
              break;
            case "password":
              setValidPassword(false);
              setPasswordHelpText(response.message[i]);
              break;
            case "policy":
              setValidPolicy(false);
              break;
            default:
              break;
          }
        }
      }
    }
    if (response.status && response.status === 201) {
      const accessToken = response.data.tokens.access.token;
      const refreshToken = response.data.tokens.refresh.token;
      const accessTokenExpires = response.data.tokens.access.expires;
      const refreshTokenExpires = response.data.tokens.refresh.expires;
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);
      localStorage.setItem("access_token_expires", accessTokenExpires);
      localStorage.setItem("refresh_token_expires", refreshTokenExpires);
      setValidName(undefined);
      setValidEmail(undefined);
      setValidPassword(undefined);
      setValidPolicy(undefined);

      if (response2.status === 204) {
        navigate("/complete-registration");
      } else {
        navigate("/account/settings");
      }
    }
  };

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  
  /*
  console.log("user aaa: ", user);
  console.log("user.subscriptionStatus: ", user.subscriptionStatus);
  if (user != undefined && user.subscriptionStatus !== undefined) {
    console.log("navigating to /account/settings!");  
    await navigateToAccountSettings("/account/settings");
  }
  */

  return (
    <PageLayout>
      <Header />
      
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ position: "relative", pt: 12, textAlign: "center" }}
          >
            <Grid item xs={11} lg={5}>
              <MDBox mb={1}>
                <MDTypography variant="h2" fontWeight="bold">
                  Choose a plan that's right for you
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDTypography variant="body2" fontWeight="light">
                  You have Free Unlimited Updates on each package.
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid xs={12} sm={2} />
            <Grid xs={12} sm={8}>
              <PricingCards prices={prices} />
            </Grid>
            <Grid xs={12} sm={2} />
          </Grid>
          </MDBox>
      </MDBox>
      <FooterThree />
    </PageLayout>
  );
}

export default CompleteRegistration;
