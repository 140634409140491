/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import { useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { NavLink, useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Exercises from "layouts/dashboards/trainer/Exercises";
import axios from "axios";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// @mui icons
import CloseIcon from "@mui/icons-material/Close";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import MDTypographyRoot from "components/MDTypography/MDTypographyRoot";


function DataTableBodyCell({ noBorder, align, link, cell, children }) {
  const params = useParams();
  const navigate = useNavigate();
  const navigateToClient = () => navigate(`/dashboards/trainer/client/${params.clientId}`);
  const navigateToWorkoutCreate = () => navigate("/dashboards/trainer/workout-create");
  const navigateToProgramCreate = () => navigate("/dashboards/trainer/program-create");
  const navigateToEditExercise = () => navigate(`/dashboards/trainer/exercise/${cell.row.original.id}/edit`);
  const navigateToEditWorkout = () => navigate(`/dashboards/trainer/workout/${cell.row.original.workoutId}/edit`, {
    state: {
      workoutName: cell.row.original.name,
    }
  });
  const navigateToEditProgram = () => navigate(`/dashboards/trainer/program/${cell.row.original.programId}/edit`, {
    state: {
      programName: cell.row.original.name,
    }
  });
  const navigateToEditProduct = () => navigate(`/dashboards/trainer/product/${cell.row.original.productId}/edit`);
  const navigateToClients = () => navigate(`/dashboards/trainer`);
  const navigateToManageSubscription = () => navigate(`/account/settings`);
  const [disabledSetsAndReps, setDisabledSetsAndReps] = useState(false);

  const [sets, setSets] = useState(cell.row.original.sets);
  const [reps, setReps] = useState(cell.row.original.reps);
  const [type, setType] = useState("reps");
  const [accessory, setAccessory] = useState();
  const [rest, setRest] = useState(cell.row.original.rest);
  const [validSets, setValidSets] = useState();
  const [validReps, setValidReps] = useState();
  const [validType, setValidType] = useState();
  const [validRest, setValidRest] = useState();
  const [show, setShow] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
  const hideSnackbar = () => setShowSnackbar(false);
  const [snackbarText, setSnackbarText] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalButtonColor, setModalButtonColor] = useState("error");
  const [modalButtonText, setModalButtonText] = useState("delete");
  const [modalButtonFunction, setModalButtonFunction] = useState();
  const [exerciseToDelete, setExerciseToDelete] = useState();
  const [workoutToDelete, setWorkoutToDelete] = useState();
  const [programToDelete, setProgramToDelete] = useState();
  const [productToDelete, setProductToDelete] = useState();
  //const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

  // add exercise to workout in local storage
  const addExerciseToWorkout = () => {
    var workoutLocal = JSON.parse(localStorage.getItem('workout'));
    if (workoutLocal === null) {
      workoutLocal = {exercises: []};
    }
    var exerciseExists = false;
    for (var i=0; i<workoutLocal.exercises.length; i++) {
        if (workoutLocal.exercises[i].id == cell.row.original.id) {
          exerciseExists = true;
          break;
        }
    }
    if (workoutLocal.exercises.length >= 10) {
      setSnackbarText("Workouts are limited to 10 exercises.");
      setShowSnackbar(true);
      return false;
    } else if (!exerciseExists) {
      workoutLocal.exercises.push({
        id: cell.row.original.id,
        exerciseName: cell.row.original.name,
        muscleGroup: cell.row.original.muscleGroup,
        type: "reps",
      });
      localStorage.setItem('workout', JSON.stringify(workoutLocal));
      return true;
    } else {
      setSnackbarText("Unable to add duplicate exercises.");
      setShowSnackbar(true);
      return false;
    }
  }
  // add workout to program in local storage
  const addWorkoutToProgram = () => {
    var programLocal = JSON.parse(localStorage.getItem('program'));
    if (programLocal === null) {
      programLocal = {workouts: []};
    }
    var workoutExists = false;
    for (var i=0; i<programLocal.workouts.length; i++) {
      if (programLocal.workouts[i].id == cell.row.original.id) {
        workoutExists = true;
        break;
      }
    }
    if (programLocal.workouts.length >= 100) {
      setSnackbarText("Programs are limited to 100 workouts.");
      setShowSnackbar(true);
      return false;
    } else if(!workoutExists) {
      programLocal.workouts.push({
        id: cell.row.original.id,
        workoutName: cell.row.original.name,
        emphasis: cell.row.original.emphasis,
      });
      localStorage.setItem('program', JSON.stringify(programLocal));
      return true;
    } else {
      setSnackbarText("Unable to add duplicate workouts.");
      setShowSnackbar(true);
      return false;
    }
    return false;
  }
  // assign program to trainer 
  const assignProgramToTrainer = async () => {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const result = await axios.patch(`${process.env.REACT_APP_API_URL}/v1/trainer/program/assign`, {
      programId: cell.row.original.id,
      traineeId: params.clientId,
      refreshToken: refreshToken,
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((result) => {
      return result;
    }).catch((err) => {
      return err;
    });
    return result;
  }
  // remove exercise from workout in local storage
  const removeExerciseFromWorkout = () => {
    var workoutLocal = JSON.parse(localStorage.getItem('workout'));
    if (workoutLocal && workoutLocal.exercises) {
      for (var i=0; i<workoutLocal.exercises.length; i++) {
          if (workoutLocal.exercises[i].id == cell.row.original.id) {
            workoutLocal.exercises.splice(i, 1);
            break;
          }
      }
      if (workoutLocal.exercises.length > 0) {
          localStorage.setItem('workout', JSON.stringify(workoutLocal));
      } else {
          workoutLocal = JSON.parse(localStorage.getItem('workout'));
          workoutLocal.exercises = [];
          localStorage.setItem('workout', JSON.stringify(workoutLocal));
      }
      // TODO: refactor such that we remove the element from the table instead of refreshing
      // (which ultimately makes unnecessary calls to the API)
      location.reload();
    }
  }
  // remove workout from program in local storage
  const removeWorkoutFromProgram = () => {
    var programLocal = JSON.parse(localStorage.getItem('program'));
    if (programLocal && programLocal.workouts) {
      for (var i=0; i<programLocal.workouts.length; i++) {
          if (programLocal.workouts[i].id == cell.row.original.id) {
            programLocal.workouts.splice(i, 1);
            break;
          }
      }
      if (programLocal.workouts.length > 0) {
          localStorage.setItem('program', JSON.stringify(programLocal));
      } else {
          programLocal = JSON.parse(localStorage.getItem('program'));
          programLocal.workouts = [];
          localStorage.setItem('program', JSON.stringify(programLocal));
      }
        // TODO: refactor such that we remove the element from the table instead of refreshing
        // (which ultimately makes unnecessary calls to the API)
        location.reload();
    }
  }
  // delete exercise
  const deleteExercise = async () => {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const result = await axios.delete(`${process.env.REACT_APP_API_URL}/v1/trainer/exercise/${exerciseToDelete}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        refreshToken: refreshToken,
      }
    });
    if (result.status === 200) {
      // TODO: refactor such that we remove the element from the table instead of refreshing
      // (which ultimately makes unnecessary calls to the API)'
      location.reload();
    }
  }
  // delete workout
  const deleteWorkout = async () => {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    const result = await axios.delete(`${process.env.REACT_APP_API_URL}/v1/trainer/workout/${workoutToDelete}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        refreshToken: refreshToken,
      }
    });
    if (result.status === 200) {
      // TODO: refactor such that we remove the element from the table instead of refreshing
      // (which ultimately makes unnecessary calls to the API)'
      location.reload();
    }
  }
  // delete program
  const deleteProgram = async () => {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const result = await axios.delete(`${process.env.REACT_APP_API_URL}/v1/trainer/program/${programToDelete}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        refreshToken: refreshToken,
      }
    });
    if (result.status === 200) {
      // TODO: refactor such that we remove the element from the table instead of refreshing
      // (which ultimately makes unnecessary calls to the API)'
      location.reload();
    }
  }
  //delete product 
  const deleteProduct = async () => {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const result = await axios.delete(`${process.env.REACT_APP_API_URL}/v1/trainer/product/${productToDelete}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        refreshToken: refreshToken,
      }
    });
    if (result.status === 200) {
      // TODO: refactor such that we remove the element from the table instead of refreshing
      // (which ultimately makes unnecessary calls to the API)'
      location.reload();
    }
  }

  const pressDeleteExercise = async () => {
    setModalMessage("This exercise will also be deleted from all workouts which use it. Are you sure you want to delete this exercise?");
    setModalButtonColor("error");
    setModalButtonText("delete");
    setExerciseToDelete(cell.row.original.id);
    setModalButtonFunction("deleteExercise");
    toggleModal();
  }
  const pressDeleteWorkout = async () => {
    setModalMessage("This workout will also be deleted from all programs which use it. Are you sure you want to delete this workout?");
    setModalButtonColor("error");
    setModalButtonText("delete");
    setWorkoutToDelete(cell.row.original.id);
    setModalButtonFunction("deleteWorkout");
    toggleModal();
  }

  const pressDeleteProgram = async () => {
    var result;
    var accessToken = localStorage.getItem('access_token');
    var refreshToken = localStorage.getItem('refresh_token');
    result = await axios.post(`${process.env.REACT_APP_API_URL}/v1/subscription/program/${cell.row.original.id}`, {
      refreshToken: refreshToken,
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
    if (result && result.data && result.data.programId) {
      setModalMessage("You have one or more clients assigned to this program. Please reassign all clients using this program to a different one before deleting.");
      setModalButtonColor("info");
      setModalButtonText("view clients");
      setProgramToDelete(cell.row.original.id);
      setModalButtonFunction("navigateToClients");
      toggleModal();
    } else {
      setModalMessage("Are you sure you want to delete this program?");
      setModalButtonColor("error");
      setModalButtonText("delete");
      setProgramToDelete(cell.row.original.id);
      setModalButtonFunction("deleteProgram");
      toggleModal();
    }
  }
  const pressDeleteProduct = async () => {
    setModalMessage("This product will be deleted from your landing pag and archived in Stripe. Are you sure you want to delete this product?");
    setModalButtonColor("error");
    setModalButtonText("delete");
    setProductToDelete(cell.row.original.productId);
    setModalButtonFunction("deleteProduct");
    toggleModal();
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    // 'Select' button
    var valid;
    if (cell.row.original.id && cell.row.original.selectButtonFunction) {
      switch (cell.row.original.selectButtonFunction) {
        case "createAddExercise":
          valid = addExerciseToWorkout();
          if (valid) {
            navigateToWorkoutCreate();
          }
          break;
        case "createAddWorkout":
          valid = addWorkoutToProgram();
          if (valid) {
            navigateToProgramCreate();
          }
          break;
        case "editAddExercise":
          valid = addExerciseToWorkout();
          if (valid) {
            navigateToEditWorkout();
          }
          break;
        case "editAddWorkout":
          valid = addWorkoutToProgram();
          if (valid) {
            navigateToEditProgram();
          }
          break;
        case "assignProgram":
          var result = await assignProgramToTrainer();
          if (result.status !== 200) {
            setModalMessage("You have exceeded the maximum amount of clients you can manage. Upgrade your subscription to manage additional clients.");
            setModalButtonColor("error");
            setModalButtonText("manage subscription");
            setModalButtonFunction("navigateToManageSubscription");//
            toggleModal();
          } else {
            navigateToClient();
          }
          break;
      }
    }
    if (e.target.innerText === "EDIT") {
      // 'Edit' button
      if (cell.row.original.id && cell.row.original.editButtonFunction) {
        switch (cell.row.original.editButtonFunction) {
          case "navigateToEditExercise":
            navigateToEditExercise();
            break;
          case "navigateToEditWorkout":
            localStorage.removeItem('workout');
            navigateToEditWorkout();
            break;
          case "navigateToEditProgram":
            localStorage.removeItem('program');
            navigateToEditProgram();
            break;
          case "navigateToEditProduct":
            localStorage.removeItem('product');
            navigateToEditProduct();
            break;
        }
      }
    }
    if (e.target.innerText === "DELETE") {
      // 'Delete' button
      if (cell.row.original.id && cell.row.original.deleteButtonFunction) {
        switch (cell.row.original.deleteButtonFunction) {
          case "createDeleteExercise":
            removeExerciseFromWorkout();
            break;
          case "createDeleteWorkout":
            removeWorkoutFromProgram();
            break;
          case "editDeleteExercise":
            removeExerciseFromWorkout();
            break;
          case "editDeleteWorkout":
            removeWorkoutFromProgram();
            break;
          case "pressDeleteExercise":
            pressDeleteExercise();
            break;
          case "pressDeleteWorkout":
            pressDeleteWorkout();
            break;
          case "pressDeleteProgram":
            pressDeleteProgram();
            break;
          case "pressDeleteProduct":
            pressDeleteProduct();
            break;
        }
      }
    }
  };

  const executeModalButtonFunction = () => { 
    switch (modalButtonFunction) {
      case "deleteExercise":
        deleteExercise();
        break;
      case "deleteWorkout":
        deleteWorkout();
        break;
      case "deleteProgram":
        deleteProgram();
        break;
      case "deleteProduct":
        deleteProduct();
        break;
      case "navigateToClients":
        navigateToClients();
        break;
      case "navigateToManageSubscription":
        navigateToManageSubscription();
        break;
      default:
        console.log(`Button function '${modalButtonFunction}' not recognized.`);
        console.log("buttonFunction: ", modalButtonFunction);
        break;
    }
  }
  
  const errorStyle = ({
    palette: { error },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: error.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
    },
  });

  const toastTemplate = (
    <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
      {snackbarText}
      <CloseIcon
        fontSize="medium"
        sx={{ ml: 4, mr: -1, cursor: "pointer" }}
        onClick={toggleSnackbar}
      />
    </MKBox>
  );

  return (
    <MDBox
        component="td"
        textAlign={align}
        py={1.5}
        px={3}
        sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
          fontSize: size.sm,
          borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
        })}
    >
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={hideSnackbar}
          message={toastTemplate}
          action={toggleSnackbar}
          sx={errorStyle}
      />
      <MKBox component="section">
        <Container>
          <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={show} timeout={500}>
              <MKBox
                position="relative"
                width="500px"
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
              >
                <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                  <MKTypography variant="h5">Warning</MKTypography>
                  <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox p={2}>
                  <MKTypography variant="body2" color="secondary" fontWeight="regular">
                    {modalMessage}
                  </MKTypography>
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                  <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                    close
                  </MKButton>
                  <MKButton variant="gradient" color={modalButtonColor} onClick={executeModalButtonFunction}>
                    {modalButtonText}
                  </MKButton>
                </MKBox>
              </MKBox>
            </Slide>
          </Modal>
        </Container>
      </MKBox>
      { link && cell.column.Header === "select" &&
        // post specific resource
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDButton
                type="submit"
                variant="gradient"
                color="info"
                size="small"
              >
                select
            </MDButton>
        </MDBox>
      }
      { link && cell.column.Header === "delete" &&
        // post specific resource
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDButton
                type="submit"
                variant="gradient"
                color="error"
                size="small"
              >
                delete
            </MDButton>
        </MDBox>
      }
      { link && cell.column.Header === "edit" &&
        // post specific resource
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDButton
                type="submit"
                variant="gradient"
                color="warning"
                size="small"
              >
                edit
            </MDButton>
        </MDBox>
      }
      { cell.column.Header === "price" && 
        <NavLink to={link}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox
              display="inline-block"
              width="max-content"
              color="text"
              sx={{ verticalAlign: "middle" }}
            >
              ${children}
            </MDBox>
          </MDBox>
        </NavLink>
      }
      { cell.column.Header === "rep type" &&
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <Select
                defaultValue={cell.row.original.type}
                onChange={(e) => {
                  setType(e.target.value);
                  var workout = JSON.parse(localStorage.getItem('workout'));
                  for (var i=0; i<workout.exercises.length; i++) {
                    if (workout.exercises[i].id == cell.row.original.id) {
                      workout.exercises[i].type = e.target.value;
                      break;
                    }
                  }
                  localStorage.setItem('workout', JSON.stringify(workout));
                }}
              >
                <MenuItem value="reps">Reps</MenuItem>
                <MenuItem value="interval">Interval</MenuItem>
            </Select>
        </MDBox>
      }
      { cell.column.Header === "sets" && !cell.row.original.disabledSetsAndReps &&
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDInput
                type="number"
                label=""
                variant="standard"
                fullWidth
                error={validSets ? !validSets : (sets > 0 ? false : true)}
                defaultValue={`${cell.row.original.sets}`}
                InputLabelProps={{ shrink: true }}

                onChange={(e) => {
                  setSets(e.target.value);
                  if (e.target.value && !isNaN(e.target.value) && e.target.value > 0) {
                    setValidSets(true);
                  } else {
                    setValidSets(false);
                  }
                  var workout = JSON.parse(localStorage.getItem('workout'));
                  for (var i=0; i<workout.exercises.length; i++) {
                    if (workout.exercises[i].id == cell.row.original.id) {
                      workout.exercises[i].sets = parseInt(e.target.value);
                      break;
                    }
                  }
                  localStorage.setItem('workout', JSON.stringify(workout));
                }}
                onFocus={() => {
                  if (validReps) {
                    setValidReps(undefined);
                  }
                }}
              />
        </MDBox>
      }
      { cell.column.Header === "sets" && cell.row.original.disabledSetsAndReps === true &&
        <NavLink to={link}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox
              display="inline-block"
              width="max-content"
              color="text"
              sx={{ verticalAlign: "middle" }}
            >
              {children}
            </MDBox>
          </MDBox>
        </NavLink>
      }
      { cell.column.Header === "reps/interval" && !cell.row.original.disabledSetsAndReps &&
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDInput
              type="number"
              label=""
              variant="standard"
              fullWidth
              error={validReps ? !validReps : (reps > 0 ? false : true)}
              //placeholder={`${cell.row.original.reps}`}
              defaultValue={`${cell.row.original.reps}`}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setReps(e.target.value);
                if (e.target.value && !isNaN(e.target.value) && e.target.value > 0) {
                  setValidReps(true);
                } else {
                  setValidReps(false);
                }
                var workout = JSON.parse(localStorage.getItem('workout'));
                  for (var i=0; i<workout.exercises.length; i++) {
                    if (workout.exercises[i].id == cell.row.original.id) {
                      workout.exercises[i].reps = parseInt(e.target.value);
                      break;
                    }
                  }
                  localStorage.setItem('workout', JSON.stringify(workout));
                }}
              onFocus={() => {
                if (validSets) {
                  setValidSets(undefined);
                }
              }}
            />
        </MDBox>
      }
      { cell.column.Header === "reps/interval" && cell.row.original.disabledSetsAndReps === true &&
        <NavLink to={link}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox
              display="inline-block"
              width="max-content"
              color="text"
              sx={{ verticalAlign: "middle" }}
            >
              {children}
              {
                cell.row.original.type === "interval" &&
                "s"
              }
            </MDBox>
          </MDBox>
        </NavLink>
      }
      { cell.column.Header === "rest" && !cell.row.original.disabledSetsAndReps &&
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDInput
                type="number"
                label=""
                variant="standard"
                fullWidth
                error={validRest ? !validRest : (rest > 0 ? false : true)}
                //placeholder={`${cell.row.original.sets}`}
                defaultValue={`${cell.row.original.rest}`}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setRest(e.target.value);
                  if (e.target.value && !isNaN(e.target.value) && e.target.value > 0) {
                    setValidRest(true);
                  } else {
                    setValidRest(false);
                  }
                  var workout = JSON.parse(localStorage.getItem('workout'));
                  for (var i=0; i<workout.exercises.length; i++) {
                    if (workout.exercises[i].id == cell.row.original.id) {
                      workout.exercises[i].rest = parseInt(e.target.value);
                      break;
                    }
                  }
                  localStorage.setItem('workout', JSON.stringify(workout));
                }}
                onFocus={() => {
                  if (validReps) {
                    setValidRest(undefined);
                  }
                }}
              />
        </MDBox>
      }
      { cell.column.Header === "rest" && cell.row.original.disabledSetsAndReps === true &&
        <NavLink to={link}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox
              display="inline-block"
              width="max-content"
              color="text"
              sx={{ verticalAlign: "middle" }}
            >
              {children}
              { cell.row.original.rest &&
                "s"
              }
            </MDBox>
          </MDBox>
        </NavLink>
      }
      { // navigate to view the specific resource
        link && cell.column.Header !== "select" &&  cell.column.Header !== "delete" && cell.column.Header !== "edit" && cell.column.Header !== "sets" && cell.column.Header !== "reps/interval" && cell.column.Header !== "rest" && cell.column.Header !== "rep type" && cell.column.Header !== "price" &&
        <NavLink to={link}>
          <MDBox>
            <MDBox
              display="inline-block"
              width="max-content"
              color="text"
              sx={{ verticalAlign: "middle" }}
            >
                {children}
            </MDBox>
          </MDBox>
        </NavLink>
      }
      { link === undefined &&
        <MDBox>
          <MDBox
            display="inline-block"
            width="max-content"
            color="text"
            sx={{ verticalAlign: "middle" }}
          >
            {children}
          </MDBox>
        </MDBox>
      }
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
