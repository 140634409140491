/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import MDButton from "components/MDButton";
import axios from "axios";

function TraineeRegistrationLink({ user }) {
    const inviteLink = `${process.env.REACT_APP_FRONT_END_URL}/register?trainer=` + user._id;
    const handleCopyLink = async (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(inviteLink);
    };
    return (
        <Card id="trainee-registration-link">
        <MDBox p={2}>
            <Grid container spacing={3} alignItems="center">
                { user && user._id &&
                <Grid item>
                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                        Invite Trainees
                    </MDTypography>
                    <MDTypography variant="button" color="text" fontWeight="medium">
                        { inviteLink }
                    </MDTypography>
                    </MDBox>
                </Grid>
                }
                <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                    <MDBox
                    display="flex"
                    justifyContent={{ md: "flex-end" }}
                    alignItems="center"
                    lineHeight={1}
                    >
                        <MDBox ml="auto">
                            <MDButton variant="gradient" color="dark" size="small" onClick={handleCopyLink}>
                            copy link
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
        </Card>
    );
}

export default TraineeRegistrationLink;
