import { useNavigate } from "react-router-dom";
import { useState } from "react";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import axios from "axios";

function Subscribe(userObj) {
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        navigate('/complete-registration');
    }

    const manageSubscriptions = async (e) => {
        e.preventDefault();
        const accessToken = localStorage.getItem("access_token");
        const session = await axios.post(`${process.env.REACT_APP_API_URL}/v1/billing_portal/sessions`,
        {
            customer: userObj.user.customerId,
            return_url: `${process.env.REACT_APP_FRONT_END_URL}/account/settings#subscriptions`,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        window.location.replace(session.data.portalSession.url);
    };
    return (
        <DashboardLayout>
        <DashboardNavbar />
        { userObj.user != undefined && (!userObj.user.subscriptionStatus || userObj.user.subscriptionStatus !== "past_due") &&
        <MDBox textAlign="center" mt={13} mb={72}>
            <MDTypography color="dark" variant="h2" mb={3}>Subscribe to a plan to continue using the web app.</MDTypography>
            <MDButton color="dark" variant="gradient" onClick={handleSubmit}>Subscribe</MDButton>
        </MDBox>
        }
        { userObj.user != undefined && userObj.user.subscriptionStatus && userObj.user.subscriptionStatus === "past_due" &&
        <MDBox textAlign="center" mt={13} mb={72}>
            <MDTypography color="dark" variant="h2" mb={3}>Update your payment method and complete payment to continue using the app.</MDTypography>
            <MDButton color="dark" variant="gradient" onClick={manageSubscriptions}>Manage Subscription</MDButton>
        </MDBox>
        }
        <Footer />
        </DashboardLayout>
    );
}

export default Subscribe;
