/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React related
import React, { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Settings page components
import FormField from "layouts/account/components/FormField";

// Data
import selectData from "layouts/authentication/free-trial/data/selectData";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

// Validation
import validator from "validator";

// Theme
import { useMaterialUIController } from "context";

// API
import axios from "axios";

async function createUser(userInfo) {
  try {
    if (userInfo.businessType === undefined || userInfo.name.trim().length === 0) {
      const message = "business type is a required field.";
      return { type: ["businessType"], message: [message] };
    }
    if (userInfo.name === undefined || userInfo.name.trim().length === 0) {
      const message = "please enter your full name.";
      return { type: ["name"], message: [message] };
    }
    if (userInfo.email === undefined || userInfo.email.trim().length === 0) {
      const message = "please enter an email address.";
      return { type: ["email"], message: [message] };
    }
    if (!validator.isEmail(userInfo.email)) {
      const message = "please enter a valid email.";
      return { type: ["email"], message: [message] };
    }
    if (userInfo.password === undefined || userInfo.password.trim().length === 0) {
      const message = "please enter a password.";
      return { type: ["password"], message: [message] };
    } else if (userInfo.password.length > 128) {
      const message = "password cannot be greater than 128 characters.";
      return { type: ["password"], message: [message] };
    } else if (!userInfo.password.match(/\d/) || !userInfo.password.match(/[a-zA-Z]/)) {
      const message = "password must contain at least one letter and one number.";
      return { type: ["password"], message: [message] };
    } 
    if (userInfo.policy === undefined || userInfo.policy === false) {
      const message = "agree to the terms and conditions to create an account.";
      return { type: ["policy"], message: [message] };
    }

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/register`, {
      businessType: userInfo.businessType,
      name: userInfo.name,
      email: userInfo.email,
      password: userInfo.password,
      accountType: "Trainer",
      termsAndConditions: userInfo.termsAndConditions,
    });

    return response;
  } catch (error) {
    return { type: ["email"], message: ["this email has already been taken."] };
  }
}

function FreeTrial() {
  /* Values */
  const [businessType, setBusinessType] = useState("individual");
  const [validBusinessType, setValidBusinessType] = useState(true);
  const [businessTypeHelpText, setBusinessTypeHelpText] = useState("");
  const [nameLabel, setNameLabel] = useState("Your Name");
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [policy, setPolicy] = useState();
  /* Validation */
  const [validName, setValidName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [validPolicy, setValidPolicy] = useState(true);
  /* Text */
  const [nameHelpText, setNameHelpText] = useState();
  const [emailHelpText, setEmailHelpText] = useState();
  const [passwordHelpText, setPasswordHelpText] = useState();
  const [policyHelpText, setPolicyHelpText] = useState();
  /* Theme */
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  /* Navigation */
  const navigate = useNavigate();

  const handleKeyPress = (event) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
      handleSubmit(event)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Sign out the current user
    const refreshToken = localStorage.getItem("refresh_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("access_token_expires");
    localStorage.removeItem("refresh_token_expires");
    localStorage.removeItem("workout");
    localStorage.removeItem("program");
    localStorage.removeItem("homedata");
    if (refreshToken != null) {
        await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/logout`, { refreshToken });
    }

    const response = await createUser({
      businessType,
      name,
      email,
      password,
      policy,
    });

    if (response.type && response.message) {
      for (let i = 0; i < response.type.length; i += 1) {
        if (response.type.length === response.message.length) {
          switch (response.type[i]) {
            case "businessType":
              setValidBusinessType(false);
              setBusinessTypeHelpText(response.message[i]);
              break;
            case "name":
              setValidName(false);
              setNameHelpText(response.message[i]);
              break;
            case "email":
              setValidEmail(false);
              setEmailHelpText(response.message[i]);
              break;
            case "password":
              setValidPassword(false);
              setPasswordHelpText(response.message[i]);
              break;
            case "policy":
              setValidPolicy(false);
              setPolicyHelpText(response.message[i]);
              break;
            default:
              break;
          }
        }
      }
    }
    if (response.status && response.status === 201) {
      const access_token = response.data.tokens.access.token;
      const access_token_expires = response.data.tokens.access.expires;
      const refresh_token = response.data.tokens.refresh.token;
      const refresh_token_expires = response.data.tokens.refresh.expires;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("access_token_expires", access_token_expires);
      localStorage.setItem("refresh_token_expires", refresh_token_expires);

      /* EMAIL VERIFICATION */
      const response2 = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/auth/send-verification-email`,
        {
          user: response.user,
        },
        {
          headers: {
            Authorization: `Bearer ${response.data.tokens.access.token}`,
          },
        }
      );
      
      setValidName(undefined);
      setValidEmail(undefined);
      setValidPassword(undefined);
      if (response2.status === 204) {
        navigate("/complete-registration");
      } else if (response2.status === 535) {
        console.log("You may need to update your nodemailer credentials.");
      }
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
               <Autocomplete
                    defaultValue="individual"
                    options={selectData.businessType}
                    renderInput={(params) => (
                        <FormField {...params}
                            label="Business Type"
                            InputLabelProps={{ shrink: true }}
                            error={!validBusinessType}
                            helperText={businessTypeHelpText}
                            onKeyPress={handleKeyPress}
                            onSelect={(e)=>{
                                if (e.target.value) {
                                  setBusinessType(e.target.value);
                                }
                                setValidBusinessType(true);
                                if (e.target.value == "company"){
                                  setNameLabel("Company Name");
                                } else {
                                  setNameLabel("Your Name");
                                }
                                if (e.target.value != "") {
                                    setValidBusinessType(true);
                                    setBusinessTypeHelpText("");
                                } else {
                                    setValidBusinessType(false);
                                    setBusinessTypeHelpText("Business type is a required field.");
                                }
                            }}
                        />
                    )}
                />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label={nameLabel}
                variant="standard"
                fullWidth
                error={!validName}
                helperText={nameHelpText}
                onKeyPress={handleKeyPress}
                onSelect={(e) => {
                  setName(e.target.value);
                  if (e.target.value) {
                    if (e.target.value.length > 50) {
                      // if not valid name
                      if (validName) {
                        setValidName(false);
                        setNameHelpText("Please limit your name to 50 characters or less.");
                      }
                    } else {
                      setValidName(true);
                      setNameHelpText("");
                    }
                  } else {
                    setValidName(false);
                  }
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                error={!validEmail}
                helperText={emailHelpText}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (e.target.value) {
                    if (validator.isEmail(e.target.value)) {
                      if (validEmail === false) {
                        setValidEmail(true);
                        setEmailHelpText("");
                      }
                    } else {
                      setValidEmail(false);
                      setEmailHelpText("");
                    }
                  } else {
                    setValidEmail(false);
                  }
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                error={!validPassword}
                helperText={passwordHelpText}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (e.target.value) {
                      if (e.target.value.length >= 8 && e.target.value.length <= 128) {
                          if (e.target.value.match(/\d/) && e.target.value.match(/[a-zA-Z]/)) {
                            if (validPassword === false) {
                              setValidPassword(true);
                              setPasswordHelpText("");
                            }
                          } else {
                            setValidPassword(false);
                            setPasswordHelpText("password must contain at least one letter and one number.");
                          }
                      } else if (e.target.value.length > 128) {
                          setValidPassword(false)
                          setPasswordHelpText("password cannot be any more than 128 characters.");
                      } else if (e.target.value.length < 8) {
                          setValidPassword(false)
                          setPasswordHelpText("password must be at least 8 characters.");
                      }
                  } else if (validPassword === true) {
                      setValidPassword(false);
                  }
                }}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                error={true}
                inputProps={{ error: true }}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setPolicy(true);
                    setPolicyHelpText("");
                  } else {
                    setPolicy(false);
                  }
                }}
              />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree to the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="/terms"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDTypography
                component="a"
                href="/terms"
                variant="button"
                fontWeight="light"
                color={darkMode ? "light" : "dark"}
                textGradient
              >
                {policyHelpText}
            </MDTypography>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                Create Account
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default FreeTrial;
