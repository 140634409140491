/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DefaultPricingCard from "layouts/main/complete-registration/components/DefaultPricingCard";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function PricingCards({ prices }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [starter, professional, master] = prices;
  return (
    <MDBox position="relative" zIndex={10} mt={8} px={{ xs: 1, sm: 0 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={6}>
          <DefaultPricingCard
            color={darkMode ? "dark" : "white"}
            badge={{color: darkMode ? "warning" : "light", label: "starter" }}
            price={{ currency: "$", value: starter, type: "mo" }}
            specifications={[
              { label: "Unlimited free trial", includes: true },
              { label: "Customized Programs", includes: true },
              { label: "Landing Page", includes: false},
              { label: "Includes 5 clients", includes: true },
              { label: "Accept Payments", includes: false },
              { label: "Workout Analytics", includes: false},
              { label: "Session Scheduling", includes: false},
              { label: "Messaging & Automation", includes: false},
            ]}
            action={{
              type: "internal",
              route: "/create-checkout-session/starter",
              priceID: "price_1MT7QHF3DU4f5czeFpDfFOhT",
              color: darkMode ? "warning" : "light",
              label: "select",
            }}
            shadow={darkMode}
          />
        </Grid>
        {/*
        { label: "Workout Analytics", includes: false },
        { label: "Session Scheduling", includes: false },
        { label: "Messaging & Automation", includes: false },
        { label: "Marketing Templates", includes: false },
        */}
        <Grid item xs={12} lg={6}>
          <DefaultPricingCard
            color="dark"
            badge={{ color: "info", label: "professional" }}
            price={{ currency: "$", value: professional, type: "mo" }}
            specifications={[
              { label: "30 day free trial", includes: true },
              { label: "Customized Programs", includes: true },
              { label: "Landing Page", includes: true},
              { label: "Includes 15 clients (then $1.50/mo ea.)", includes: true },
              { label: "Accept Payments", includes: true },
              { label: "Workout Analytics", includes: false},
              { label: "Session Scheduling", includes: false},
              { label: "Messaging & Automation", includes: false},
            ]}
            action={{
              type: "internal",
              route: "/create-checkout-session/professional",
              priceID: "price_1MsFmrF3DU4f5czeM1igfT0S",
              color: "info",
              label: "select",
            }}
          />
          {/*
            { label: "Workout Analytics", includes: true },
            { label: "Session Scheduling", includes: false },
            { label: "Messaging & Automation", includes: false },
            { label: "Marketing Templates", includes: false },
          */}
        </Grid>
        {/*
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
              color={darkMode ? "dark" : "white"}
              badge={{ color: darkMode ? "warning" : "light", label: "master" }}
              price={{ currency: "$", value: master, type: "mo" }}
              specifications={[
                { label: "30 day free trial", includes: true },
                { label: "Customized Programs", includes: true },
                { label: "Landing Page", includes: true},
                { label: "Includes 40 clients (then $1.00/mo ea.)", includes: true },
                { label: "Accept Payments", includes: true },
                { label: "Workout Analytics", includes: true},
                { label: "Session Scheduling", includes: true},
                { label: "Messaging & Automation", includes: true},
              ]}
              action={{
                type: "internal",
                route: "", // /create-checkout-session/master
                priceID: "", // price_1MsVXoF3DU4f5czee07biNKU
                color: darkMode ? "warning" : "light",
                label: "coming soon",
              }}
            />
        </Grid>
        */}
      </Grid>
    </MDBox>
  );
}

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PricingCards;
