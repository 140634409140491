import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import MenuItem from "@mui/material/MenuItem";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MKBox from "components/MKBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import validator from "validator";
// @mui icons
import CloseIcon from "@mui/icons-material/Close";
import { ConstructionOutlined } from "@mui/icons-material";

async function createProduct(productInfo) {
    if (productInfo.name === undefined || productInfo.name.trim().length === 0) {
      const message = "please enter a product name.";
      return { type: ["name"], message: [message] };
    } else if (productInfo.name.trim().length > 32) {
      const message = "product name cannot exceed 32 characters.";
      return { type: ["name"], message: [message] };
    }
    if (productInfo.price === undefined || productInfo.price.trim().length === 0) {
      const message = "product price is a required field.";
      return { type: ["price"], message: [message] };
    }
    if (productInfo.cta === undefined || productInfo.cta.trim().length === 0) {
      const message = "call to action is a required field."
      return { type: ["cta"], message: [message]};
    } else if (productInfo.cta.trim().length > 32) {
      const message = "call to action cannot exceed 32 characters."
      return { type: ["cta"], message: [message] };
    }
    if (productInfo.description === undefined || productInfo.description.trim().length === 0) {
      const message = "please enter a product description.";
      return { type: ["description"], message: [message] };
    } else if (productInfo.description.trim().length > 256) {
      const message = "product description is limited to 256 characters or less.";
      return { type: ["description"], message: [message] };
    }
    if (productInfo.customProgram === undefined || productInfo.customProgram === false) {
      const message = "please select at least one option above.";
      return { type: ["customProgram"], message: [message] };
    }
    const accessToken = localStorage.getItem("access_token");
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/product`, {
      name: productInfo.name,
      price: productInfo.price,
      cta: productInfo.cta,
      customProgram: productInfo.customProgram,
      description: productInfo.description,
      refreshToken: productInfo.refreshToken,
    },{
      headers: {
          Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
}


function ProductCreate() {
  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToProducts = () => navigate("/dashboards/trainer/products");
  const navigateToCreateProduct = () => navigate("/dashboards/trainer/product-create");
  /* Values */
  const [name, setName] = useState();
  const [price, setPrice] = useState();
  const [cta, setCta] = useState("Start Training");
  const [customProgram, setCustomProgram] = useState();
  const [description, setDescription] = useState();
  /* Validation */
  const [validName, setValidName] = useState(true);
  const [validPrice, setValidPrice] = useState(true);
  const [validCta, setValidCta] = useState(true);
  const [validCustomProgram, setValidCustomProgram] = useState(false);
  const [validDescription, setValidDescription] = useState(true);
  /* Text */
  const [nameHelpText, setNameHelpText] = useState();
  const [priceHelpText, setPriceHelpText] = useState();
  const [ctaHelpText, setCtaHelpText] = useState();
  const [customProgramHelpText, setCustomProgramHelpText] = useState();
  const [descriptionHelpText, setDescriptionHelpText] = useState();
  /* Snackbar */
  const [showSnackbar, setShowSnackbar] = useState(false);
  const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
  const hideSnackbar = () => setShowSnackbar(false);
  const [snackbarText, setSnackbarText] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const refreshToken = localStorage.getItem("refresh_token");
    await createProduct({
      name,
      price,
      cta,
      customProgram,
      description,
      refreshToken,
    }).then((response) => {
      if (response.type && response.message) {
        for (let i = 0; i < response.type.length; i += 1) {
          if (response.type.length === response.message.length) {
            switch (response.type[i]) {
              case "name":
                setValidName(false);
                setNameHelpText(response.message[i]);
                return;
              case "price":
                setValidPrice(false);
                setPriceHelpText(response.message[i]);
                return;
              case "cta":
                setValidCta(false);
                setCtaHelpText(response.message[i]);
              case "customProgram":
                setValidCustomProgram(false);
                setCustomProgramHelpText(response.message[i]);
                return;
              case "description":
                setValidDescription(false);
                setDescriptionHelpText(response.message[i]);
                return;
              default:
                break;
            }
          }
        }
      }
      if (response.status && response.status === 201) {
        navigate("/dashboards/trainer/products");
      }
    }).catch((err) => {
      console.log(err);
      setSnackbarText(err.response.data);
      toggleSnackbar(true);
    });
  }

  const errorStyle = ({
    palette: { error },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: error.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
    },
  });

  const toastTemplate = (
    <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
      {snackbarText}
      <CloseIcon
        fontSize="medium"
        sx={{ ml: 4, mr: -1, cursor: "pointer" }}
        onClick={toggleSnackbar}
      />
    </MKBox>
  );

  return (
    <DashboardLayout>
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={hideSnackbar}
          message={toastTemplate}
          action={toggleSnackbar}
          sx={errorStyle}
      />
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MDBox pb={3} mt={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Create Product
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Create, view, or edit a product to use in your workouts
                </MDTypography>
              </MDBox>
              <Grid pb={1}>
                <MDButton
                    variant="gradient"
                    color="secondary"
                    size="small"
                    onClick={navigateBack}
                    pr={3}
                    >
                    Back
                </MDButton>
              </Grid>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form" onSubmit={handleSubmit}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Name"
                      variant="standard"
                      fullWidth
                      error={!validName}
                      helperText={nameHelpText}
                      onChange={(e) => {
                        setName(e.target.value);
                        if (e.target.value) {
                          if (e.target.value.length > 32) {
                            // if not valid name
                            if (validName) {
                              setValidName(false);
                              setNameHelpText("Please limit the product name to 32 characters or less.");
                            }
                          } else {
                            setValidName(true);
                            setNameHelpText("");
                          }
                        } else {
                          setValidName(false);
                          setNameHelpText("please enter a product name.");
                        }
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="number"
                      label="Monthly Price"
                      inputProps={{
                        maxLength: 13,
                        step: "0.01"
                      }}
                      variant="standard"
                      fullWidth
                      error={!validPrice}
                      helperText={priceHelpText}
                      onChange={(e) => {
                        if (e.target.value) {
                          setPrice(e.target.value);
                          setValidPrice(true);
                          setPriceHelpText("");
                        } else {
                          setValidPrice(false);
                          setPriceHelpText("product price is a required field.");
                        }
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="product-description"
                      label="Product Description"
                      variant="standard"
                      fullWidth
                      error={!validDescription}
                      helperText={descriptionHelpText}
                      onChange={(e) => {
                        setDescription(e.target.value);
                        if (e.target.value) {
                          if (e.target.value.length > 256) {
                            if (validDescription) {
                              setValidDescription(false);
                              setDescriptionHelpText("Please limit the description to 256 characters or less.");
                            }
                          } else {
                            setValidDescription(true);
                            setDescriptionHelpText("");
                          }
                        } else {
                          setValidDescription(false);
                          setDescriptionHelpText("please enter a product description.");
                        }
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="call-to-action"
                      label="Call to Action"
                      variant="standard"
                      fullWidth
                      error={!validCta}
                      helperText={ctaHelpText}
                      value={cta}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => {
                        setCta(e.target.value);
                        if (e.target.value) {
                          if (e.target.value.length > 32) {
                            if (validCta) {
                              setValidCta(false);
                              setCtaHelpText("Please limit the call to action to 32 characters or less.");
                            }
                          } else {
                            setValidCta(true);
                            setCtaHelpText("");
                          }
                        } else {
                          setValidCta(false);
                          setCtaHelpText("please enter a product description.");
                        }
                      }}
                    />
                  </MDBox>
                  <Grid ml={1}>
                    <MDTypography
                        variant="text"
                        fontWeight="light"
                        color="text"
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        
                      >
                        What will be included in this product?
                    </MDTypography>
                  </Grid>
                  <MDBox mb={2}>
                    <Checkbox
                      error={true}
                      inputProps={{ error: true }}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setCustomProgram(true);
                          setCustomProgramHelpText("");
                        } else {
                          setCustomProgram(false);
                        }
                      }}
                    />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;Custom Program
                    </MDTypography>
                    <br></br>
                    <Checkbox
                      disabled
                      error={true}
                      inputProps={{ error: true }}
                      onChange={(e) => {
                        /*
                        if (e.target.checked === true) {
                          setMealProduct(true);
                          setMealProductHelpText("");
                        } else {
                          setMealProduct(false);
                        }
                        */
                      }}
                    />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;Meal Product (coming soon)
                    </MDTypography>
                    <br></br>
                    <Checkbox
                      disabled
                      error={true}
                      inputProps={{ error: true }}
                      onChange={(e) => {
                        /*
                        if (e.target.checked === true) {
                          setWeeklyCheckin(true);
                          setWeeklyCheckinHelpText("");
                        } else {
                          setWeeklyCheckin(false);
                        }
                        */
                      }}
                    />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;Weekly Check-in (coming soon)
                    </MDTypography>
                    <br></br>
                    <Checkbox
                      disabled
                      error={true}
                      inputProps={{ error: true }}
                      onChange={(e) => {
                        /*
                        if (e.target.checked === true) {
                          
                          setVirtualTraining(true);
                          setVirtualTrainingHelpText("");
                        } else {
                          setVirtualTraining(false);
                        }
                        */
                      }}
                    />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;Virtual Training (coming soon)
                    </MDTypography>
                    <br></br>
                    <Checkbox
                      disabled
                      error={true}
                      inputProps={{ error: true }}
                      onChange={(e) => {
                        /*
                        if (e.target.checked === true) {
                          setInPersonTraining(true);
                          setInPersonTrainingHelpText("");
                        } else {
                          setInPersonTraining(false);
                        }
                        */
                      }}
                    />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;In-person Training (coming soon)
                    </MDTypography>
                    
                  </MDBox>
                  { !validCustomProgram &&
                    <Grid pl={1}>
                    <MDTypography
                        variant="text"
                        fontWeight="light"
                        color="text"
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        
                      >
                        {customProgramHelpText}
                    </MDTypography>
                  </Grid>
                  }
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                      Create
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductCreate;
