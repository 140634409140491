import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import MenuItem from "@mui/material/MenuItem";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MKBox from "components/MKBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import validator from "validator";
// @mui icons
import CloseIcon from "@mui/icons-material/Close";

async function createExercise(exerciseInfo) {

    if (exerciseInfo.name === undefined || exerciseInfo.name.trim().length === 0) {
      const message = "please enter an exercise name.";
      return { type: ["name"], message: [message] };
    } else if (exerciseInfo.name.trim().length > 32) {
      const message = "exercise name cannot exceed 32 characters.";
      return { type: ["name"], message: [message] };
    }
    if (exerciseInfo.muscleGroup === undefined || exerciseInfo.muscleGroup.trim().length === 0) {
      const message = "please enter a muscle group.";
      return { type: ["muscleGroup"], message: [message] };
    } else if (exerciseInfo.muscleGroup.trim().length > 32) {
      const message = "muscle group cannot exceed 32 characters.";
      return { type: ["muscleGroup"], message: [message] };
    }
    if (exerciseInfo.accessory === undefined || exerciseInfo.accessory.trim().length === 0) {
      const message = "please select an accessory.";
      return { type: ["accessory"], message: [message] };
    }
    if (!isValidHttpUrl(exerciseInfo.videoLink)){
      const message = "URL must be valid. Ex) https://youtube.com/embed/abcdefghijk";
      return { type: ["videoLink"], message: [message] }
    } else if (exerciseInfo.videoLink !== undefined && exerciseInfo.videoLink.length > 2048) {
      const message = "url cannot be more than 2048 characters.";
      return { type: ["videoLink"], message: [message] }
    }
    if (exerciseInfo.description === undefined || exerciseInfo.description.trim().length === 0) {
      const message = "please enter an exercise description.";
      return { type: ["description"], message: [message] };
    } else if (exerciseInfo.description.trim().length > 256) {
      const message = "exercise description is limited to 256 characters or less.";
      return { type: ["description"], message: [message] };
    }
    const accessToken = localStorage.getItem("access_token");
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/exercise`, {
      name: exerciseInfo.name,
      muscleGroup: exerciseInfo.muscleGroup,
      accessory: exerciseInfo.accessory,
      videoLink: exerciseInfo.videoLink,
      description: exerciseInfo.description,
      refreshToken: exerciseInfo.refreshToken,
    },{
      headers: {
          Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
}

function isValidHttpUrl(string) {
  let url;
  if (string == undefined || string == "")
    return true;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  if (string.slice(0,26) === "https://youtube.com/embed/")
    return url.protocol === "https:";
  else
    return false;
}

function ExerciseCreate() {
  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToExercises = () => navigate("/dashboards/trainer/exercises");
  const navigateToCreateExercise = () => navigate("/dashboards/trainer/exercise-create");
  /* Values */
  const [name, setName] = useState();
  const [muscleGroup, setMuscleGroup] = useState();
  const [accessory, setAccessory] = useState("bodyweight");
  const [videoLink, setVideoLink] = useState();
  const [description, setDescription] = useState();
  const [trainerId, setTrainerId] = useState();
  /* Validation */
  const [validName, setValidName] = useState(true);
  const [validMuscleGroup, setValidMuscleGroup] = useState(true);
  const [validAccessory, setValidAccessory] = useState(true);
  const [validVideoLink, setValidVideoLink] = useState(true);
  const [validDescription, setValidDescription] = useState(true);
  /* Text */
  const [nameHelpText, setNameHelpText] = useState();
  const [muscleGroupHelpText, setMuscleGroupHelpText] = useState();
  const [accessoryHelpText, setAccessoryHelpText] = useState();
  const [videoLinkHelpText, setVideoLinkHelpText] = useState();
  const [descriptionHelpText, setDescriptionHelpText] = useState();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
  const hideSnackbar = () => setShowSnackbar(false);
  const [snackbarText, setSnackbarText] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const refreshToken = localStorage.getItem("refresh_token");
    const response = await createExercise({
      name,
      muscleGroup,
      accessory,
      videoLink,
      description,
      refreshToken,
    }).then((response) => {
      if (response.type && response.message) {
        for (let i = 0; i < response.type.length; i += 1) {
          if (response.type.length === response.message.length) {
            switch (response.type[i]) {
              case "name":
                setValidName(false);
                setNameHelpText(response.message[i]);
                return;
              case "muscleGroup":
                setValidMuscleGroup(false);
                setMuscleGroupHelpText(response.message[i]);
                return;
              case "accessory":
                setValidAccessory(false);
                setAccessoryHelpText(response.message[i]);
                return;
              case "videoLink":
                setValidVideoLink(false);
                setVideoLinkHelpText(response.message[i]);
                return;
              case "description":
                setValidDescription(false);
                setDescriptionHelpText(response.message[i]);
                return;
              default:
                break;
            }
          }
        }
      }
      if (response.status && response.status === 201) {
        navigate("/dashboards/trainer/exercises");
      }
    }).catch((err) => {
      console.log(err);
      setSnackbarText(err.response.data);
      toggleSnackbar(true);
    });
  }

  const errorStyle = ({
    palette: { error },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: error.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
    },
  });

  const toastTemplate = (
    <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
      {snackbarText}
      <CloseIcon
        fontSize="medium"
        sx={{ ml: 4, mr: -1, cursor: "pointer" }}
        onClick={toggleSnackbar}
      />
    </MKBox>
  );

  return (
    <DashboardLayout>
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={hideSnackbar}
          message={toastTemplate}
          action={toggleSnackbar}
          sx={errorStyle}
      />
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MDBox pb={3} mt={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Create Exercise
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Create, view, or edit an exercise to use in your workouts
                </MDTypography>
              </MDBox>
              <Grid pb={1}>
                <MDButton
                    variant="gradient"
                    color="secondary"
                    size="small"
                    onClick={navigateBack}
                    pr={3}
                    >
                    Back
                </MDButton>
              </Grid>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form" onSubmit={handleSubmit}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Name"
                      variant="standard"
                      fullWidth
                      error={!validName}
                      helperText={nameHelpText}
                      onChange={(e) => {
                        setName(e.target.value);
                        if (e.target.value) {
                          if (e.target.value.length > 32) {
                            // if not valid name
                            if (validName) {
                              setValidName(false);
                              setNameHelpText("Please limit the exercise name to 32 characters or less.");
                            }
                          } else {
                            setValidName(true);
                            setNameHelpText("");
                          }
                        } else {
                          setValidName(false);
                          setNameHelpText("please enter an exercise name.");
                        }
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="muscle-group"
                      label="Muscle Group"
                      variant="standard"
                      fullWidth
                      error={!validMuscleGroup}
                      helperText={muscleGroupHelpText}
                      onChange={(e) => {
                        setMuscleGroup(e.target.value);
                        if (e.target.value) {
                          if (e.target.value.length > 32) {
                            if (validMuscleGroup) {
                              setValidMuscleGroup(false);
                              setMuscleGroupHelpText("Please limit the muscle group to 32 characters or less.");
                            }
                          } else {
                            setValidMuscleGroup(true);
                            setMuscleGroupHelpText("");
                          }
                        } else {
                          setValidMuscleGroup(false);
                          setMuscleGroupHelpText("please enter a muscle group.");
                        }
                      }}
                    />
                  </MDBox>
                  <MDBox pb={2} pt={1}>
                    <InputLabel>Accessory</InputLabel>
                    <Select
                      value={accessory}
                      label="Accessory"
                      error={!validAccessory}
                      helperText={accessoryHelpText}
                      onChange={(e) => {
                        setAccessory(e.target.value);
                        if (e.target.value) {
                          setValidAccessory(true);
                          setAccessoryHelpText("");
                        } else {
                          setValidAccessory(false);
                        }
                      }}
                      
                    >
                      <MenuItem value="bands">Bands</MenuItem>
                      <MenuItem value="barbell">Barbell</MenuItem>
                      <MenuItem value="bodyweight">Bodyweight</MenuItem>
                      <MenuItem value="dumbells">Dumbells</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </MDBox>
                  
                  <MDBox mb={2}>
                    <MDInput
                      type="video-link"
                      label="Video Link (optional)"
                      variant="standard"
                      fullWidth
                      error={!validVideoLink}
                      helperText={videoLinkHelpText}
                      onChange={(e) => {
                        setVideoLink(e.target.value);
                        if (e.target.value) {
                          if (!isValidHttpUrl(e.target.value)) {
                            setValidVideoLink(false);
                            setVideoLinkHelpText("URL must be valid. Ex) https://youtube.com/embed/abcdefghijk");
                          } else if (e.target.value !== undefined && e.target.value.length > 2048) {
                            setValidVideoLink(false);
                            setVideoLinkHelpText("url cannot be more than 2048 characters.");
                          } else {
                            setValidVideoLink(true);
                            setVideoLinkHelpText("");
                          }
                        } else {
                          setValidVideoLink(true);
                          setVideoLinkHelpText("");
                        }
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="workout-description"
                      label="Workout Description"
                      variant="standard"
                      fullWidth
                      error={!validDescription}
                      helperText={descriptionHelpText}
                      onChange={(e) => {
                        setDescription(e.target.value);
                        if (e.target.value) {
                          if (e.target.value.length > 256) {
                            if (validDescription) {
                              setValidDescription(false);
                              setDescriptionHelpText("Please limit the description to 256 characters or less.");
                            }
                          } else {
                            setValidDescription(true);
                            setDescriptionHelpText("");
                          }
                        } else {
                          setValidDescription(false);
                          setDescriptionHelpText("please enter an exercise description.");
                        }
                      }}
                    />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                      Create
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ExerciseCreate;
