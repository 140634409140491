import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import DataTable from "examples/Tables/DataTable";
import axios from "axios";
// Data
//import exerciseTableData from "layouts/pages/applications/data-tables/data/exerciseTableData";

function Exercises() {
  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToDashboard = () => navigate("/dashboards/trainer");
  const navigateToCreateExercise = () => navigate("/dashboards/trainer/exercise-create");
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [exerciseTableData, setExerciseTableData] = useState({
    columns: [
      { Header: "name", accessor: "name", width: "20%" },
      { Header: "group", accessor: "muscleGroup", width: "20%" },
      { Header: "accessory", accessor: "accessory", width: "20%"},
      { Header: "edit", accessor: "edit", width: "10%" },
      { Header: "delete", accessor: "delete", width: "10%" },
    ],
    rows: [],
  });

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await setExercises();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/dashboards/trainer/exercises`]);
  
  async function setExercises() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/exercises`, {
      refreshToken: refreshToken,
      queryParams: {}
    }, {
      headers: {
        "Authorization": "Bearer " + accessToken,
      }
    })
    .then((response) => {
      for (var i=0; i<response.data.length; i++) {
        // append any new information we want to view or otherwise need from the exercise
        response.data[i].link = `/dashboards/trainer/exercise/${response.data[i].id}`;
        // response.data[i].buttonFunction = "navigateToEditExercise";
        response.data[i].editButtonFunction = "navigateToEditExercise";
        response.data[i].deleteButtonFunction = "pressDeleteExercise";
      }
      setExerciseTableData({
        "columns": [
          { Header: "name", accessor: "name", width: "20%" },
          { Header: "group", accessor: "muscleGroup", width: "20%" },
          { Header: "accessory", accessor: "accessory", width: "20%"},
          { Header: "edit", accessor: "edit", width: "10%" },
          { Header: "delete", accessor: "delete", width: "10%" },
        ],
        "rows": response.data,
      });
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <Grid mt={6}>
            <MDBox pb={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                My Exercises
              </MDTypography>
              <MDTypography variant="button" color="text">
                Create, view, or edit an exercise to use in your workouts
              </MDTypography>
            </MDBox>
            <Grid pb={1}>
              <MDButton
                  variant="gradient"
                  color="secondary"
                  size="small"
                  onClick={navigateBack}
                  pr={3}
                  >
                  Back
              </MDButton>
              &nbsp;
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={navigateToCreateExercise}
              >
                Create
              </MDButton>
            </Grid>
            <DataTable table={exerciseTableData} />
        </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Exercises;
