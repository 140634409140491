/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import EventCalendar from "examples/Calendar";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Calendar application components
import Header from "layouts/pages/applications/calendar/components/Header";
import NextEvents from "layouts/pages/applications/calendar/components/NextEvents";
import ProductivityChart from "layouts/pages/applications/calendar/components/ProductivityChart";

// Data
import calendarEventsData from "layouts/pages/applications/calendar/data/calendarEventsData";


function Analytics() {
  return (
    
    <DashboardLayout>
      <DashboardNavbar />
        <MDBox py={3}>
            <MDBox mb={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <DefaultInfoCard
                          title="Analytics"
                          description="You don't have any analytics yet (coming soon)."
                          icon={<Icon>analytics</Icon>}
                        />
                    </Grid>
                </Grid>
                {/*
        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title="Bench Press"
                  description={
                    <>
                      (<strong>+15%</strong>) since last month.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Deadlift"
                  description={
                    <>
                      (<strong>+18%</strong>) since last month.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="primary"
                  title="Squat"
                  description={
                    <>
                      (<strong>+18%</strong>) since last month.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        */}
            </MDBox>
        </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
