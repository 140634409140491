/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import reportsLineChartData from "layouts/pages/dashboards/analytics/data/reportsLineChartData";

// Trainee Dashboard Components
import TodaysWorkoutProgram from "layouts/dashboards/trainee/components/TodaysWorkoutProgram";
import TodaysWorkoutProgram2 from "layouts/dashboards/trainee/components/TodaysWorkoutProgram2";
import TodaysFoodPlan from "layouts/dashboards/trainee/components/TodaysFoodPlan";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

function TraineeDashboard() {
  const { sales, tasks } = reportsLineChartData;

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <MDBox py={0}>
            {/*}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ComplexStatisticsCard
                  color="primary"
                  icon={<FitnessCenterIcon />}
                  title="Upcoming Training"
                  count="06/25/2020"
                  percentage={{
                    label: "4:00PM CST",
                  }}
                />
              </MDBox>
               
            </Grid>
             */}
            <Grid item md={0} lg={12}>
                <TodaysWorkoutProgram2 />
            </Grid>
            {/*
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <TodaysFoodPlan />
              </MDBox>
            </Grid>
            */}
            
        </MDBox>
        {/*
        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title="Bench Press"
                  description={
                    <>
                      (<strong>+15%</strong>) since last month.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Deadlift"
                  description={
                    <>
                      (<strong>+18%</strong>) since last month.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="primary"
                  title="Squat"
                  description={
                    <>
                      (<strong>+18%</strong>) since last month.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        */}
        {/*
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Upcoming Workouts
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              This week's plan
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor1}
                label="Men's Bulk Day #1"
                title="Push"
                description="Heavy focus on chest and triceps."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view workout",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor1}
                label="Men's Bulk Day #2"
                title="Pull"
                description="Back and shoulder focus."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view workout",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor1}
                label="Men's Bulk Day #3"
                title="Legs"
                description="Heavy focus on quadraceps."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view workout",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor4}
                label="Workout Program"
                title="Men's Bulk"
                description="This program's is geared toward gaining strength."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view program",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
          </Grid>
        </MDBox>
              */}
      <Footer />
    </DashboardLayout>
  );
}

export default TraineeDashboard;
