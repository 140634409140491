import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import DataTable from "examples/Tables/DataTable";
import axios from "axios";
// Data
//import exerciseTableData from "layouts/pages/applications/data-tables/data/exerciseTableData";

function AddExerciseToWorkout() {
  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToWorkoutCreate= () => navigate("/dashboards/trainer/workout-create");
  const navigateToCreateExercise = () => {
    localStorage.removeItem('exercise');
    navigate("/dashboards/trainer/exercise-create");
  }

  const [exerciseTableData, setExerciseTableData] = useState({
    columns: [
      { Header: "name", accessor: "name", width: "25%" },
      { Header: "muscle group", accessor: "muscleGroup", width: "25%" },
      { Header: "accessory", accessor: "accessory", width: "25%" },
      { Header: "select", accessor: "select", width: "25%"},
    ],
    rows: [],
  });

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await setExercises();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/dashboards/trainer/exercises`]);
  
  async function setExercises() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/exercises`, {
      refreshToken: refreshToken,
      queryParams: {}
    }, {
      headers: {
        "Authorization": "Bearer " + accessToken,
      }
    })
    .then((response) => {
        for (var i=0; i<response.data.length; i++) {
            // append any new information we want to view or otherwise need from the exercise
            response.data[i].link = `/dashboards/trainer/exercise/${response.data[i].id}`;
            response.data[i].selectButtonFunction = "createAddExercise";
        }
        setExerciseTableData({
            "columns": [
            { Header: "name", accessor: "name", width: "25%" },
            { Header: "muscle group", accessor: "muscleGroup", width: "25%" },
            { Header: "accessory", accessor: "accessory", width: "25%" },
            { Header: "select", accessor: "select", width: "25%"},
            ],
            "rows": response.data,
        });
        return Promise.resolve();
    })
    .catch((err) => {
        console.log(err);
        return Promise.reject();
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <Grid mt={6} mb={18}>
            <MDBox pb={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Select Exercise
              </MDTypography>
              <MDTypography variant="button" color="text">
                Choose an exercise below to add to your workout
              </MDTypography>
            </MDBox>
            <Grid pb={1}>
              <MDButton
                  variant="gradient"
                  color="secondary"
                  size="small"
                  onClick={navigateBack}
                  pr={3}
                  >
                  Back
              </MDButton>
              &nbsp;
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={navigateToCreateExercise}
              >
                New Exercise
              </MDButton>
            </Grid>
            
            {/*
            <Grid pb={1}>
              <MDButton
                  variant="gradient"
                  color="secondary"
                  size="small"
                  onClick={navigateBack}
                  pr={3}
                  >
                  Back
              </MDButton>
            </Grid>
            */}
            <DataTable table={exerciseTableData} />
        </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default AddExerciseToWorkout;
