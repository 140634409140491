/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import axios from "axios";
import { identifier } from "stylis";
import GoalUpload from "./components/GoalUpload";
import FeedbackUpload from "./components/FeedbackUpload";

function Profile({ user }) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const traineeId = urlParams.get("client");
  const [trainee, setTrainee] = useState();
  const [program, setProgram] = useState();
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToSelectProgram = () => navigate("/dashboards/trainer/programs/select", {
    state: {
      traineeId: trainee.id,
    }
  });

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await getTrainee();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/trainer/client`]);

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await getProgram();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/program/trainee/${traineeId}`]);

  async function getTrainee() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (traineeId !== null) {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/client`, {
          traineeId: traineeId,
          refreshToken: refreshToken,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
      })
      .then((response) => {
        setTrainee(response.data.trainee)
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject();
      });
    }
  }

  async function getProgram() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (traineeId) {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/program/trainee/${traineeId}`, {
          refreshToken: refreshToken,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
      })
      .then((response) => {
        setProgram(response.data[0].name);
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject();
      });
    }
  }

    return (
      <DashboardLayout>
        <DashboardNavbar />
          <MDBox py={3}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                <Header user={user}>
                  <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                    {user !== undefined && 
                      <ProfileInfoCard
                        title="profile information"
                        description={user.description}
                        info={{
                          fullName: user.name,
                          mobile: user.phoneNumber,
                          email: user.email,
                          location: user.location,
                        }}
                        social={[
                          {
                            link: "https://www.facebook.com/CreativeTim/",
                            icon: <FacebookIcon />,
                            color: "facebook",
                          },
                          {
                            link: "https://twitter.com/creativetim",
                            icon: <TwitterIcon />,
                            color: "twitter",
                          },
                          {
                            link: "https://www.instagram.com/creativetimofficial/",
                            icon: <InstagramIcon />,
                            color: "instagram",
                          },
                        ]}
                        action={{ route: "/account/settings#basic-info", tooltip: "Edit Profile" }}
                        shadow={false}
                      />
                    }
                  <Divider orientation="vertical" sx={{ mx: 0 }} />
                  </Header>
                </Grid>
                <Grid item xs={12} sm={6} mt={1} mb={3}>
                  <Card id="goals" mb={3}>
                      <MDBox p={2}>
                          <MDTypography variant="h5" fontWeight="medium">
                            Goals
                          </MDTypography>
                      </MDBox>
                      <GoalUpload user={user} />
                      {/*
                      <TextField
                        id="standard-multiline-flexible"
                        label="What are your goals?"
                        multiline
                        minRows={6}
                        maxRows={6}
                        variant="standard"
                        mb={3}
                      />
                      <MDBox mt={4} mb={1}>
                        <MDButton variant="gradient" color="info">
                          Save
                        </MDButton>
                      </MDBox>
                      */}
                  </Card>
                  { ( user?.feedbackDeadline === undefined || user?.feedbackDeadline < new Date()) &&
                    <Grid mt={3}> 
                      <Card id="feedback" mb={3}>
                        <MDBox p={2}>
                            <MDTypography variant="h5" fontWeight="medium">
                              Feedback
                            </MDTypography>
                        </MDBox>
                        <FeedbackUpload user={user} />
                      </Card>
                    </Grid>
                  }
                  
                </Grid>
                <Grid item xs={12} sm={6} mt={1} mb={3}>
                 
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        <Footer />
      </DashboardLayout>
    );
}

export default Profile;
