// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";
// @mui material components
import Grid from "@mui/material/Grid";
// Pricing page components
import Header from "layouts/main/components/Header";
import ContactUsOne from "layouts/main/components/ContactUsOne";
import FooterThree from "layouts/main/components/FooterThree";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// rellax
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";


// Images
//import bgImage from "assets/images/bg-coworking.jpeg";
import bgImage from "assets/images/row-of-dumbbells.jpg";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function ContactUs() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);
  const navigate = useNavigate();
  const navigateFreeTrial = () => {
    navigate("/free-trial");
  }

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });
    return () => parallax.destroy();
  }, []);

  return (
    <PageLayout>
      <Header active="Contact Us"/>
      <br>
      </br>
      <br>
      </br>
      <MKBox
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
            Contact Us<span ref={typedJSRef} />
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
            For further questions, concerns, or partnership opportunities, please email
            service@fittoscale.io or contact us using the form below.
            </MKTypography>
            <MKButton color="default" onClick={navigateFreeTrial} sx={{ color: ({ palette: { dark } }) => dark.main }}>
              get started for free
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <ContactUsOne />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <FooterThree />
      </MKBox>
    </PageLayout>
  );
}

export default ContactUs;
