import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MKBox from "components/MKBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import validator from "validator";
// @mui icons
import CloseIcon from "@mui/icons-material/Close";
import { ConstructionOutlined } from "@mui/icons-material";

async function editPage(pageInfo) {
    /* URL Prefix */
    // URL length equal to 14 is empty because it includes ".fittoscale.io" (14 characters)
    if (pageInfo.url === "undefined.fittoscale.io" || pageInfo.url === "fittoscale.io/pages/undefined" || pageInfo.url === "localhost:3000/pages/undefined" || pageInfo.url === pageInfo.url.trim().length === 14) {
      const message = "please enter a url prefix.";
      return { type: ["urlPrefix"], message: [message] };
    //see note above^
    } else if (pageInfo.url.trim().length > 46) {
      const message = "the url prefix cannot exceed 32 characters.";
      return { type: ["urlPrefix"], message: [message] };
    }
    /* Headline & Supporting Text */
    if (pageInfo.headline === undefined || pageInfo.headline.trim().length === 0) {
        const message = "please enter a headline.";
        return { type: ["headline"], message: [message] };
    } else if (pageInfo.headline.trim().length > 256) {
        const message = "the headline cannot exceed 256 characters.";
        return { type: ["headline"], message: [message] };
    }
    if (pageInfo.headline2 === undefined || pageInfo.headline2.trim().length === 0) {
        const message = "please enter supporting text.";
        return { type: ["headline2"], message: [message] };
    } else if (pageInfo.headline2.trim().length > 256) {
        const message = "the supporting text cannot exceed 256 characters.";
        return { type: ["headline2"], message: [message] };
    }
    /* Feature 1 */
    if (pageInfo.feature1_headline === undefined || pageInfo.feature1_headline.trim().length === 0) {
        const message = "please enter a feature 1 headline.";
        return { type: ["feature1_headline"], message: [message] };
    } else if (pageInfo.feature1_headline.trim().length > 256) {
        const message = "the feature 1 headline cannot exceed 256 characters.";
        return { type: ["feature1_headline"], message: [message] };
    }
    if (pageInfo.feature1_headline2 === undefined || pageInfo.feature1_headline2.trim().length === 0) {
        const message = "please enter feature 1 supporting text.";
        return { type: ["feature1_headline2"], message: [message] };
    } else if (pageInfo.feature1_headline2.trim().length > 256) {
        const message = "the feature 1 supporting text cannot exceed 256 characters.";
        return { type: ["feature1_headline2"], message: [message] };
    }
    /* Feature 2 */
    if (pageInfo.feature2_headline === undefined || pageInfo.feature2_headline.trim().length === 0) {
        const message = "please enter a feature 2 headline.";
        return { type: ["feature2_headline"], message: [message] };
    } else if (pageInfo.feature2_headline.trim().length > 256) {
        const message = "the feature 2 headline cannot exceed 256 characters.";
        return { type: ["feature2_headline"], message: [message] };
    }
    if (pageInfo.feature2_headline2 === undefined || pageInfo.feature2_headline2.trim().length === 0) {
        const message = "please enter feature 2 supporting text.";
        return { type: ["feature2_headline2"], message: [message] };
    } else if (pageInfo.feature2_headline2.trim().length > 256) {
        const message = "the feature 2 supporting text cannot exceed 256 characters.";
        return { type: ["feature2_headline2"], message: [message] };
    }
    /* Feature 3 */
    if (pageInfo.feature3_headline === undefined || pageInfo.feature3_headline.trim().length === 0) {
        const message = "please enter a feature 3 headline.";
        return { type: ["feature3_headline"], message: [message] };
    } else if (pageInfo.feature3_headline.trim().length > 256) {
        const message = "the feature 3 headline cannot exceed 256 characters.";
        return { type: ["feature3_headline"], message: [message] };
    }
    if (pageInfo.feature3_headline2 === undefined || pageInfo.feature3_headline2.trim().length === 0) {
        const message = "please enter feature 3 supporting text.";
        return { type: ["feature3_headline2"], message: [message] };
    } else if (pageInfo.feature3_headline2.trim().length > 256) {
        const message = "the feature 3 supporting text cannot exceed 256 characters.";
        return { type: ["feature3_headline2"], message: [message] };
    }
    /* Testimonials */

    /* Guarantee */
    if (pageInfo.guarantee_headline === undefined || pageInfo.guarantee_headline.trim().length === 0) {
        const message = "please enter a guarantee headline.";
        return { type: ["guarantee_headline"], message: [message] };
    } else if (pageInfo.guarantee_headline.trim().length > 256) { 
        const message = "the guarantee headline cannot exceed 256 characters.";
        return { type: ["guarantee_headline"], message: [message] };
    }
    if (pageInfo.guarantee_headline2 === undefined || pageInfo.guarantee_headline2.trim().length === 0) {
        const message = "please enter guarantee support text.";
        return { type: ["guarantee_headline2"], message: [message] };
    } else if (pageInfo.guarantee_headline2.trim().length > 256) { 
        const message = "the guarantee supporting text cannot exceed 256 characters.";
        return { type: ["guarantee_headline2"], message: [message] };
    }

    const accessToken = localStorage.getItem("access_token");
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/page`, {
      url: pageInfo.url,
      trainerId: pageInfo.trainerId,
      headline: pageInfo.headline,
      headline2: pageInfo.headline2,
      feature1_headline: pageInfo.feature1_headline,
      feature1_headline2: pageInfo.feature1_headline2,
      feature2_headline: pageInfo.feature2_headline,
      feature2_headline2: pageInfo.feature2_headline2,
      feature3_headline: pageInfo.feature3_headline,
      feature3_headline2: pageInfo.feature3_headline2,
      //testimonial_headline: pageInfo.testimonial_headline,
      //testimonial_headline2: pageInfo.testimonial_headline2,
      //testimonials: pageInfo.testimonials,
      guarantee_headline: pageInfo.guarantee_headline,
      guarantee_headline2: pageInfo.guarantee_headline2,
      urlPrefix: pageInfo.urlPrefix,
      refreshToken: pageInfo.refreshToken,
    },{
      headers: {
          Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
}

function PageEdit(user) {
  user = user.user;
  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToViewPage = () => {
    if (user.urlPrefix) {
      navigate(`/pages/${user.urlPrefix}`);
    } else {
      navigate('/pages');
    }
  };
  const navigateToPages = () => navigate("/dashboards/trainer/pages");
  const navigateToEditPage = () => navigate("/dashboards/trainer/page-edit");
  /* Values */
  const [urlPrefix, setUrlPrefix] = useState();
  const [headline, setHeadline] = useState("Start Your Fitness Journey");
  const [headline2, setHeadline2] = useState("with a trainer you can trust");
  const [feature1_headline, setFeature1_headline] = useState("Custom Programs");
  const [feature1_headline2, setFeature1_headline2] = useState("I'll create a custom program tailored to your goals.");
  const [feature2_headline, setFeature2_headline] = useState("Personalized Goals");
  const [feature2_headline2, setFeature2_headline2] = useState("I'll work with you to set goals and hold you accountable.");
  const [feature3_headline, setFeature3_headline] = useState("Weekly Feedback");
  const [feature3_headline2, setFeature3_headline2] = useState("I'll assess your progress and guide you through your fitness journey.");
  //const [testimonial_headline, setTestimonial_headline] = useState();
  //const [testimonial_headline2, setTestimonial_headline2] = useState();
  //const [testimonials, setTestimonials] = useState();
  const [guarantee_headline, setGuarantee_headline] = useState("30-Day Money Back Guarantee");
  const [guarantee_headline2, setGuarantee_headline2] = useState("Get 100% of your money back if you're not satisfied within the first 30 days of purchasing my custom workout program");
  //const [product1, setProduct1] = useState();
  //const [product2, setProduct2] = useState();
  //const [product3, setProduct3] = useState();
  /* Validation */
  const [validUrlPrefix, setValidUrlPrefix] = useState(true);
  const [validHeadline, setValidHeadline] = useState(true);
  const [validHeadline2, setValidHeadline2] = useState(true);
  const [validFeature1_headline, setValidFeature1_headline] = useState(true);
  const [validFeature1_headline2, setValidFeature1_headline2] = useState(true);
  const [validFeature2_headline, setValidFeature2_headline] = useState(true);
  const [validFeature2_headline2, setValidFeature2_headline2] = useState(true);
  const [validFeature3_headline, setValidFeature3_headline] = useState(true);
  const [validFeature3_headline2, setValidFeature3_headline2] = useState(true);
  //const [validTestimonial_headline, setValidTestimonial_headline] = useState(true);
  //const [validTestimonial_headline2, setValidTestimonial_headline2] = useState(true);
  const [validGuarantee_headline, setValidGuarantee_headline] = useState(true);
  const [validGuarantee_headline2, setValidGuarantee_headline2] = useState(true);
  //const [validProduct1, setValidProduct1] = useState(true);
  //const [validProduct2, setValidProduct2] = useState(true);
  //const [validProduct3, setValidProduct3] = useState(true);
  /* Text */
  const [urlPrefixHelpText, setUrlPrefixHelpText] = useState();
  const [headlineHelpText, setHeadlineHelpText] = useState();
  const [headline2HelpText, setHeadline2HelpText] = useState();
  const [feature1_headlineHelpText, setFeature1_headlineHelpText] = useState();
  const [feature1_headline2HelpText, setFeature1_headline2HelpText] = useState();
  const [feature2_headlineHelpText, setFeature2_headlineHelpText] = useState();
  const [feature2_headline2HelpText, setFeature2_headline2HelpText] = useState();
  const [feature3_headlineHelpText, setFeature3_headlineHelpText] = useState();
  const [feature3_headline2HelpText, setFeature3_headline2HelpText] = useState();
  //const [testimonial_headlineHelpText, setTestimonial_headlineHelpText] = useState();
  //const [testimonial_headline2HelpText, setTestimonial_headline2HelpText] = useState();
  //const [testimonialsHelpText, setTestimonialsHelpText] = useState();
  const [guarantee_headlineHelpText, setGuarantee_headlineHelpText] = useState();
  const [guarantee_headline2HelpText, setGuarantee_headline2HelpText] = useState();
  //const [product1HelpText, setProduct1HelpText] = useState();
  //const [product2HelpText, setProduct2HelpText] = useState();
  //const [product3HelpText, setProduct3HelpText] = useState();
  /* Snackbar */
  const [showSnackbar, setShowSnackbar] = useState(false);
  const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
  const hideSnackbar = () => setShowSnackbar(false);
  const [snackbarText, setSnackbarText] = useState();
  const [snackbarStyle, setSnackbarStyle] = useState();


  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (user) {
      if (!(refreshToken === undefined || accessToken === undefined)) {
        const fetchData = async() => await setPage();
        fetchData().catch(console.error);
      }
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/trainer/page/get`]);

  async function setPage() {
      if (user) {
        await axios.get(`${process.env.REACT_APP_API_URL}/v1/trainer/page/${user.urlPrefix}`)
        .then((response) => {
          console.log("response: ", response);
          let pageData = response.data;
          setUrlPrefix(user.urlPrefix);
          if (pageData != "") {
            setHeadline(pageData.headline);
            setHeadline2(pageData.headline2);
            setFeature1_headline(pageData.feature1_headline);
            setFeature1_headline2(pageData.feature1_headline2);
            setFeature2_headline(pageData.feature2_headline);
            setFeature2_headline2(pageData.feature2_headline2);
            setFeature3_headline(pageData.feature3_headline);
            setFeature3_headline2(pageData.feature3_headline2);
            setGuarantee_headline(pageData.guarantee_headline);
            setGuarantee_headline2(pageData.guarantee_headline2);
            //setBusinessName(pageData.businessName);
            //setProduct1(pageData.product1);
            //setProduct2(pageData.product2);
            //setProduct3(pageData.product3);
          }
          
          return Promise.resolve();
        })
        .catch((error) => {
          console.log("error: ", error);
          return Promise.reject();
        });
      }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const refreshToken = localStorage.getItem("refresh_token");
    let urlArray = window.location.href.split("/");
    let url = "";
    if (`${urlArray[0]}/${urlArray[1]}/${urlArray[2]}` == `${process.env.REACT_APP_FRONT_END_URL}`) {
        url = `${urlArray[2]}/pages/${urlPrefix}`;
    }
    await editPage({
      url: url,
      trainerId: user._id,
      headline,
      headline2,
      feature1_headline,
      feature1_headline2,
      feature2_headline,
      feature2_headline2,
      feature3_headline,
      feature3_headline2,
      //testimonial_headline,
      //testimonial_headline2,
      //testimonials,
      guarantee_headline,
      guarantee_headline2,
      urlPrefix,
      refreshToken,
    }).then((response) => {
      if (response.type && response.message) {
        for (let i = 0; i < response.type.length; i += 1) {
          if (response.type.length === response.message.length) {
            switch (response.type[i]) {
                case "urlPrefix":
                    setValidUrlPrefix(false);
                    setUrlPrefixHelpText(response.message[i]);
                    return;
                case "headline":
                    setValidHeadline(false);
                    setHeadlineHelpText(response.message[i]);
                    return;
                case "headline2":
                    setValidHeadline2(false);
                    setHeadline2HelpText(response.message[i]);
                    return;
                case "feature1_headline":
                    setValidFeature1_headline(false);
                    setFeature1_headlineHelpText(response.message[i]);
                    return;
                case "feature1_headline2":
                    setValidFeature1_headline2(false);
                    setFeature1_headline2HelpText(response.message[i]);
                    return;
                case "feature2_headline":
                    setValidFeature2_headline(false);
                    setFeature2_headlineHelpText(response.message[i]);
                    return;
                case "feature2_headline2":
                    setValidFeature2_headline2(false);
                    setFeature2_headline2HelpText(response.message[i]);
                    return;
                case "feature3_headline":
                    setValidFeature3_headline(false);
                    setFeature3_headlineHelpText(response.message[i]);
                    return;
                case "feature3_headline2":
                    setValidFeature3_headline2(false);
                    setFeature3_headline2HelpText(response.message[i]);
                    return;
              //case "testimonial_headline":
              //  return;
              //case "testimonial_headline2":
              //  return;
              //case "testimonials":
              //  return;
              //default:
              //  break;
              case "guarantee_headline":
                  setValidGuarantee_headline(false);
                  setGuarantee_headlineHelpText(response.message[i]);
                  return;
              case "guarantee_headline2":
                  setValidGuarantee_headline2(false);
                  setGuarantee_headline2HelpText(response.message[i]);
                  return;
            }
          }
        }
      }
      if (response.status && response.status === 200) {
        //Test//navigate("/dashboards/trainer/pages");
        //setSnackbarStyle("successStyle");
        //setSnackbarText("Landing page successfully saved.");
        //toggleSnackbar(true);
        window.location.reload(false);
      }
    }).catch((err) => {
      console.log(err);
      setSnackbarStyle("errorStyle");
      setSnackbarText(err.response.data);
      toggleSnackbar(true);
    });
  }

  const successStyle = ({
    palette: { success },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
    }) => ({
    "& .MuiPaper-root": {
        backgroundColor: success.main,
        borderRadius: borderRadius.lg,
        fontSize: size.sm,
        fontWeight: 400,
        boxShadow: lg,
        px: 2,
        py: 0.5,
    },
  });

  const errorStyle = ({
    palette: { error },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: error.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
    },
  });

  const toastTemplate = (
    <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
      {snackbarText}
      <CloseIcon
        fontSize="medium"
        sx={{ ml: 4, mr: -1, cursor: "pointer" }}
        onClick={toggleSnackbar}
      />
    </MKBox>
  );

  return (
    <DashboardLayout>
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={hideSnackbar}
          message={toastTemplate}
          action={toggleSnackbar}
          sx={snackbarStyle == "successStyle" ? successStyle : errorStyle}
      />
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MDBox pb={3} mt={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Edit Landing Page
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Choose what to prefill on the page to sell your products
                </MDTypography>
              </MDBox>
              <Grid pb={1}>
                <MDButton
                    variant="gradient"
                    color="secondary"
                    size="small"
                    onClick={navigateBack}
                    pr={3}
                    >
                    Back
                </MDButton>
                &nbsp;
                <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={navigateToViewPage}
                    pr={3}
                    >
                    View
                </MDButton>
              </Grid>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form" onSubmit={handleSubmit}>
                  {/* URL */}
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="URL Prefix"
                      variant="standard"
                      fullWidth
                      error={!validUrlPrefix}
                      helperText={urlPrefixHelpText}
                      value={urlPrefix}
                      InputLabelProps={{ shrink: (urlPrefix != undefined) }}
                      onChange={(e) => {
                        setUrlPrefix(e.target.value);
                        if (e.target.value) {
                          if (e.target.value.length > 32) {
                            // if not valid name
                            if (validUrlPrefix) {
                              setValidUrlPrefix(false);
                              setUrlPrefixHelpText("Please limit the url prefix to 32 characters or less.");
                            }
                          } else {
                            setValidUrlPrefix(true);
                            setUrlPrefixHelpText("");
                          }
                        } else {
                            setValidUrlPrefix(false);
                            setUrlPrefixHelpText("please enter a url prefix to identify your landing page.");
                        }
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Headline"
                      variant="standard"
                      fullWidth
                      error={!validHeadline}
                      helperText={headlineHelpText}
                      value={headline}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => {
                        setHeadline(e.target.value);
                        if (e.target.value) {
                          if (e.target.value.length > 256) {
                            // if not valid name
                            if (validHeadline) {
                              setValidHeadline(false);
                              setHeadlineHelpText("Please limit the headline to 256 characters or less.");
                            }
                          } else {
                            setValidHeadline(true);
                            setHeadlineHelpText("");
                          }
                        } else {
                            setValidHeadline(false);
                            setHeadlineHelpText("please enter a headline.");
                        }
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Supporting Text"
                      variant="standard"
                      fullWidth
                      error={!validHeadline2}
                      helperText={headline2HelpText}
                      value={headline2}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => {
                        setHeadline2(e.target.value);
                        if (e.target.value) {
                          if (e.target.value.length > 256) {
                            // if not valid name
                            if (validHeadline2) {
                              setValidHeadline2(false);
                              setHeadline2HelpText("Please limit the supporting text to 256 characters or less.");
                            }
                          } else {
                            setValidHeadline2(true);
                            setHeadline2HelpText("");
                          }
                        } else {
                            setValidHeadline2(false);
                            setHeadline2HelpText("please enter supporting text.");
                        }
                      }}
                    />
                  </MDBox>
                  <Grid mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDInput
                                type="text"
                                label="Feature 1 Headline"
                                variant="standard"
                                fullWidth
                                value={feature1_headline}
                                error={!validFeature1_headline}
                                helperText={feature1_headlineHelpText}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    setFeature1_headline(e.target.value);
                                    if (e.target.value) {
                                        if (e.target.value.length > 256) {
                                            if (validFeature1_headline) {
                                                setValidFeature1_headline(false);
                                                setValidFeature1_headlineHelpText("Please limit the feature 1 headline to 256 characters or less.");
                                            }
                                        } else {
                                            setValidFeature1_headline(true);
                                            setFeature1_headlineHelpText("");
                                        }
                                    } else {
                                        setValidFeature1_headline(false);
                                        setFeature1_headlineHelpText("please enter a feature 1 headline.");
                                    }
                                }}
                                />
                                <Grid mb={2}/>
                                <MDInput
                                type="text"
                                label="Feature 1 Supporting Text"
                                variant="standard"
                                multiline
                                fullWidth
                                rows={3}
                                value={feature1_headline2}
                                error={!validFeature1_headline2}
                                helperText={feature1_headline2HelpText}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    setFeature1_headline2(e.target.value);
                                    if (e.target.value) {
                                        if (e.target.value.length > 256) {
                                            if (validFeature1_headline2) {
                                                setValidFeature1_headline2(false);
                                                setFeature1_headline2HelpText("Please limit the feature 1 supporting text to 256 characters or less.");
                                            }
                                        } else {
                                            setValidFeature1_headline2(true);
                                            setFeature1_headline2HelpText("");
                                        }
                                    } else {
                                        setValidFeature1_headline2(false);
                                        setFeature1_headline2HelpText("please enter feature 1 supporting text.");
                                    }
                                }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDInput
                                type="text"
                                label="Feature 2 Headline"
                                variant="standard"
                                fullWidth
                                value={feature2_headline}
                                error={!validFeature2_headline}
                                helperText={feature2_headlineHelpText}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    setFeature2_headline(e.target.value);
                                    if (e.target.value) {
                                        if (e.target.value.length > 256) {
                                            if (validFeature2_headline) {
                                                setValidFeature2_headline(false);
                                                setValidFeature2_headlineHelpText("Please limit the feature 2 headline to 256 characters or less.");
                                            }
                                        } else {
                                            setValidFeature2_headline(true);
                                            setFeature2_headlineHelpText("");
                                        }
                                    } else {
                                        setValidFeature2_headline(false);
                                        setFeature2_headlineHelpText("please enter a feature 2 headline.");
                                    }
                                }}
                                />
                                <Grid mb={2}/>
                                <MDInput
                                type="text"
                                label="Feature 2 Supporting Text"
                                variant="standard"
                                multiline
                                fullWidth
                                rows={3}
                                value={feature2_headline2}
                                error={!validFeature2_headline2}
                                helperText={feature2_headline2HelpText}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    setFeature2_headline2(e.target.value);
                                    if (e.target.value) {
                                        if (e.target.value.length > 256) {
                                            if (validFeature2_headline2) {
                                                setValidFeature2_headline2(false);
                                                setFeature2_headline2HelpText("Please limit the feature 2 supporting text to 256 characters or less.");
                                            }
                                        } else {
                                            setValidFeature2_headline2(true);
                                            setFeature2_headline2HelpText("");
                                        }
                                    } else {
                                        setValidFeature2_headline2(false);
                                        setFeature2_headline2HelpText("please enter feature 2 supporting text.");
                                    }
                                }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDInput
                                type="text"
                                label="Feature 3 Headline"
                                variant="standard"
                                fullWidth
                                error={!validFeature3_headline}
                                helperText={feature3_headlineHelpText}
                                value={feature3_headline}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    setFeature3_headline(e.target.value);
                                    if (e.target.value) {
                                        if (e.target.value.length > 256) {
                                            if (validFeature3_headline) {
                                                setValidFeature3_headline(false);
                                                setValidFeature3_headlineHelpText("Please limit the feature 3 headline to 256 characters or less.");
                                            }
                                        } else {
                                            setValidFeature3_headline(true);
                                            setFeature3_headlineHelpText("");
                                        }
                                    } else {
                                        setValidFeature3_headline(false);
                                        setFeature3_headlineHelpText("please enter a feature 3 headline.");
                                    }
                                }}
                                />
                                <Grid mb={2}/>
                                <MDInput
                                type="text"
                                label="Feature 3 Supporting Text"
                                variant="standard"
                                multiline
                                fullWidth
                                rows={3}
                                value={feature3_headline2}
                                error={!validFeature3_headline2}
                                helperText={feature3_headline2HelpText}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    setFeature3_headline2(e.target.value);
                                    if (e.target.value) {
                                        if (e.target.value.length > 256) {
                                            if (validFeature3_headline2) {
                                                setValidFeature3_headline2(false);
                                                setFeature3_headline2HelpText("Please limit the feature 3 supporting text to 256 characters or less.");
                                            }
                                        } else {
                                            setValidFeature3_headline2(true);
                                            setFeature3_headline2HelpText("");
                                        }
                                    } else {
                                        setValidFeature3_headline2(false);
                                        setFeature3_headline2HelpText("please enter feature 3 supporting text.");
                                    }
                                }}
                                />
                            </Grid>
                        </Grid>
                  </Grid>
                  {/*
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Testimonial Headline"
                      variant="standard"
                      fullWidth
                      error={!validTestimonial_headline}
                      helperText={testimonial_headlineHelpText}
                      onChange={(e) => {
                        setTestimonial_headline(e.target.value);
                        if (e.target.value) {
                          if (e.target.value.length > 256) {
                            if (validTestimonial_headline) {
                              setValidTestimonial_headline(false);
                              setTestimonial_headlineHelpText("Please limit the testimonial headline to 256 characters or less.");
                            }
                          } else {
                            setValidTestimonial_headline(true);
                            setTestimonial_headlineHelpText("");
                          }
                        } else {
                            setValidTestimonial_headline(false);
                            setTestimonial_headlineHelpText("please enter a testimonial headline.");
                        }
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                  <MDInput
                      type="text"
                      label="Testimonial Supporting Text"
                      variant="standard"
                      fullWidth
                      error={!validTestimonial_headline2}
                      helperText={testimonial_headline2HelpText}
                      onChange={(e) => {
                        setTestimonial_headline2(e.target.value);
                        if (e.target.value) {
                          if (e.target.value.length > 256) {
                            if (validTestimonial_headline2) {
                              setValidTestimonial_headline2(false);
                              setTestimonial_headline2HelpText("Please limit the testimonial supporting text to 256 characters or less.");
                            }
                          } else {
                            setValidTestimonial_headline2(true);
                            setTestimonial_headline2HelpText("");
                          }
                        } else {
                            setValidTestimonial_headline2(false);
                            setTestimonial_headline2HelpText("please enter testimonial supporting text.");
                        }
                      }}
                    />
                  </MDBox>
                    */}
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Guarantee Headline"
                      variant="standard"
                      fullWidth
                      error={!validGuarantee_headline}
                      helperText={guarantee_headlineHelpText}
                      value={guarantee_headline}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => {
                        setGuarantee_headline(e.target.value);
                        if (e.target.value) {
                          if (e.target.value.length > 256) {
                            if (validGuarantee_headline) {
                              setValidGuarantee_headline(false);
                              setGuarantee_headlineHelpText("Please limit the guarantee headline to 256 characters or less.");
                            }
                          } else {
                            setValidGuarantee_headline(true);
                            setGuarantee_headlineHelpText("");
                          }
                        } else {
                            setValidGuarantee_headline(false);
                            setGuarantee_headlineHelpText("please enter a guarantee headline.");
                        }
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                  <MDInput
                      type="text"
                      label="Guarantee Supporting Text"
                      variant="standard"
                      multiline
                      fullWidth
                      rows={3}
                      value={guarantee_headline2}
                      error={!validGuarantee_headline2}
                      helperText={guarantee_headline2HelpText}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => {
                        setGuarantee_headline2(e.target.value);
                        if (e.target.value) {
                          if (e.target.value.length > 256) {
                            if (validGuarantee_headline2) {
                              setValidGuarantee_headline2(false);
                              setGuarantee_headline2HelpText("Please limit the guarantee supporting text to 256 characters or less.");
                            }
                          } else {
                            setValidGuarantee_headline2(true);
                            setGuarantee_headline2HelpText("");
                          }
                        } else {
                            setValidGuarantee_headline2(false);
                            setGuarantee_headline2HelpText("please enter guarantee supporting text.");
                        }
                      }}
                    />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PageEdit;
