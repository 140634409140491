// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";
// @mui material components
import Grid from "@mui/material/Grid";
// Pricing page components
import Header from "layouts/main/components/Header";
import ContactUsOne from "layouts/main/components/ContactUsOne";
import FeaturesOne from "layouts/main/components/FeaturesOne";
import FeaturesTwo from "layouts/main/components/FeaturesTwo";
import FeaturesThree from "layouts/main/components/FeaturesThree";
import HeaderThree from "layouts/main/components/HeaderThree";
import StatsOne from "layouts/main/components/StatsOne";
import TestimonialsOne from "layouts/main/components/TestimonialsOne";
import Footer from "layouts/main/components/Footer";
import FooterThree from "layouts/main/components/FooterThree";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CtaThree from "layouts/main/components/CtaThree";

// rellax
import Rellax from "rellax";

// typed-js
import * as Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "./Information";
import Team from "./Team";
import Featuring from "./Featuring";
import Newsletter from "./Newsletter";

// Routes
import routes from "routes";
import footerRoutes from "../footer.routes";

// Images
//import bgImage from "assets/images/bg-coworking.jpeg";
import bgImage from "assets/images/row-of-dumbbells.jpg";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

import Guarantee from "../components/Guarantee";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function Home() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);
  const navigate = useNavigate();
  const navigateFreeTrial = () => {
    navigate("/free-trial");
  }

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  return (
    <PageLayout>
      <Header active="Home"/>
      <br>
      </br>
      <br>
      </br>
      {/*<HeaderThree />*/}
      <MKBox
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
            Grow Your Personal Training Business Online<span ref={typedJSRef} />
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Sign up for free to see how we&apos;re fit to scale your personal training business today.
            </MKTypography>
            <MKButton color="default" onClick={navigateFreeTrial} sx={{ color: ({ palette: { dark } }) => dark.main }}>
              get started for free
            </MKButton>
            {/*
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
              Find us on
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <TwitterIcon />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <InstagramIcon />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <LinkedInIcon />
              </MKTypography>
            </MKBox>*/}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
         // p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <StatsOne />
        {/*<FeaturesTwo />*/}
        <FeaturesThree />
        {/*<Divider sx={{ my: 6 }} />*/}
        {/*<TestimonialsOne />*/}
        <Grid my={8}>
            <Guarantee />
        </Grid>
       {/* <Divider sx={{ my: 6 }} />*/}
        {/*<ContactUsOne />*/}
        <CtaThree />
        {/*
        <Information />
        <Team />
        <Featuring />
        <Newsletter />
      */}
      </Card>
      
      <MKBox pt={6} px={1} mt={6}>
        {/*<DefaultFooter content={footerRoutes} />*/}
        <FooterThree />
      </MKBox>
      
      {/*
      <Grid>
        <StatsOne />
        <FeaturesThree />
        <TestimonialsOne />
        <ContactUsOne />
      </Grid>
      <Footer />
      */}
    </PageLayout>
  );
}

export default Home;
