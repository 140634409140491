/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";

// Validation
import validator from "validator";

// API
import axios from "axios";

async function loginUser(credentials, trainer) {
  try {
    if (credentials.email === undefined || credentials.email.trim().length === 0) {
      return { type: "email", message: "please enter an email address" };
    }
    if (!validator.isEmail(credentials.email)) {
      return { type: "email", message: "please enter a valid email address" };
    }
    if (credentials.password === undefined || credentials.password.length === 0) {
      return { type: "password", message: "please enter a password" };
    }

    var trainerString = "";
    if (trainer) {
      trainerString = "?trainer=" + trainer;
    } else if (credentials?.urlPrefixToSignUpWith & credentials?.productIdToSignUpWith) {
      trainerString = `?urlPrefix=${credentials.urlPrefixToSignUpWith}&productId=${credentials.productIdToSignUpWith}`;
    }
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/login` + trainerString, {
      email: credentials.email,
      password: credentials.password,
      urlPrefixToSignUpWith: credentials?.urlPrefixToSignUpWith,
      productIdToSignUpWith: credentials?.productIdToSignUpWith,
    });
    if (response.code) {
      return {
        code: response.status,
        message: response.message,
      };
    }
    if (response.user && response.user.isEmailVerified === false) {
      return {
        code: response.status,
        message: "Please verify your email before logging in.",
      };
    }
    return {
      code: response.status,
      token: response.data.tokens,
    };
  } catch (err) {
    console.log(err);
    return {
      status: 401,
      type: "password",
      message: "Incorrect email or password, please try again.",
    };
  }
}

function Cover() {
  /* Values */
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [rememberMe, setRememberMe] = useState(true);
  /* Validation */
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  /* Text */
  const [emailHelpText, setEmailHelpText] = useState();
  const [passwordHelpText, setPasswordHelpText] = useState();
  /* Navigation */
  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const trainer = urlParams.get("trainer");
  const urlPrefixToSignUpWith = urlParams.get("urlPrefix");
  const productIdToSignUpWith = urlParams.get("productId");

  const handleKeyPress = (event) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
      handleSubmit(event)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    await loginUser({
      email,
      password,
      urlPrefixToSignUpWith,
      productIdToSignUpWith,
    },
      trainer
    ).then((response) => {
      if (response.type && response.message) {
        if (response.type === "email") {
          setValidEmail(false);
          setEmailHelpText(response.message);
        } else if (response.type === "password") {
          setPasswordHelpText(response.message);
        }
        return;
      }
      if (response.code === 401) {
        setPasswordHelpText(response.message);
        return;
      }
      if (response.code === 200) {
        const access_token = response.token.access.token;
        const refresh_token = response.token.refresh.token;
        const access_token_expires = response.token.access.expires;
        const refresh_token_expires = response.token.refresh.expires;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("access_token_expires", access_token_expires);
        localStorage.setItem("refresh_token_expires", refresh_token_expires);
        if (trainer) {
          navigate("/authenticate?trainerId="+trainer);
        } else {
          navigate(`/authenticate`);
        }
      }
    })
    .catch((err)=> {
      console.log(err);
    });
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to Sign In
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                placeholder="john@example.com"
                InputLabelProps={{ shrink: true }}
                error={!validEmail}
                helperText={emailHelpText}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (email && validator.isEmail(email)) {
                   
                      setValidEmail(true);
                      setEmailHelpText("");
                    
                  } else if (email) {
                    if (validEmail) {
                      setValidEmail(false);
                    }
                  }
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                placeholder="************"
                InputLabelProps={{ shrink: true }}
                error={!validPassword}
                helperText={passwordHelpText}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (e.target.value.length > 8) {
                    setValidPassword(true);
                    setPasswordHelpText("");
                  } else {
                    setValidPassword(false);
                  }
                }}
              />
            </MDBox>
            <MDTypography
              component={Link}
              to="/forgot-password"
              variant="button"
              color="info"
              fontWeight="small"
              textGradient
            >
              Forgot Password?
            </MDTypography>
            {/*
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
              */}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                { urlPrefixToSignUpWith && productIdToSignUpWith &&
                    <MDTypography
                    component={Link}
                    to={`/sign-up?urlPrefix=${urlPrefixToSignUpWith}&productId=${productIdToSignUpWith}`}
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign Up
                  </MDTypography>
                }
                { (urlPrefixToSignUpWith == null || productIdToSignUpWith == null) &&
                  <MDTypography
                  component={Link}
                  to="/free-trial"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Free Trial
                </MDTypography>
                }
                
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
