import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";

async function editExercise(exerciseInfo) {
    if (exerciseInfo.name === undefined || exerciseInfo.name.trim().length === 0) {
      const message = "please enter an exercise name.";
      return { type: ["name"], message: [message] };
    } else if (exerciseInfo.name.trim().length > 32) {
      const message = "exercise name cannot exceed 32 characters.";
      return { type: ["name"], message: [message] };
    }
    if (exerciseInfo.muscleGroup === undefined || exerciseInfo.muscleGroup.trim().length === 0) {
      const message = "please enter a muscle group.";
      return { type: ["muscleGroup"], message: [message] };
    } else if (exerciseInfo.muscleGroup.trim().length > 32) {
      const message = "muscle group cannot exceed 32 characters.";
      return { type: ["muscleGroup"], message: [message] };
    }
    if (exerciseInfo.accessory === undefined || exerciseInfo.accessory.trim().length === 0) {
      const message = "please enter an accessory.";
      return { type: ["accessory"], message: [message] };
    }
    if (exerciseInfo.videoLink !== null) {
      if (!isValidHttpUrl(exerciseInfo.videoLink)){
        const message = "URL must be valid. Ex) https://youtube.com/embed/abcdefghijk";
        return { type: ["videoLink"], message: [message] }
      } else if (exerciseInfo.videoLink !== undefined && exerciseInfo.videoLink.length > 2048) {
        const message = "url cannot be more than 2048 characters.";
        return { type: ["videoLink"], message: [message] }
      }
    } else {
      exerciseInfo.videoLink = "";
    }
    if (exerciseInfo.description === undefined || exerciseInfo.description.trim().length === 0) {
      const message = "please enter an exercise description.";
      return { type: ["description"], message: [message] };
    } else if (exerciseInfo.description.trim().length > 256) {
      const message = "exercise description is limited to 256 characters or less.";
      return { type: ["description"], message: [message] };
    }
    const accessToken = localStorage.getItem("access_token");
    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/v1/trainer/exercise/${exerciseInfo.exerciseId}`, {
      name: exerciseInfo.name,
      muscleGroup: exerciseInfo.muscleGroup,
      accessory: exerciseInfo.accessory,
      videoLink: exerciseInfo.videoLink,
      description: exerciseInfo.description,
      refreshToken: exerciseInfo.refreshToken,
    },{
      headers: {
          Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
}

function isValidHttpUrl(string) {
  let url;
  if (string == undefined || string == "")
    return true;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  if (string.slice(0,26) === "https://youtube.com/embed/")
    return url.protocol === "https:";
  else
    return false;
}

function EditExercise() {
  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToExercises = () => navigate("/dashboards/trainer/exercises");
  const navigateToCreateExercise = () => navigate("/dashboards/trainer/exercise-create");
  /* Values */
  const [name, setName] = useState();
  const [muscleGroup, setMuscleGroup] = useState();
  const [accessory, setAccessory] = useState("bodyweight");
  const [videoLink, setVideoLink] = useState();
  const [description, setDescription] = useState();
  const [trainerId, setTrainerId] = useState();
  /* Validation */
  const [validName, setValidName] = useState(true);
  const [validMuscleGroup, setValidMuscleGroup] = useState(true);
  const [validAccessory, setValidAccessory] = useState(true);
  const [validVideoLink, setValidVideoLink] = useState(true);
  const [validDescription, setValidDescription] = useState(true);
  /* Text */
  const [nameHelpText, setNameHelpText] = useState();
  const [muscleGroupHelpText, setMuscleGroupHelpText] = useState();
  const [accessoryHelpText, setAccessoryHelpText] = useState();
  const [videoLinkHelpText, setVideoLinkHelpText] = useState();
  const [descriptionHelpText, setDescriptionHelpText] = useState();
  
  const params = useParams();
  var exerciseId = params.exerciseId;

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await setExercise();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/dashboards/trainer/exercise/${exerciseId}`]);

  async function setExercise() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/exercise/${exerciseId}`, {
        refreshToken: refreshToken,
    }, {
      headers: {
        "Authorization": "Bearer " + accessToken,
      }
    })
    .then((response) => {
      setName(response.data.name);
      setMuscleGroup(response.data.muscleGroup);
      setAccessory(response.data.accessory);
      setVideoLink(response.data.videoLink);
      setDescription(response.data.description);
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const refreshToken = localStorage.getItem("refresh_token");
    const response = await editExercise({
      exerciseId,
      name,
      muscleGroup,
      accessory,
      videoLink,
      description,
      refreshToken,
    });
    if (response.type && response.message) {
      for (let i = 0; i < response.type.length; i += 1) {
        if (response.type.length === response.message.length) {
          switch (response.type[i]) {
            case "name":
              setValidName(false);
              setNameHelpText(response.message[i]);
              return;
            case "muscleGroup":
              setValidMuscleGroup(false);
              setMuscleGroupHelpText(response.message[i]);
              return;
            case "accessory":
              setValidAccessory(false);
              setAccessoryHelpText(response.message[i]);
              return;
            case "videoLink":
              setValidVideoLink(false);
              setVideoLinkHelpText(response.message[i]);
              return;
            case "description":
              setValidDescription(false);
              setDescriptionHelpText(response.message[i]);
              return;
            default:
              break;
          }
        }
      }
    }
    if (response.status && response.status === 200) {
      navigateBack();
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <Grid mt={6}>
            <MDBox pb={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Edit Exercise
              </MDTypography>
              <MDTypography variant="button" color="text">
                Create, view, or edit an exercise to use in your workouts
              </MDTypography>
            </MDBox>
            <Grid pb={1}>
              <MDButton
                  variant="gradient"
                  color="secondary"
                  size="small"
                  onClick={navigateBack}
                  pr={3}
                  >
                  Back
              </MDButton>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <MDBox pt={4} pb={3} px={3}>
                             <MDBox component="form" role="form" onSubmit={handleSubmit}>
                                <MDBox mb={2}>
                                  <MDInput
                                    type="text"
                                    label="Name"
                                    variant="standard"
                                    fullWidth
                                    value={name}
                                    error={!validName}
                                    helperText={nameHelpText}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                      setName(e.target.value);
                                      if (e.target.value) {
                                        if (e.target.value.length > 32) {
                                          // if not valid name
                                          if (validName) {
                                            setValidName(false);
                                            setNameHelpText("Please limit your name to 32 characters or less.");
                                          }
                                        } else {
                                          setValidName(true);
                                          setNameHelpText("");
                                        }
                                      } else {
                                        setValidName(false);
                                      }
                                    }}
                                  />
                                </MDBox>
                                <MDBox mb={2}>
                                  <MDInput
                                    type="muscle-group"
                                    label="Muscle Group"
                                    variant="standard"
                                    fullWidth
                                    value={muscleGroup}
                                    error={!validMuscleGroup}
                                    helperText={muscleGroupHelpText}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                      setMuscleGroup(e.target.value);
                                      if (e.target.value) {
                                        if (e.target.value.length > 32) {
                                          if (validMuscleGroup) {
                                            setValidMuscleGroup(false);
                                            setMuscleGroupHelpText("Please limit the muscle group to 32 characters or less.");
                                          }
                                        } else {
                                          setValidMuscleGroup(true);
                                          setMuscleGroupHelpText("");
                                        }
                                      } else {
                                        setValidMuscleGroup(false);
                                      }
                                    }}
                                  />
                                </MDBox>
                                <MDBox mb={2}>
                                  <InputLabel>Accessory</InputLabel>
                                  <Select
                                    value={accessory}
                                    error={!validAccessory}
                                    helperText={accessoryHelpText}
                                    label="Accessory"
                                    onChange={(e) => {
                                      setAccessory(e.target.value);
                                      if (e.target.value) {
                                        setValidAccessory(true);
                                        setAccessoryHelpText("");
                                      } else {
                                        setValidAccessory(false);
                                      }
                                    }}
                                  >
                                    <MenuItem value="bands">Bands</MenuItem>
                                    <MenuItem value="barbell">Barbell</MenuItem>
                                    <MenuItem value="bodyweight">Bodyweight</MenuItem>
                                    <MenuItem value="dumbells">Dumbells</MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                  </Select>
                                </MDBox>
                                <MDBox mb={2}>
                                  <MDInput
                                    type="video-link"
                                    label="Video Link (optional)"
                                    variant="standard"
                                    fullWidth
                                    value={videoLink}
                                    error={!validVideoLink}
                                    helperText={videoLinkHelpText}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                      setVideoLink(e.target.value);
                                      if (e.target.value) {
                                        if (!isValidHttpUrl(e.target.value)) {
                                          setValidVideoLink(false);
                                          setVideoLinkHelpText("URL must be valid. Ex) https://youtube.com/embed/abcdefghijk");
                                        } else if (e.target.value !== undefined && e.target.value.length > 2048) {
                                          setValidVideoLink(false);
                                          setVideoLinkHelpText("url cannot be more than 2048 characters.");
                                        } else {
                                          setValidVideoLink(true);
                                          setVideoLinkHelpText("");
                                        }
                                      } else {
                                        setValidVideoLink(true);
                                        setVideoLinkHelpText("");
                                      }
                                    }}
                                  />
                                </MDBox>
                                <MDBox mb={2}>
                                  <MDInput
                                    type="workout-description"
                                    label="Workout Description"
                                    variant="standard"
                                    fullWidth
                                    value={description}
                                    error={!validDescription}
                                    helperText={descriptionHelpText}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                      setDescription(e.target.value);
                                      if (e.target.value) {
                                        if (e.target.value.length > 256) {
                                          if (validDescription) {
                                            setValidDescription(false);
                                            setDescriptionHelpText("Please limit the description to 256 characters or less.");
                                          }
                                        } else {
                                          setValidDescription(true);
                                          setDescriptionHelpText("");
                                        }
                                      } else {
                                        setValidDescription(false);
                                      }
                                    }}
                                  />
                                </MDBox>
                                <MDBox mt={4} mb={1}>
                                  <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                                    Save
                                  </MDButton>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default EditExercise;
