import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import MDBox from "components/MDBox";
import MKBox from "components/MKBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import axios from "axios";
import { Navigate } from "react-router-dom";
// @mui icons
import CloseIcon from "@mui/icons-material/Close";

function ConfirmEmail() {
    const [user, setUser] = useState();
    const [mainText, setMainText] = useState("Please confirm your email before continuing to the web application.");
    const [buttonVisible, setButtonVisible] = useState(true);
    const navigate = useNavigate();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    const [snackbarText, setSnackbarText] = useState();
    const [show, setShow] = useState(false);
    const [snackbarColor, setSnackbarColor] = useState();
    const toggleSnackbar = () => setShow(!show);
    const hideSnackbar = () => setShow(false);
    
    const errorStyle = ({
        palette: { error },
        borders: { borderRadius },
        typography: { size },
        boxShadows: { lg },
      }) => ({
        "& .MuiPaper-root": {
          backgroundColor: error.main,
          borderRadius: borderRadius.lg,
          fontSize: size.sm,
          fontWeight: 400,
          boxShadow: lg,
          px: 2,
          py: 0.5,
        },
      });
    
    const successStyle = ({
    palette: { success },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
    }) => ({
    "& .MuiPaper-root": {
        backgroundColor: success.main,
        borderRadius: borderRadius.lg,
        fontSize: size.sm,
        fontWeight: 400,
        boxShadow: lg,
        px: 2,
        py: 0.5,
    },
    });
    
    const toastTemplate = (
    <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
        {snackbarText}
        <CloseIcon
        fontSize="medium"
        sx={{ ml: 4, mr: -1, cursor: "pointer" }}
        onClick={toggleSnackbar}
        />
    </MKBox>
    );

    useEffect(() => {
        const abortController = new AbortController();
        const fetchData = async() => await checkIfUserIsEnabled();
        fetchData();
        return () => {
        abortController.abort();
        };
    }, [`${process.env.REACT_APP_API_URL}/v1/users/getUserByToken`]);

    async function checkIfUserIsEnabled() {
        if (refreshToken === undefined || accessToken === undefined) {
            setUser(null);
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}/v1/users/getUserByToken`, {
                refreshToken,
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((result) => {
                setUser(result.data);
                if (result.isEmailVerified) {
                    navigate("/account/settings");
                }
                return Promise.resolve()
            })
            .catch((error) => { return Promise.reject() });
        }
    };

    const handleSubmit = async (e) => {
        // Resend the verification email
        var response;
        try {
            response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/send-verification-email`, {
                user: user,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
        } catch (err) {
            response = err.response;
        }
        if (response.status === 204) {
            setSnackbarColor("success");
            setSnackbarText("Email confirmation link sent successfully.");
            setShow(true);
        } else if (response.status === 429) {
            setSnackbarColor("error");
            setSnackbarText("There was a problem sending the email confirmation link. Please try again later.");
            setShow(true);
        }
    }

    return (
        <DashboardLayout>
        <DashboardNavbar />
        <MKBox component="section">
        <Container>
            {
                snackbarColor === "success" && 
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={show}
                    autoHideDuration={3000}
                    onClose={hideSnackbar}
                    message={toastTemplate}
                    action={toggleSnackbar}
                    sx={successStyle}
                />
            }
            {
                snackbarColor === "error" && 
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={show}
                    autoHideDuration={3000}
                    onClose={hideSnackbar}
                    message={toastTemplate}
                    action={toggleSnackbar}
                    sx={errorStyle}
                />
            }
            
        </Container>
      </MKBox>
        <MDBox textAlign="center" mt={13} mb={72}>
            <MDTypography color="dark" variant="h2" mb={3}>{mainText}</MDTypography>
            { buttonVisible &&
            <MDButton color="dark" variant="gradient" onClick={handleSubmit}>Send Again</MDButton>
            }
        </MDBox>
        <Footer />
        </DashboardLayout>
    );
}

export default ConfirmEmail;
