import axios from "axios";

async function SignOut() {
    const refreshToken = localStorage.getItem("refresh_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("access_token_expires");
    localStorage.removeItem("refresh_token_expires");
    localStorage.removeItem("workout");
    localStorage.removeItem("program");
    localStorage.removeItem("homedata");
    if (refreshToken != null) {
        await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/logout`, { refreshToken });
    }
    document.location.href = `${process.env.REACT_APP_FRONT_END_URL}/sign-in`;
}

export default SignOut;
