// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

import { useMaterialUIController } from "context";

function FeaturesThree() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MKBox component="section" pt={12} pb={8}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          mx="auto"
          textAlign="center"
          pb={16}
        >
          <MKTypography variant="h2" mb={1} color={darkMode ? "white" : "dark"}>
            Fit for your personal training business
          </MKTypography>
          <MKTypography variant="body1" color="text">
            We&apos;re constantly trying to improve our service. That's why we'll take your feedback seriously and create a solution that works for you. {" "}
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              color="primary"
              icon="fitness_center"
              title="Add Value"
              description="Improve your services by providing custom programs made accessible via an online dashboard."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              icon="request_page"
              title="Sell Online"
              description="Create a landing page using our template and start selling programs directly to your audience."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              color="warning"
              icon="update"
              title="Feature Revisions"
              description="Get unlimited updates, addressing feedback that we get directly from trainers like you."
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FeaturesThree;
