
import { useState } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MKBox from "components/MKBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";
// Settings page components
import FormField from "layouts/account/components/FormField";
import axios from "axios";
// @mui icons
import CloseIcon from "@mui/icons-material/Close";
// @mui material components
import Snackbar from "@mui/material/Snackbar";

async function editGoal(goals) {
    if (goals === undefined || goals.trim().length === 0) {
      const message = "please update your personal fitness goals before saving.";
      return { type: ["goals"], message: [message] };
    } else if (goals.length > 256) {
      const message = "please limit the goals description to 256 characters.";
      return { type: ["goals"], message: [message] };
    }
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainee/goals`, {
        refreshToken,
        goals,
    }, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        }
    });
    return response;
}
  

function GoalUpload({ user }) {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
    const hideSnackbar = () => setShowSnackbar(false);
    const [snackbarColor, setSnackbarColor] = useState();
    const [snackbarText, setSnackbarText] = useState();

    const [goals, setGoals] = useState();
    const [validGoals, setValidGoals] = useState(true);
    const [goalsHelpText, setGoalsHelpText] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await editGoal(goals)
            .then((response) => {
                if (response.type && response.message) {
                    for (let i = 0; i < response.type.length; i += 1) {
                        if (response.type.length === response.message.length) {
                            switch (response.type[i]) {
                                case "goals":
                                    setValidGoals(false);
                                    setGoalsHelpText(response.message[i]);
                                    return;
                                default:
                                    break;
                            }
                        }
                    }
                }
                if (response.status === 200) {
                    setSnackbarColor("success");
                    setSnackbarText("Your goals were saved successfully.");
                    setShowSnackbar(true);
                }
                
            }).catch((err) => {
                console.log(err);
                setSnackbarColor("error");
                setSnackbarText("There was an error saving your goals.");
                setShowSnackbar(true);
            }
        );
    }

    const errorStyle = ({
        palette: { error },
        borders: { borderRadius },
        typography: { size },
        boxShadows: { lg },
      }) => ({
        "& .MuiPaper-root": {
          backgroundColor: error.main,
          borderRadius: borderRadius.lg,
          fontSize: size.sm,
          fontWeight: 400,
          boxShadow: lg,
          px: 2,
          py: 0.5,
        },
      });
    
    const successStyle = ({
        palette: { success },
        borders: { borderRadius },
        typography: { size },
        boxShadows: { lg },
        }) => ({
        "& .MuiPaper-root": {
            backgroundColor: success.main,
            borderRadius: borderRadius.lg,
            fontSize: size.sm,
            fontWeight: 400,
            boxShadow: lg,
            px: 2,
            py: 0.5,
        },
    });
    
    const toastTemplate = (
        <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
            {snackbarText}
            <CloseIcon
            fontSize="medium"
            sx={{ ml: 4, mr: -1, cursor: "pointer" }}
            onClick={toggleSnackbar}
            />
        </MKBox>
    );

    return (
        <MDBox mx={3}>
            {
                snackbarColor === "success" && 
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={showSnackbar}
                    autoHideDuration={3000}
                    onClose={hideSnackbar}
                    message={toastTemplate}
                    action={toggleSnackbar}
                    sx={successStyle}
                />
            }
            {
                snackbarColor === "error" && 
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={showSnackbar}
                    autoHideDuration={3000}
                    onClose={hideSnackbar}
                    message={toastTemplate}
                    action={toggleSnackbar}
                    sx={errorStyle}
                />
            }
            { user !== undefined &&
                
                <FormField 
                    id="standard-multiline-flexible2"
                    label="What are your goals?"
                    //multiline
                    //minRows={6}
                    //maxRows={6}
                    variant="standard"
                    mb={3}
                    fullWidth
                    defaultValue={user?.goals ? user.goals : ""}
                    error={!validGoals}
                    helperText={goalsHelpText}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                        setGoals(e.target.value);
                        if (e.target.value == "") {
                            setValidGoals(false);
                            setGoalsHelpText("please update your personal fitness goals before saving.");
                        } else if (e.target.value.length > 256) {
                            setValidGoals(false);
                            setGoalsHelpText("please limit the goals description to 256 characters.");
                        } else {
                            setValidGoals(true);
                            setGoalsHelpText("");
                        }
                    }}
                />
            }
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
              >
                <MDBox ml="auto" mt={2} mb={2}>
                  <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    Update Goals
                  </MDButton>
                </MDBox>
            </MDBox>
        </MDBox>
    )
}

export default GoalUpload;
