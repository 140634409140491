import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";
// @mui material components
import Grid from "@mui/material/Grid";
// Pricing page components
import Header from "layouts/main/components/Header";
import ContactUsOne from "layouts/main/components/ContactUsOne";
import FeaturesOne from "layouts/main/components/FeaturesOne";
import FeaturesTwo from "layouts/main/components/FeaturesTwo";
import FeaturesThree from "layouts/main/components/FeaturesThree";
import HeaderThree from "layouts/main/components/HeaderThree";
import StatsOne from "layouts/main/components/StatsOne";
import TestimonialsOne from "layouts/main/components/TestimonialsOne";
import Footer from "layouts/main/components/Footer";
import FooterThree from "layouts/main/components/FooterThree";
import TrainerFooter from "layouts/main/components/TrainerFooter";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
import peterlarsonfitness from "assets/images/products/peterlarsonfitness.jpg";

// rellax
import Rellax from "rellax";

// typed-js
import * as Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";


import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredPriceCard from "examples/Cards/BlogCards/CenteredPriceCard";
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";
// Routes
import routes from "routes";

// Images
//import bgImage from "assets/images/bg-coworking.jpeg";
import bgImage from "assets/images/row-of-dumbbells.jpg";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import team1 from "assets/images/team-3.jpg";
import team2 from "assets/images/marie.jpg";
import team3 from "assets/images/team-2.jpg";
import { ConstructionOutlined } from "@mui/icons-material";

const getUrlPrefix = () => {
    const params = useParams();
    let url = window.location.href;
    let urlArray = url.split("/");
    let urlPrefix = "";
    let frontEndUrl = process.env.REACT_APP_FRONT_END_URL;
    let urlSplit = frontEndUrl.split("/");

    // ${process.env.REACT_APP_API_URL}/pages/<urlPrefix>
    if (urlArray.length > 2 && urlArray[2] == `${urlSplit[2]}`) {
        urlPrefix =  params.urlPrefix;
    // https://<urlPrefix>.fittoscale.io
    } else if (urlArray.length > 2) {
        let split = urlArray[2].split(".");
        if (split.length > 1 && split[1] == "fittoscale") {
            urlPrefix = split[0];
        }
    }

    return urlPrefix;
}

function Page() {
  const [headline, setHeadline] = useState();
  const [headline2, setHeadline2] = useState();
  const [feature1_headline, setFeature1_headline] = useState();
  const [feature1_headline2, setFeature1_headline2] = useState();
  const [feature2_headline, setFeature2_headline] = useState();
  const [feature2_headline2, setFeature2_headline2] = useState();
  const [feature3_headline, setFeature3_headline] = useState();
  const [feature3_headline2, setFeature3_headline2] = useState();
  const [guarantee_headline, setGuarantee_headline] = useState();
  const [guarantee_headline2, setGuarantee_headline2] = useState();
  const [businessName, setBusinessName] = useState();
  const [product1_id, setProduct1_id] = useState();
  const [product1_name, setProduct1_name] = useState();
  const [product1_description, setProduct1_description] = useState();
  const [product1_price, setProduct1_price] = useState();
  const [product1_cta, setProduct1_cta] = useState();
  const [product1_link, setProduct1_link] = useState();
  //const [product1_image, setProduct1_image] = useState();
  const [product2_id, setProduct2_id] = useState();
  const [product2_name, setProduct2_name] = useState();
  const [product2_description, setProduct2_description] = useState();
  const [product2_price, setProduct2_price] = useState();
  const [product2_cta, setProduct2_cta] = useState();
  const [product2_link, setProduct2_link] = useState();
  //const [product2_image, setProduct2_image] = useState();
  const [product3_id, setProduct3_id] = useState();
  const [product3_name, setProduct3_name] = useState();
  const [product3_description, setProduct3_description] = useState();
  const [product3_price, setProduct3_price] = useState();
  const [product3_cta, setProduct3_cta] = useState();
  const [product3_link, setProduct3_link] = useState();
  //const [product3_image, setProduct3_image] = useState();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);
  const navigate = useNavigate();
  const urlPrefix = getUrlPrefix();
  const navigateFreeTrial = () => {
    navigate("/free-trial");
  }
  /* Navigation */
  const navigateToCreatePage = () => {
      navigate("/dashboards/trainer/page-create");
  }

  useEffect(() => {
    // set page data used within the template
    const abortController = new AbortController();
    const fetchData = async() => await setPage();
    fetchData().catch(console.error);
    // set the products
    const fetchData1 = async() => await setProducts();
    fetchData1().catch(console.error);
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/trainer/page/get`, `${process.env.REACT_APP_API_URL}/v1/trainer/products/${urlPrefix}`]);

  async function setPage() {
    await axios.get(`${process.env.REACT_APP_API_URL}/v1/trainer/page/${urlPrefix}`)
    .then((response) => {
      let pageData = response.data;
      setHeadline(pageData.headline);
      setHeadline2(pageData.headline2);
      setFeature1_headline(pageData.feature1_headline);
      setFeature1_headline2(pageData.feature1_headline2);
      setFeature2_headline(pageData.feature2_headline);
      setFeature2_headline2(pageData.feature2_headline2);
      setFeature3_headline(pageData.feature3_headline);
      setFeature3_headline2(pageData.feature3_headline2);
      setGuarantee_headline(pageData.guarantee_headline);
      setGuarantee_headline2(pageData.guarantee_headline2);
      setBusinessName(pageData.businessName);
      //setProduct1(pageData.product1);
      //setProduct2(pageData.product2);
      //setProduct3(pageData.product3);
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
  }

  async function setProducts() {
    await axios.get(`${process.env.REACT_APP_API_URL}/v1/trainer/products/${urlPrefix}`)
    .then((response) => {
      let productData = response.data;
      for (var i=0; i<productData.length; i++) {
        if (i == 0) {
            setProduct1_id(productData[i].productId);
            setProduct1_name(productData[i].name);
            setProduct1_description(productData[i].description);
            setProduct1_price(productData[i].price / 100.0);
            setProduct1_cta(productData[i].cta);
            //setProduct1_image(productData[i].image);
            setProduct1_link(productData[i].link);
        } else if (i == 1) {
            setProduct2_id(productData[i].productId);
            setProduct2_name(productData[i].name);
            setProduct2_description(productData[i].description);
            setProduct2_price(productData[i].price / 100.0);
            setProduct2_cta(productData[i].cta);
            //setProduct2_image(productData[i].image);
            setProduct2_link(productData[i].link);
        } else if (i == 2) {
            setProduct3_id(productData[i].productId);
            setProduct3_name(productData[i].name);
            setProduct3_description(productData[i].description);
            setProduct3_price(productData[i].price / 100.0);
            setProduct3_cta(productData[i].cta);
            //setProduct3_image(productData[i].image);
            setProduct3_link(productData[i].link);
        }
      }
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject();
    });
  }

  return (
    <PageLayout>
        { urlPrefix != "undefined" &&
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 12,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={12}>
            <Grid
                container
                item
                xs={12}
                lg={6}
                justifyContent="center"
                mx="auto"
                textAlign="center"
                pb={0}
            >
                <MKTypography variant="h2" mb={1} color={darkMode ? "white" : "dark"}>
                    { headline }
                </MKTypography>
            </Grid>
            <Grid
                container
                item
                xs={12}
                lg={6}
                justifyContent="center"
                mx="auto"
                textAlign="center"
                pb={18}
            >
                <MKTypography variant="body1" color="text">
                    { headline2 }
                </MKTypography>
            </Grid>

            { product1_name != undefined && product2_name == undefined && product3_name == undefined && urlPrefix == "peterlarsonfitness" &&
            <Grid>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4} />
                        <Grid item xs={12} md={6} lg={4}>
                            <CenteredPriceCard
                                image={peterlarsonfitness}
                                title={product1_name}
                                description={product1_description}
                                price={{ currency: "$", value: product1_price, type: "mo" }}
                                action={{
                                    type: "internal",
                                    route: `/sign-up?urlPrefix=${urlPrefix}&productId=${product1_id}`,
                                    color: "info",
                                    label: product1_cta,

                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} />
                    </Grid>
                </Container>
            </Grid>
            }

            { product1_name != undefined && product2_name == undefined && product3_name == undefined && urlPrefix != "peterlarsonfitness" &&
            <Grid>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4} />
                        <Grid item xs={12} md={6} lg={4}>
                            <CenteredPriceCard
                                title={product1_name}
                                description={product1_description}
                                price={{ currency: "$", value: product1_price, type: "mo" }}
                                action={{
                                    type: "internal",
                                    route: `/sign-up?urlPrefix=${urlPrefix}&productId=${product1_id}`,
                                    color: "info",
                                    label: product1_cta,

                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} />
                    </Grid>
                </Container>
            </Grid>
            }

            { product1_name != undefined && product2_name  != undefined && product3_name  == undefined &&
             <Grid>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={2} />
                        <Grid item xs={12} md={6} lg={4}>
                            <CenteredPriceCard
                                image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                                title={product1_name}
                                description={product1_description}
                                price={{ currency: "$", value: product1_price, type: "mo" }}
                                action={{
                                    type: "internal",
                                    route: `/sign-up?urlPrefix=${urlPrefix}&productId=${product1_id}`, // `/create-checkout-session/${urlPrefix}/${product1_id}`,
                                    color: "info",
                                    label: product1_cta,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <CenteredPriceCard
                                image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                                title={product2_name}
                                description={product2_description}
                                price={{ currency: "$", value: product2_price, type: "mo" }}
                                action={{
                                    type: "internal",
                                    route: `/sign-up?urlPrefix=${urlPrefix}&productId=${product2_id}`,//`/create-checkout-session/${urlPrefix}/${product2_id}`,
                                    color: "info",
                                    label: product2_cta,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2} />
                    </Grid>
                </Container>
            </Grid>
            }
            { product1_name  != undefined && product2_name  != undefined && product3_name  != undefined &&
            <Grid>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <CenteredPriceCard
                                image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                                title={product1_name}
                                description={product1_description}
                                price={{ currency: "$", value: product1_price, type: "mo" }}
                                action={{
                                    type: "internal",
                                    route: `/sign-up?urlPrefix=${urlPrefix}&productId=${product1_id}`,
                                    color: "info",
                                    label: product1_cta,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <CenteredPriceCard
                                image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                                title={product2_name}
                                description={product2_description}
                                price={{ currency: "$", value: product2_price, type: "mo" }}
                                action={{
                                    type: "internal",
                                    route: `/sign-up?urlPrefix=${urlPrefix}&productId=${product2_id}`, // `/create-checkout-session/${urlPrefix}/${product2_id}`,
                                    color: "info",
                                    label: product2_cta,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <CenteredPriceCard
                                image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                                title={product3_name}
                                description={product3_description}
                                price={{ currency: "$", value: product3_price, type: "mo" }}
                                action={{
                                    type: "internal",
                                    route: `/sign-up?urlPrefix=${urlPrefix}&productId=${product3_id}`, // `/create-checkout-session/${urlPrefix}/${product3_id}`,
                                    color: "info",
                                    label: product3_cta,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            }

            <Grid pt={16}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <SimpleInfoCard
                                color="primary"
                                icon="fitness_center"
                                title={feature1_headline}//"Custom Programs"
                                description={feature1_headline2}//"I'll create a custom program tailored to your goals."
                                direction="center"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <SimpleInfoCard
                                icon="flare"
                                title={feature2_headline}//"Personalized Goals"
                                description={feature2_headline2}//"I'll work with you to set goals and hold you accountable."
                                direction="center"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <SimpleInfoCard
                                color="warning"
                                icon="update"
                                title={feature3_headline}//"Weekly Feedback"
                                description={feature3_headline2}//"I'll assess your progress and guide you through your fitness journey."
                                direction="center"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </MKBox>
        {/*
        <Divider sx={{ my: 6 }} />*/}
        <MKBox component="section" pb={2}>
           {/*
        <Container>
            <Grid container item xs={12} lg={6} justifyContent="center" mx="auto" textAlign="center">
            <MKTypography variant="h2" mb={2} color={darkMode ? "white" : "dark"}>
                { guarantee_headline }
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
                { guarantee_headline2 }
                That&apos;s the main thing people are controlled by! Thoughts - their perception of
                themselves!{" "}
            </MKTypography>
            </Grid>
            <Grid container spacing={3} my={8}>
            <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
                <DefaultReviewCard
                image={team1}
                name="Mathew Glock"
                date="1 day ago"
                review="If you have the opportunity to play this game of life you need to appreciate every moment."
                rating={4}
                />
            </Grid>
            <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
                <DefaultReviewCard
                color="info"
                image={team2}
                name="Mathew Glock"
                date="28 February"
                review="If you have the opportunity to play this game of life you need to appreciate every moment."
                rating={3}
                />
            </Grid>
            <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
                <DefaultReviewCard
                image={team3}
                name="Mathew Glock"
                date="3 days ago"
                review="If you have the opportunity to play this game of life you need to appreciate every moment."
                rating={5}
                />
            </Grid>
            </Grid>
        </Container>
        */}
        <MKBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }} py={12}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={8}>
                        <Card  sx={{ boxShadow: 5, p: 2 }}>
                            <Grid
                                container
                                item
                                xs={12}
                                md={6}
                                pt={8}
                                justifyContent="center"
                                sx={{ mx: "auto", mt: 8, textAlign: "center" }}
                            >
                                <MKTypography variant="h2" mb={1} color={darkMode ? "white" : "dark"}>
                                { guarantee_headline }
                                </MKTypography>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                md={6}
                                pb={8}
                                justifyContent="center"
                                sx={{ mx: "auto", mb: 8, textAlign: "center" }}
                            >
                                <MKTypography variant="body1" color="text">
                                { guarantee_headline2 }
                                </MKTypography>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </MKBox>
        </MKBox>

      </Card>
      }
      { urlPrefix == "undefined" &&
       <Card
            sx={{
                p: 2,
                mx: { xs: 2, lg: 3 },
                mt: 12,
                mb: 4,
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
        >
            <MKBox component="section" py={12}>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justifyContent="center"
                    mx="auto"
                    textAlign="center"
                    pt={6}
                >
                    <MKTypography variant="h2" mb={1} color={darkMode ? "white" : "dark"}>
                        You haven't created a landing page yet.
                    </MKTypography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justifyContent="center"
                    mx="auto"
                    textAlign="center"
                    pb={6}
                >
                    <MKTypography variant="body1" color="text">
                        Finish onboarding with Stripe before creating a landing page.
                    </MKTypography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justifyContent="center"
                    mx="auto"
                    textAlign="center"
                    pb={18}
                >
                    <MKButton
                        variant="gradient"
                        color="info"
                        size="small"
                        onClick={navigateToCreatePage}
                    >
                        Back to Dashboard
                    </MKButton>
                </Grid>
            </MKBox>
        </Card>
        }
        { urlPrefix == "undefined" && 
            <FooterThree twitterLink="https://twitter.com/fit_to_scale" instagramLink="https://www.instagram.com/fittoscale.io/" linkedInLink="https://www.linkedin.com/company/fit-to-scale/" />
        }
        { urlPrefix != "undefined" &&
            <TrainerFooter name={businessName} twitterLink="https://twitter.com/fit_to_scale" instagramLink="https://www.instagram.com/fittoscale.io/" linkedInLink="https://www.linkedin.com/company/fit-to-scale/" />
        }
      </PageLayout>
  );
}

export default Page;
