/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { useMaterialUIController } from "context";

function AboutUs() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const data = [
    {
      icon: "fitness_center",
      title: "Customized Programs",
      items: ["Assess client goals", "Create custom programs"],
      color: "info",
    },
    {
      icon: "credit_card",
      title: "Accept Payments",
      items: ["Sell custom programs", "Collect recurring payments", "Multiple payment methods"],
      color: "info",
    },
    {
      icon: "group",
      title: "Marketing Resources",
      //items: ["Enhance your marketing strategy", "Grow your trainees"],
      items: ["Landing page"],//, "Trainer network"],
      color: "info",
    },
    {
      icon: "analytics",
      title: "Workout Analytics",
      //items: ["Collect workout data", "Measure trainee goals & progress"],
      items: ["(Coming Soon)"],
      color: "dark",
    },
    {
      icon: "message",
      title: "Messaging & Automation",
      //items: ["Talk directly with trainees ", "Automated client interaction"],
      items: ["(Coming Soon)"],
      color: "dark",
    },
    {
      icon: "schedule",
      title: "Session Scheduling",
      //items: ["Schedule one on one training", "Training notifications"],
      items: ["(Coming Soon)"],
      color: "dark",
    },
  ];

  return (
    <MKBox component="section" pt={12} px={1}>
      <Container>
        <Grid container item xs={12} lg={8}>
          <MKTypography variant="h3" color={darkMode ? "white" : "dark"}>Elevate Your Training Business</MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="text">
            Fit to Scale is enhancing powerful sets of features, enabling trainers to create custom workout programs for trainees and market their services to clients. We will soon offer session scheduling, client progress tracking, messaging and automation tooling along with other marketing resources to grow your business.
          </MKTypography>
        </Grid>
        <Grid container sx={{ mt: 6 }}>
          {data.map(({ icon, title, items, color }) => (
            <Grid key={icon} item xs={12} md={4}>
              <MKBox py={2} pr={2}>
                <MKTypography variant="h3" color={darkMode ? (color === "info" ? "info" : "light") : color}>
                  <Icon>{icon}</Icon>
                </MKTypography>
                <MKTypography variant="h5" mt={2} mb={3} color={darkMode ? "white" : "dark"}>
                  {title}
                </MKTypography>
                {items.map((item) => (
                  <MKBox key={item} display="flex" lineHeight={1.25}>
                    <MKTypography variant="body1" color="info">
                      { item != "(Coming Soon)" && 
                      <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                      }
                      
                    </MKTypography>
                    <MKBox pl={2}>
                      <MKTypography variant="button" color="text" fontWeight="bold">
                        {item}
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AboutUs;
