/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import CloseIcon from "@mui/icons-material/Close";

// @mui material components
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import axios from "axios";
import { identifier } from "stylis";

import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Client({ user }) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = useParams();

  const [modalMessage, setModalMessage] = useState();
  const [modalButtonColor, setModalButtonColor] = useState("error");
  const [modalButtonText, setModalButtonText] = useState("delete");
  const [modalButtonFunction, setModalButtonFunction] = useState();
  const [clientToDelete, setClientToDelete] = useState();
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

  // const traineeId = urlParams.get("client");
  const traineeId = params.clientId;
  const [trainee, setTrainee] = useState();
  const [subscription, setSubscription] = useState();
  const [program, setProgram] = useState();
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToSelectProgram = () => {
    navigate(`/dashboards/trainer/client/${params.clientId}/assign-program`, {
        state: {
          traineeId: trainee.id,
        }
    });
  }
  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await getTrainee();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/trainer/client`]);

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await getProgram();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/program/trainee/${traineeId}`]);

  async function getTrainee() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/client`, {
        traineeId: traineeId,
        refreshToken: refreshToken,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
    })
    .then((response) => {
      setTrainee(response.data.trainee)
      setSubscription(response.data.subscription);
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
  }

  async function getProgram() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/program/trainee/${traineeId}`, {
        refreshToken: refreshToken,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
    })
    .then((response) => {
      if (response.data[0]) {
        setProgram(response.data[0].name);
      }
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
  }

  const pressDeleteClient = async () => {
    setModalMessage("This client and their associated data will also be deleted. Are you sure you want to delete this client?");
    setModalButtonColor("error");
    setModalButtonText("delete");
    toggleModal();
  }

  const deleteSubscription = async () => {
    const subscriptionId = subscription.subscriptionId;
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.delete(`${process.env.REACT_APP_API_URL}/v1/subscription/${subscriptionId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        refreshToken: refreshToken,
      }
    }).then((response) => {
      if (response.status == 200) {
        window.location.replace(`${process.env.REACT_APP_FRONT_END_URL}/dashboards/trainer`);
      }
    });
  }

  const deleteClient = () => {
    deleteSubscription();
  }


  if (trainee) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
      {/*}
        <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}></Grid>
      */}
        <MKBox component="section">
          <Container>
            <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
              <Slide direction="down" in={show} timeout={500}>
                <MKBox
                  position="relative"
                  width="500px"
                  display="flex"
                  flexDirection="column"
                  borderRadius="xl"
                  bgColor="white"
                  shadow="xl"
                >
                  <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                    <MKTypography variant="h5">Warning</MKTypography>
                    <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
                  </MKBox>
                  <Divider sx={{ my: 0 }} />
                  <MKBox p={2}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular">
                      {modalMessage}
                    </MKTypography>
                  </MKBox>
                  <Divider sx={{ my: 0 }} />
                  <MKBox display="flex" justifyContent="space-between" p={1.5}>
                    <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                      close
                    </MKButton>
                    <MKButton variant="gradient" color={modalButtonColor} onClick={deleteClient}>
                      {modalButtonText}
                    </MKButton>
                  </MKBox>
                </MKBox>
              </Slide>
            </Modal>
          </Container>
        </MKBox>
        <MDBox py={3}>
            <MDBox mb={3}>
                { subscription?.status != undefined && !(subscription?.status == "active" || subscription?.status == "trialing") &&
                <MDBox /*component="form" role="form" onSubmit={pressDeleteClient}*/>
                    <MDButton
                        type="submit"
                        variant="gradient"
                        color="error"
                        size="small"
                        onClick={pressDeleteClient}
                      >
                        delete
                    </MDButton>
                </MDBox>
                }
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Header user={trainee}>
                            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                                {trainee && 
                                  <ProfileInfoCard
                                    title="profile information"
                                    description={trainee.description}
                                    info={{
                                      fullName: trainee.name,
                                      mobile: trainee.phoneNumber,
                                      email: trainee.email,
                                      location: trainee.location,
                                    }}
                                    social={[
                                      {
                                        link: "https://www.facebook.com/CreativeTim/",
                                        icon: <FacebookIcon />,
                                        color: "facebook",
                                      },
                                      {
                                        link: "https://twitter.com/creativetim",
                                        icon: <TwitterIcon />,
                                        color: "twitter",
                                      },
                                      {
                                        link: "https://www.instagram.com/creativetimofficial/",
                                        icon: <InstagramIcon />,
                                        color: "instagram",
                                      },
                                    ]}
                                    action={{ route: "/account/settings#basic-info", tooltip: "Edit Profile" }}
                                    shadow={false}
                                  />
                                }
                            <Divider orientation="vertical" sx={{ mx: 0 }} />
                        </Header>
                    </Grid>
                    <Grid item xs={12} sm={6} mt={1} mb={3}>
                        <Card id="assign-program" mb={3}>
                            <MDBox p={2}>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <MDBox height="100%" mt={0.5} lineHeight={1}>
                                        <MDTypography variant="h5" fontWeight="medium">
                                            Program
                                        </MDTypography>
                                        <MDTypography variant="h6" color="text" fontWeight="small">
                                            { program != undefined && program }
                                            { program == undefined && "Client hasn't been assigned a program."}
                                        </MDTypography>
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                                        <MDBox
                                        display="flex"
                                        justifyContent={{ md: "flex-end" }}
                                        alignItems="center"
                                        lineHeight={1}
                                        >
                                            <MDBox ml="auto">
                                                <MDButton variant="gradient" color="dark" size="small" onClick={navigateToSelectProgram}>
                                                assign program
                                                </MDButton>
                                            </MDBox>
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Card>
                        <Grid item xs={12} sm={12} mt={3} mb={3}>
                          <Card id="goals" mb={3}>
                            <MDBox p={2}>
                              <MDTypography variant="h5" fontWeight="medium">
                                Goals
                              </MDTypography>
                              {
                                trainee.goals && 
                                <MDTypography variant="h6" color="text" fontWeight="small">
                                  {trainee.goals}
                                </MDTypography>
                              }
                              {
                                trainee.goals == undefined && 
                                <MDTypography variant="h6" color="text" fontWeight="small">
                                  Client hasn't uploaded any goals.
                                </MDTypography>
                              }
                            </MDBox>
                          </Card>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </MDBox>
        </MDBox>
      <Footer />
      </DashboardLayout>
    );

  } else {
    return (
      <DashboardLayout>
        <DashboardNavbar />
          <MDBox py={3}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                    <MDTypography p={3}>
                        Client not found.
                    </MDTypography>
                    <Divider orientation="vertical" sx={{ mx: 0 }} />
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }
}

export default Client;
