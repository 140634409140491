/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Home menu routes
import homeRoutes from "home.routes";

var makeActiveTab = (activeTab) => {
  for(var route in homeRoutes) {
    if (homeRoutes[route].name == activeTab.active) {
      homeRoutes[route].active = true;
    } else {
      homeRoutes[route].active = false;
    }
  }
  return homeRoutes;
}

function Header(activeTab) {
  return (
    <MDBox display="flex" justifyContent="center" flexWrap="wrap" mb={3}>
      <DefaultNavbar
        routes={makeActiveTab(activeTab)}
        action={{
          type: "internal",
          // Log In Button
          route_1: "/sign-in",
          label_1: "Sign In",
          variant_1: "outlined",
          // Free Trial Button
          route_2: "/free-trial",
          label_2: "free trial",
          color_2: "dark",
        }}
        transparent
        dark
      />
    </MDBox>
  );
}

// Typechecking props for the Header
Header.propTypes = {};

export default Header;
