/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import MDButton from "components/MDButton";
import axios from "axios";

function Subscriptions({ user }) {

    const manageSubscriptions = async (e) => {
        e.preventDefault();
        const accessToken = localStorage.getItem("access_token");
        const session = await axios.post(`${process.env.REACT_APP_API_URL}/v1/billing_portal/sessions`,
        {
            customer: user.customerId,
            return_url: `${process.env.REACT_APP_FRONT_END_URL}/account/settings#subscriptions`,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        window.location.replace(session.data.portalSession.url);
    };
    return (
        <Card id="subscriptions">
        <MDBox p={2}>
            <Grid container spacing={3} alignItems="center">
            <Grid item>
                <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                    Subscriptions
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="medium">
                    Upgrade or cancel your existing subscription
                </MDTypography>
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                <MDBox
                display="flex"
                justifyContent={{ md: "flex-end" }}
                alignItems="center"
                lineHeight={1}
                >
                    <MDBox ml="auto">
                        <MDButton variant="gradient" color="dark" size="small" onClick={manageSubscriptions}>
                        manage subscription
                        </MDButton>
                    </MDBox>
                </MDBox>
            </Grid>
            </Grid>
        </MDBox>
        </Card>
    );
}

export default Subscriptions;
