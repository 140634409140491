import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import YoutubeEmbed from "components/YoutubeEmbed";
import axios from "axios";


function ExerciseTrainee() {
  const params = useParams();
  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToExercises = () => navigate("/dashboards/trainee/exercises");
  const navigateToCreateExercise = () => navigate("/dashboards/trainee/exercise-create");
  const navigateToEditExercise = () => navigate(`/dashboards/trainee/exercise/${params.exerciseId}/edit`);
  /* Values */
  const [name, setName] = useState();
  const [muscleGroup, setMuscleGroup] = useState();
  const [videoLink, setVideoLink] = useState();
  const [description, setDescription] = useState();

  const queryString = window.location.href;
  const s = queryString.split("/");
  const exerciseId = s[s.length-1];
  
  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await getExercise();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/trainee/exercise/${exerciseId}`]);

  async function getExercise() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainee/exercise/${exerciseId}`, {
      refreshToken: refreshToken,
    }, {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
      }
    })
    .then((response) => {
      if (response.data.name) {
          setName(response.data.name);
      }
      if (response.data.muscleGroup) {
          setMuscleGroup(response.data.muscleGroup);
      }
      if (response.data.videoLink) {
          setVideoLink(response.data.videoLink);
      }
      if (response.data.description) {
          setDescription(response.data.description);
      }
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject();
    });
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
        <Grid mt={6}>
            <MDBox pb={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {name}
              </MDTypography>
              <MDTypography variant="button" color="text">
                {muscleGroup}
              </MDTypography>
            </MDBox>
            <Grid pb={1}>
              {/*
              <MDButton
                variant="gradient"
                color="secondary"
                size="small"
                onClick={navigateBack}
                pr={3}
              >
                Back
              </MDButton>
              &nbsp;
              */}
            </Grid>
          <Card>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  { videoLink &&
                    <YoutubeEmbed embedLink={videoLink} />
                  }
                  {
                    !videoLink && 
                    <MDTypography>No video available.</MDTypography>
                  }
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
          <MDTypography variant="button" color="text">
            {description}
          </MDTypography>
        </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default ExerciseTrainee;
