
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { useMaterialUIController } from "context";

function Guarantee() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();
  const navigateToFreeTrial = async () => {
    navigate("/free-trial");
  }
  return (
    <MKBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
        <Grid container justifyContent="center">
            <Grid item xs={12} lg={8}>
                <Card  sx={{ boxShadow: 5, p: 2 }}>
                    <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        py={8}
                        justifyContent="center"
                        sx={{ mx: "auto", my: 8, textAlign: "center" }}
                    >
                        <Grid pb={3} >
                            <MKTypography variant="h2" mb={1} color={darkMode ? "white" : "dark"}>
                                100% money back guarantee
                            </MKTypography>
                            <MKTypography variant="body1" color="text">
                                Try Fit to Scale free for 30 days, use our platform and guide to grow your personal training business. You'll make 5x your subscription cost within the first 3 months or we'll give you your money back.
                            </MKTypography>
                        </Grid>
                        <MKButton variant="gradient" color="info" onClick={navigateToFreeTrial}>
                            start free trial
                        </MKButton>
                    </Grid>
                </Card>
            </Grid>
            
        </Grid>
    </MKBox>
  )
}

export default Guarantee;
