import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";
// @mui material components
import Grid from "@mui/material/Grid";
// Pricing page components
import Header from "layouts/main/components/Header";
import ContactUsOne from "layouts/main/components/ContactUsOne";
import FeaturesOne from "layouts/main/components/FeaturesOne";
import FeaturesTwo from "layouts/main/components/FeaturesTwo";
import FeaturesThree from "layouts/main/components/FeaturesThree";
import HeaderThree from "layouts/main/components/HeaderThree";
import StatsOne from "layouts/main/components/StatsOne";
import TestimonialsOne from "layouts/main/components/TestimonialsOne";
import Footer from "layouts/main/components/Footer";
import FooterThree from "layouts/main/components/FooterThree";
import TrainerFooter from "layouts/main/components/TrainerFooter";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";

// rellax
import Rellax from "rellax";

// typed-js
import * as Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";


import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredPriceCard from "examples/Cards/BlogCards/CenteredPriceCard";
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";
// Routes
import routes from "routes";

// Images
//import bgImage from "assets/images/bg-coworking.jpeg";
import bgImage from "assets/images/row-of-dumbbells.jpg";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import team1 from "assets/images/team-3.jpg";
import team2 from "assets/images/marie.jpg";
import team3 from "assets/images/team-2.jpg";
import { ConstructionOutlined } from "@mui/icons-material";

const getUrlPrefix = () => {
    const params = useParams();
    let url = window.location.href;
    let urlArray = url.split("/");
    let urlPrefix = "";
    let frontEndUrl = process.env.REACT_APP_FRONT_END_URL;
    let urlSplit = frontEndUrl.split("/");

    // ${process.env.REACT_APP_API_URL}/pages/<urlPrefix>
    if (urlArray.length > 2 && urlArray[2] == `${urlSplit[2]}`) {
        urlPrefix =  params.urlPrefix;
    // https://<urlPrefix>.fittoscale.io
    } else if (urlArray.length > 2) {
        let split = urlArray[2].split(".");
        if (split.length > 1 && split[1] == "fittoscale") {
            urlPrefix = split[0];
        }
    }

    return urlPrefix;
}

function Pages() {
  const [headline, setHeadline] = useState();
  const [headline2, setHeadline2] = useState();
  const [feature1_headline, setFeature1_headline] = useState();
  const [feature1_headline2, setFeature1_headline2] = useState();
  const [feature2_headline, setFeature2_headline] = useState();
  const [feature2_headline2, setFeature2_headline2] = useState();
  const [feature3_headline, setFeature3_headline] = useState();
  const [feature3_headline2, setFeature3_headline2] = useState();
  const [guarantee_headline, setGuarantee_headline] = useState();
  const [guarantee_headline2, setGuarantee_headline2] = useState();
  const [businessName, setBusinessName] = useState();
  const [product1_id, setProduct1_id] = useState();
  const [product1_name, setProduct1_name] = useState();
  const [product1_price, setProduct1_price] = useState();
  const [product1_cta, setProduct1_cta] = useState();
  const [product1_link, setProduct1_link] = useState();
  //const [product1_image, setProduct1_image] = useState();
  const [product2_id, setProduct2_id] = useState();
  const [product2_name, setProduct2_name] = useState();
  const [product2_price, setProduct2_price] = useState();
  const [product2_cta, setProduct2_cta] = useState();
  const [product2_link, setProduct2_link] = useState();
  //const [product2_image, setProduct2_image] = useState();
  const [product3_id, setProduct3_id] = useState();
  const [product3_name, setProduct3_name] = useState();
  const [product3_price, setProduct3_price] = useState();
  const [product3_cta, setProduct3_cta] = useState();
  const [product3_link, setProduct3_link] = useState();
  //const [product3_image, setProduct3_image] = useState();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);
  const navigate = useNavigate();
  const urlPrefix = getUrlPrefix();
  const navigateFreeTrial = () => {
    navigate("/free-trial");
  }
    /* Navigation */
    const navigateToCreatePage = () => {
        navigate("/dashboards/trainer/page-create");
    }

  useEffect(() => {
    // set page data used within the template
    const abortController = new AbortController();
    const fetchData = async() => await setPage();
    fetchData().catch(console.error);
    // set the products
    const fetchData1 = async() => await setProducts();
    fetchData1().catch(console.error);
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/trainer/page/get`, `${process.env.REACT_APP_API_URL}/v1/trainer/products/${urlPrefix}`]);

  async function setPage() {
    await axios.get(`${process.env.REACT_APP_API_URL}/v1/trainer/page/${urlPrefix}`)
    .then((response) => {
      let pageData = response.data;
      setHeadline(pageData.headline);
      setHeadline2(pageData.headline2);
      setFeature1_headline(pageData.feature1_headline);
      setFeature1_headline2(pageData.feature1_headline2);
      setFeature2_headline(pageData.feature2_headline);
      setFeature2_headline2(pageData.feature2_headline2);
      setFeature3_headline(pageData.feature3_headline);
      setFeature3_headline2(pageData.feature3_headline2);
      setGuarantee_headline(pageData.guarantee_headline);
      setGuarantee_headline2(pageData.guarantee_headline2);
      setBusinessName(pageData.businessName);
      //setProduct1(pageData.product1);
      //setProduct2(pageData.product2);
      //setProduct3(pageData.product3);
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
  }

  async function setProducts() {
    await axios.get(`${process.env.REACT_APP_API_URL}/v1/trainer/products/${urlPrefix}`)
    .then((response) => {
      let productData = response.data;
      for (var i=0; i<productData.length; i++) {
        if (i == 0) {
            setProduct1_id(productData[i].productId);
            setProduct1_name(productData[i].name);
            setProduct1_price(productData[i].price);
            setProduct1_cta(productData[i].cta);
            //setProduct1_image(productData[i].image);
            setProduct1_link(productData[i].link);
        } else if (i == 1) {
            setProduct2_id(productData[i].productId);
            setProduct2_name(productData[i].name);
            setProduct2_price(productData[i].price);
            setProduct2_cta(productData[i].cta);
            //setProduct2_image(productData[i].image);
            setProduct2_link(productData[i].link);
        } else if (i == 2) {
            setProduct3_id(productData[i].productId);
            setProduct3_name(productData[i].name);
            setProduct3_price(productData[i].price);
            setProduct3_cta(productData[i].cta);
            //setProduct3_image(productData[i].image);
            setProduct3_link(productData[i].link);
        }
      }
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
  }

  return (
    <PageLayout>
       <Card
            sx={{
                p: 2,
                mx: { xs: 2, lg: 3 },
                mt: 12,
                mb: 4,
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
        >
            <MKBox component="section" py={12}>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justifyContent="center"
                    mx="auto"
                    textAlign="center"
                    pt={6}
                >
                    <MKTypography variant="h2" mb={1} color={darkMode ? "white" : "dark"}>
                        You haven't created a landing page yet.
                    </MKTypography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justifyContent="center"
                    mx="auto"
                    textAlign="center"
                    pb={6}
                >
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justifyContent="center"
                    mx="auto"
                    textAlign="center"
                    pb={18}
                >
                    <MKButton
                        variant="gradient"
                        color="info"
                        size="small"
                        onClick={navigateToCreatePage}
                    >
                        Back to Dashboard
                    </MKButton>
                </Grid>
            </MKBox>
        </Card>
        <FooterThree twitterLink="https://twitter.com/fit_to_scale" instagramLink="https://www.instagram.com/fittoscale.io/" linkedInLink="https://www.linkedin.com/company/fit-to-scale/" />
      </PageLayout>
  );
}

export default Pages;
