// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";
// @mui material components
import Grid from "@mui/material/Grid";
// Pricing page components
import Header from "layouts/main/components/Header";
import FooterThree from "layouts/main/components/FooterThree";

function OurStory() {
  return (
    <PageLayout>
      <Header />
      <Grid>
        <br>
        </br>
        <br>
        </br>
      </Grid>
      <FooterThree />
    </PageLayout>
  );
}

export default OurStory;
