import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";

import axios from "axios";

// Data
import dataTableData from "layouts/pages/applications/data-tables/data/dataTableData";
//import ProductTableData from "layouts/pages/applications/data-tables/data/ProductTableData";

function Products() {

  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToDashboard = () => navigate("/dashboards/trainer");
  const navigateToCreateProduct = () => {
    localStorage.removeItem('Product');
    navigate("/dashboards/trainer/product-create");
  }

  const [productTableData, setProductTableData] = useState({
    columns: [
      { Header: "name", accessor: "name", width: "20%" },
      { Header: "price", accessor: "price", width: "20%"},
      { Header: "description", accessor: "description", width: "40%"},
      { Header: "edit", accessor: "edit", width: "10%" },
      { Header: "delete", accessor: "delete", width: "10%" },
    ],
    rows: [],
  });

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await setProducts();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/dashboards/trainer/products`]);

  async function setProducts() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/products`, {
      refreshToken: refreshToken,
    }, {
      headers: {
        "Authorization": "Bearer " + accessToken,
      }
    })
    .then((response) => {
      for (var i=0; i<response.data.length; i++) {
        // append any new information we want to view or otherwise need from the Product
        response.data[i].link = `/dashboards/trainer/product/${response.data[i].productId}`;
        response.data[i].productId = response.data[i].productId;
        response.data[i].editButtonFunction = "navigateToEditProduct";
        response.data[i].deleteButtonFunction = "pressDeleteProduct";
        response.data[i].price = response.data[i].price / 100.0;
      }
      setProductTableData({
        "columns": [
          { Header: "name", accessor: "name", width: "20%" },
          { Header: "price", accessor: "price", width: "20%"},
          { Header: "description", accessor: "description", width: "40%"},
          { Header: "edit", accessor: "edit", width: "10%" },
          { Header: "delete", accessor: "delete", width: "10%" },
        ],
        "rows": response.data,
      });
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject();
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid mt={6}>
            <MDBox pb={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                My Products
              </MDTypography>
              <MDTypography variant="button" color="text">
                Create, view, or edit a product to sell
              </MDTypography>
            </MDBox>
            <Grid pb={1}>
              <MDButton
                variant="gradient"
                color="secondary"
                size="small"
                onClick={navigateBack}
                pr={3}
              >
                Back
              </MDButton>
              &nbsp;
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={navigateToCreateProduct}
              >
                Create
              </MDButton>
            </Grid>
            
            <DataTable table={productTableData} />
        </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Products;
