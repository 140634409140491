// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from "@mui/icons-material/Pinterest";
import GitHubIcon from "@mui/icons-material/GitHub";

// Material Kit 2 PRO React examples
import CenteredFooter from "examples/Footers/CenteredFooter";

function FooterThress() {
  const company = { href: "/", name: "Fit to Scale, LLC" };
  const links = [
    { href: "/", name: "Fit to Scale" },
    { href: "/terms", name: "Terms" },
    { href: "/privacy", name: "Privacy Policy" },
  ];
  const socials = [
    {
      icon: <TwitterIcon fontSize="small" />,
      link: "https://twitter.com/fit_to_scale",
    },
    {
      icon: <InstagramIcon fontSize="small" />,
      link: "https://www.instagram.com/fittoscale.io/",
    },
    {
        icon: <LinkedInIcon fontSize="small" />,
        link: "https://www.linkedin.com/company/fit-to-scale/",
    }
  ];

  return <CenteredFooter company={company} links={links} socials={socials} />;
}

export default FooterThress;
