/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Settings page components
import BaseLayout from "layouts/account/components/BaseLayout";
import Sidenav from "layouts/account/settings/components/Sidenav";
import Header from "layouts/account/settings/components/Header";
import AccountInfo from "layouts/account/settings/components/AccountInfo";
import BasicInfo from "layouts/account/settings/components/BasicInfo";
import ChangePassword from "layouts/account/settings/components/ChangePassword";
import Subscriptions from "layouts/account/settings/components/Subscriptions";
import TraineeSubscriptions from "layouts/account/settings/components/TraineeSubscriptions";
import TraineeRegistrationLink from "layouts/account/settings/components/TraineeRegistrationLink";
import LandingPageLink from "layouts/account/settings/components/LandingPageLink";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Authentication from "layouts/pages/account/settings/components/Authentication";
// import Accounts from "layouts/pages/account/settings/components/Accounts";
import Notifications from "layouts/account/settings/components/Notifications";
import { SettingsSuggestRounded } from "@mui/icons-material";
// import Sessions from "layouts/pages/account/settings/components/Sessions";
// import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";
import axios from "axios";
import { syncIndexes } from "mongoose";

function Settings({ user }) {
  /** Fetch User **/
  //const [user, setUser] = useState();
  const [subscriptions, setSubscriptions] = useState();
  const accessToken = localStorage.getItem("access_token");
  const refreshToken = localStorage.getItem("refresh_token");

  useEffect(() => {
      const abortController = new AbortController();
      //const fetchData = async() => await checkIfUserIsEnabled();
      const fetchSubscriptions = async() => await getSubscriptions();
      //fetchData();
      fetchSubscriptions();
      return () => {
      abortController.abort();
      };
  }, [`${process.env.REACT_APP_API_URL}/v1/users/getUserByToken`, `${process.env.REACT_APP_API_URL}/v1/subscription/trainees`]);

  async function getSubscriptions() {
    if (refreshToken === undefined || accessToken === undefined || user === undefined) {
      setSubscriptions(null);
    } else {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/subscription/trainee/${user._id.toString()}`, {
        refreshToken,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      })
      .then((result) => { setSubscriptions(result.data); return Promise.resolve() })
      .catch((err) => { console.log(err); return Promise.reject() });
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav user={user} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header user={user}/>
                </Grid>

                { user && user.accountType && user.accountType === "Trainer" && (user?.fg_accept_payments == false || user?.fg_accept_payments == undefined) &&
                    <Grid item xs={12}>
                      <TraineeRegistrationLink user={user} />
                    </Grid>
                }
                { user && user.accountType && user.accountType === "Trainer" && user.fg_accept_payments == true &&
                    <Grid item xs={12}>
                      <LandingPageLink user={user} />
                    </Grid>
                }
                { user && user.accountType && user.accountType === "Trainer" &&
                    <Grid item xs={12}>
                      <Subscriptions user={user} />
                    </Grid>
                }
                { subscriptions && subscriptions.map(function(s, i) {
                  if (s.status == "active" || s.status == "trialing") {
                    return  (
                      <Grid item xs={12}>
                        <TraineeSubscriptions subscription={s} key={i}/>
                      </Grid>
                    )
                  }
                })}
                { user && user.accountType && user.accountType === "Trainer" && user.fg_accept_payments == true &&
                    <AccountInfo user={user} />
                }
                { user && 
                  <Grid item xs={12}>
                    <BasicInfo user={user} />
                  </Grid>
                }
                {/*}
                <Grid item xs={12}>
                  <ChangePassword />
                </Grid>
                */}
                {/*
                <Grid item xs={12}>
                  <Authentication />
                </Grid>
                <Grid item xs={12}>
                  <Accounts />
                </Grid>
                */}
                {/*
                <Grid item xs={12}>
                  <Notifications />
                </Grid>
                */}
                {/*
                <Grid item xs={12}>
                  <Sessions />
                </Grid>
                <Grid item xs={12}>
                  <DeleteAccount />
                </Grid>
              */}
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Settings;
