import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MDBox from "components/MDBox";


function CenteredPriceCard({ image, title, description, price, action }) {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (action.route !== undefined) {
      navigate(action.route);
    };
  }

  return (
    <Card>
      <MKBox p={3} mt={-1} textAlign="center">
        <MKButton
            variant="gradient"
            color={action.color ? action.color : "dark"}
            onClick={handleSubmit}
          >
            { title }
          </MKButton>
        <MKBox pt={3} pb={2} px={2} textAlign="center">
          <MKBox my={1}>
            { image &&
              <MDBox
                component="img"
                src={image}
                alt="test"
                borderRadius="lg"
                shadow="md"
                width="100%"
                height="100%"
                position="relative"
                zIndex={1}
              />
            }
            <MKTypography display="inline" variant="h5" fontWeight="regular">
              {description}
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox pt={3} pb={2} px={2} textAlign="center">
          <MKBox my={1}>
            <MKTypography variant="h1" color="dark">
              <MKTypography
                display="inline"
                component="small"
                variant="h5"
                color="inherit"
                verticalAlign="top"
              >
                {price.currency}
              </MKTypography>
              {price.value}
              <MKTypography display="inline" component="small" variant="h5" color="inherit">
                /{price.type}
              </MKTypography>
            </MKTypography>
          </MKBox>
        </MKBox>
        {action.type === "external" ? (
          <MKButton
            component={MuiLink}
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            size="small"
            color={action.color ? action.color : "dark"}
          >
            {action.label}
          </MKButton>
        ) : (
          <MKButton
            variant="gradient"
            size="small"
            color={action.color ? action.color : "dark"}
            onClick={handleSubmit}
          >
            {action.label}
          </MKButton>
        )}
      </MKBox>
    </Card>
  );
}

// Typechecking props for the CenteredPriceCard
CenteredPriceCard.propTypes = {
  //image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default CenteredPriceCard;
