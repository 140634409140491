/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

// @mui material components
import Snackbar from "@mui/material/Snackbar";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MKBox from "components/MKBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/account/components/FormField";
// Validation
import validator from "validator";
// Data
import selectData from "layouts/account/settings/components/AccountInfo/data/selectData";
// Restful API
import axios from "axios";
// @mui icons
import CloseIcon from "@mui/icons-material/Close";
// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput";

async function updateUser(userInfo) {
  // TODO (ereilly89): Ensure we have each of these checks similarly implemented in the API
  if (userInfo.firstName === undefined || userInfo.firstName.trim().length === 0) {
    const message = "please enter your first name.";
    return { type: ["firstName"], message: [message] };
  } else if (userInfo.firstName.trim().length > 24) {
    const message = "please limit your first name to the first 24 characters.";
    return { type: ["firstName"], message: [message] };
  }
}

function AccountInfo({ user }) {
  let [detailsSubmitted, setDetailsSubmitted] = useState(true);
  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
        const fetchData = async() => await getAccount();
        fetchData().catch(console.error);
    }
    return () => {
        abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/stripe/account/${user.accountId}`]);
    

  async function getAccount() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/stripe/account/${user.accountId}`, {
      refreshToken: refreshToken,
    }, {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
      }
    })
    .then((response) => {
        if (response.data.account.details_submitted) {
            setDetailsSubmitted(true);
        } else {
            setDetailsSubmitted(false);
        }
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject();
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem("refresh_token");

    await axios.post(`${process.env.REACT_APP_API_URL}/v1/stripe/account_link`,{
        account: user.accountId,
        refresh_url: `${process.env.REACT_APP_FRONT_END_URL}/account/settings`,
        return_url: `${process.env.REACT_APP_FRONT_END_URL}/account/settings`,
        type: 'account_onboarding',
    },{
        headers: {
            "Authorization": "Bearer " + accessToken,
        }
    }).then((resp) => {
        window.location.href = resp.data.accountLink.url;
    }).catch((err) => {
        console.log(err);
    });
  };

  const navigateToStripeDashboard = async (e) => {
    e.preventDefault();
    window.location.href = "https://dashboard.stripe.com";
    return null;
  }

  return (
      
        <Grid item xs={12}>
            { !detailsSubmitted &&
            <Card id="basic-info" sx={{ overflow: "visible" }}>
                <MDBox p={3}>
                    <MDTypography variant="h5">Accept Payments - Required Action</MDTypography>
                </MDBox>
                <MDBox component="form" pb={3} px={3}>
                    <MDBox pt={3}>
                        <MDTypography sx={{ fontStyle: 'italic', lineHeight: 1, fontWeight: 'light' }}>
                            Fit to Scale uses Stripe to get you paid quickly and keep your personal and payment information secure. Thousands of companies around the world trust Stripe to process payments for their users. Set up a Stripe account to get paid with Fit to Scale.
                        </MDTypography>
                    </MDBox>
                    
                    <Grid item xs={12} md={6} pt={3} lg={3} sx={{ ml: "auto" }}>
                        <MDBox
                        display="flex"
                        justifyContent={{ md: "flex-end" }}
                        alignItems="center"
                        lineHeight={1}
                        >
                            <MDBox ml="auto">
                                <MDButton variant="gradient" color="info" size="small" onClick={handleSubmit}>
                                    complete onboarding
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </Grid>
                </MDBox>
            </Card>
            }
            { detailsSubmitted &&
              <Card id="manage-payments">
                <MDBox p={2}>
                    <Grid container spacing={3} alignItems="center">
                    <Grid item>
                        <MDBox height="100%" mt={0.5} lineHeight={1}>
                        <MDTypography variant="h5" fontWeight="medium">
                            Manage Payments
                        </MDTypography>
                        <MDTypography variant="button" color="text" fontWeight="medium">
                            We use Stripe to manage payments
                        </MDTypography>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                        <MDBox
                        display="flex"
                        justifyContent={{ md: "flex-end" }}
                        alignItems="center"
                        lineHeight={1}
                        >
                           <MDBox ml="auto">
                                <MDButton variant="gradient" color="info" size="small" onClick={navigateToStripeDashboard}>
                                stripe dashboard
                                </MDButton>
                            </MDBox>
                            &nbsp;
                            <MDBox ml="auto">
                                <MDButton variant="gradient" color="dark" size="small" onClick={handleSubmit}>
                                manage onboarding
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </Grid>
                    </Grid>
                </MDBox>
              </Card>
            }
      </Grid>    
  );
}

export default AccountInfo;
