//import Features from "layouts/pages/features";
//import OurStory from "layouts/pages/our-story";
import PricingPage from "layouts/pages/pricing-page";

const homeRoutes = [
    {
        type: "collapse",
        name: "Home",
        key: "home",
        route: "/",
        noCollapse: true,
        active: false,
    },
    {
        type: "collapse",
        name: "Features",
        key: "features",
        route: "/features",
       // component: <PricingPage />,
        noCollapse: true,
        active: false,
    },
    {
        type: "collapse",
        name: "Pricing",
        key: "pricing",
        route: "/pricing",
        //component: <PricingPage />,
        noCollapse: true,
        active: false,
    },
    {
        type: "collapse",
        name: "Contact Us",
        key: "contact-us1",
        route: "/contact-us",
        noCollapse: true,
        active: false,
    },
    {
        type: "collapse",
        name: "Sign In",
        key: "sign-in",
        route: "/sign-in",
        noCallapse: true,
        active: false,
    }
];

export default homeRoutes;
