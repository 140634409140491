import { Navigate } from "react-router-dom";
/*
  A user "isAllowed" if they are authenticated to an existing users account,
  however, even if a user "isAllowed", they may not have already confirmed their
  email or subscribed to a plan.  A user "isSubscriber" if they are currently a
  subcriber and actively paid as shown via Stripe API. A user "isEmailVerified" if
  they have successfully confirmed their account via the link sent to their email.
*/
const ProtectedRoute = ({ user, route, children }) => {
  // only if route contains ["user"] role and a specific accountType Ex) ["User", "Trainer"] or ["User", "Trainee"]
  // will the user by authenticated for the route
  // Check if role is allowed Ex) admin, user
  const isRoleAllowed= !!user && route.roles.includes(user.role);
  // Check if account type is allowed Ex) Trainer, Trainee
  let isTypeAllowed = true
  if (route.roles.length > 1) {
    isTypeAllowed = (!!user && route.roles.includes(user.accountType));
  }
  // isAllowed based on role and account type
  let isAllowed = (isRoleAllowed && isTypeAllowed);
  // Check for active trainer subscription
  let isTrainerSubscriber = true;
  if (route.subscriptionRequired && route.subscriptionRequired === true) {
    isTrainerSubscriber = false;
    if (user != null && user.subscriptionStatus && (user.subscriptionStatus === "active" || user.subscriptionStatus === "trialing")) {
      isTrainerSubscriber = true;
    }
  }
  // Check for active trainee subscription
  let isTraineeSubscriber = true;
  if (route.subscriptionRequired && route.subscriptionRequired === true) {
    isTraineeSubscriber = false;
    if (user != null && user.subscriptions && user.subscriptions.status == "active") {
      isTraineeSubscriber = true;
    }
  }
  // Check if email verification is required
  let isEmailVerified = true;
  if (route.emailVerificationRequired && route.emailVerificationRequired === true) {
    isEmailVerified = false;
    if (user != null && user.isEmailVerified && user.isEmailVerified === true) {
      isEmailVerified = true;
    }
  }
  // Sign out the user if we can't authenticate them
  const accessTokenExpires = localStorage.getItem("access_token_expires");
  if (new Date(accessTokenExpires) < new Date() && route.roles) {
    return <Navigate to="/sign-out" replace />;
  }
  // Navigate user based on their level of authorization (1. signed-in, 2. subscribed, 3. email authenticated)
  if (!isAllowed) {
    // the condition below occurs upon refresh or when user is unauthenticated
    // TODO (ereilly89): decouple refresh and unauthenticated conditions, refresh
    //                   operations should return children if authenticated, while
    //                   unauthenticated users should be redirected to /sign-in.
    if (user == null) {
      return children;
    } else {
      return <Navigate to="/sign-in" replace />;
    }
  } else if (!isTrainerSubscriber && user.accountType === "Trainer") {
    if (!isTraineeSubscriber) {
      return <Navigate to="/subscribe" replace />;
    } else {
      return children;
    }
  } else if (!isEmailVerified) {
    return <Navigate to="/confirm-email" replace />;
  } else if (!isTraineeSubscriber && user.accountType === "Trainee") {
    // NOTE (ereilly89): eventually we need to redirect the trainee to a page where they can
    // subscribe to their trainer (or re-subscribe)
    // const subscribeUrl = "/subscribe?trainerId="+user.recentTrainer.toString();
    // return <Navigate to={subscribeUrl} replace />
    return children;
  }
  return children;
};

export default ProtectedRoute;
