import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Authenticate({ user }) {
  //const [user, setUser] = useState();
  const [billingPortalSessionUrl, setBillingPortalSessionUrl] = useState();
  const accessToken = localStorage.getItem("access_token");
  const [path, setPath] = useState("");
  const navigate = useNavigate();

  if (user?.productIdToSignUpWith && user?.productIdToSignUpWith) {
    const createCheckoutSession = async() => await getCheckoutSession(user);
    createCheckoutSession();
  } else {
    // Ssers who already have an active subscription may want an additional subscription.  In such cases, we would still like the user to update their profile.  
    // From the complete-profile page, the user will then navigate to the stripe endpoint to purchase their subscription from their new trainer.
    const queryString = window.location.search.trim();
    const urlParams = new URLSearchParams(queryString);
    const trainerId = urlParams.get("trainerId");
    if (trainerId != "null" && trainerId != null) {
      window.location.replace(`${process.env.REACT_APP_FRONT_END_URL}/dashboards/trainee`);
    }
    // Check if the user is an actively subscribed trainer
    if (user != null && user.accountType === "Trainer" && user.subscriptionStatus && (user.subscriptionStatus === "active" || user.subscriptionStatus === "trialing")) {
      window.location.replace(`${process.env.REACT_APP_FRONT_END_URL}/dashboards/trainer`);
    } else if (user != null && user.subscriptionStatus && user.subscriptionStatus === "past_due") {
      // User's subscription has recently expired, we need them to update their payment method
      window.location.replace(`${process.env.REACT_APP_FRONT_END_URL}/billingPortalSessionUrl`);
    // Check if the user is an actively subscribed trainee
    } else if (user != null && user.subscriptions != null && user.subscriptions.status == "active") {
      // await axios.post("${process.env.REACT_APP_API_URL}/subscription/productId/")
      // we should navigate to trainee dashboard only if user has the subscription in question already
      if (user?.productIdToSignUpWith == undefined && user?.productIdToSignUpWith == undefined) {
        window.location.replace(`${process.env.REACT_APP_FRONT_END_URL}/dashboards/trainee`);
      }
    // Trainer who do not have an active subscription
    } else if (user != null && user.accountType === "Trainer") {
      window.location.replace(`${process.env.REACT_APP_FRONT_END_URL}/complete-registration`);
    // Trainees who do not have an active subscription
    } else if (user != null && user.accountType === "Trainee") {
      window.location.replace(`${process.env.REACT_APP_FRONT_END_URL}/dashboards/trainee`);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    if (user?.accountType == "Trainer") {
      const fetchBillingPortalSession = async() => await getBillingPortalSession(user);
      fetchBillingPortalSession();
    }
    return () => {
    abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/billing_portal/sessions`]);

  // Trainer checkout
  async function getBillingPortalSession(user) {
    const session = await axios.post(`${process.env.REACT_APP_API_URL}/v1/billing_portal/sessions`,
    {
        customer: user.customerId,
        return_url: `${process.env.REACT_APP_FRONT_END_URL}/account/settings#subscriptions`,
    },
    {
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
    });
    setBillingPortalSessionUrl(session.data.portalSession.url);
  }

  // Trainee checkout
  async function getCheckoutSession(user) {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/stripe/create-checkout-session/${user.urlPrefixToSignUpWith}/${user.productIdToSignUpWith}/${user.email}`,
      {
      // priceID: priceID,
      }
    ).then((response) => {
      window.location.replace(response.data.checkoutSession.url);
    }).catch((err) => {
      // TODO (ereilly89): handle stripe error
      console.log(err);
    });
  }
  // TODO (ereilly89): navigate to some sort of 503 "something went wrong" page
  return null;
}

export default Authenticate;
