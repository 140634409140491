// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Pricing page components
import Header from "layouts/main/components/Header";
import Footer from "layouts/main/components/Footer";

function PaymentCanceled() {
  return (
    <PageLayout>
      <Header />
      <MDBox textAlign="center" mt={13} mb={72}>
        <MDTypography color="dark" mb={3} variant="h2">Payment Canceled</MDTypography>
        <MDTypography>Sorry we couldn't be of service to you, feel free to come back and try our platform for free any time!</MDTypography>
      </MDBox>
      <Footer />
    </PageLayout>
  );
}

export default PaymentCanceled;
