
import { useState } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MKBox from "components/MKBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
// Settings page components
import FormField from "layouts/account/components/FormField";
import axios from "axios";
// @mui icons
import CloseIcon from "@mui/icons-material/Close";
// @mui material components
import Snackbar from "@mui/material/Snackbar";
import { valid } from "chroma-js";

async function editFeedback(feedback) {
    if (feedback.q1 === undefined || feedback.q1.trim().length < 25) {
      const message = "responses must contain at least 25 characters.";
      return { type: ["q1"], message: [message] };
    } else if (feedback.q1.length > 256) {
      const message = "please limit your response to 256 characters.";
      return { type: ["q1"], message: [message] };
    }
    if (feedback.q2 === undefined || feedback.q2.trim().length < 25) {
        const message = "responses must contain at least 25 characters.";
        return { type: ["q2"], message: [message] };
    } else if (feedback.q2.length > 256) {
        const message = "please limit your response to 256 characters.";
        return { type: ["q2"], message: [message] };
    }
    if (feedback.q3 === undefined || feedback.q3.trim().length < 25) {
        const message = "responses must contain at least 25 characters.";
        return { type: ["q3"], message: [message] };
    } else if (feedback.q3.length > 256) {
        const message = "please limit your response to 256 characters.";
        return { type: ["q3"], message: [message] };
    }
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainee/feedback`, {
        refreshToken,
        feedback,
    }, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        }
    });
    return response;
}
  

function FeedbackUpload({ user }) {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
    const hideSnackbar = () => setShowSnackbar(false);
    const [snackbarColor, setSnackbarColor] = useState();
    const [snackbarText, setSnackbarText] = useState();

    const [q1, setQ1] = useState();
    const [q2, setQ2] = useState();
    const [q3, setQ3] = useState();
    const [validQ1, setValidQ1] = useState(true);
    const [validQ2, setValidQ2] = useState(true);
    const [validQ3, setValidQ3] = useState(true);
    const [q1HelpText, setQ1HelpText] = useState();
    const [q2HelpText, setQ2HelpText] = useState();
    const [q3HelpText, setQ3HelpText] = useState();
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        var feedback = {
            q1,
            q2,
            q3,
        }
        await editFeedback(feedback)
            .then((response) => {
                if (response.type && response.message) {
                    for (let i = 0; i < response.type.length; i += 1) {
                        if (response.type.length === response.message.length) {
                            switch (response.type[i]) {
                                case "q1":
                                    setValidQ1(false);
                                    setQ1HelpText(response.message[i]);
                                    return;
                                case "q2":
                                    setValidQ2(false);
                                    setQ2HelpText(response.message[i]);
                                    return;
                                case "q3":
                                    setValidQ3(false);
                                    setQ3HelpText(response.message[i]);
                                    return;
                                default:
                                    break;
                            }
                        }
                    }
                }
                if (response.status === 200) {
                    setSnackbarColor("success");
                    setSnackbarText("Thanks! Your feedback was submitted successfully.");
                    setShowSnackbar(true);
                }
            }).catch((err) => {
                console.log(err);
                setSnackbarColor("error");
                setSnackbarText("There was an error submitting your feedback.");
                setShowSnackbar(true);
            }
        );
    }

    const errorStyle = ({
        palette: { error },
        borders: { borderRadius },
        typography: { size },
        boxShadows: { lg },
      }) => ({
        "& .MuiPaper-root": {
          backgroundColor: error.main,
          borderRadius: borderRadius.lg,
          fontSize: size.sm,
          fontWeight: 400,
          boxShadow: lg,
          px: 2,
          py: 0.5,
        },
      });
    
    const successStyle = ({
        palette: { success },
        borders: { borderRadius },
        typography: { size },
        boxShadows: { lg },
        }) => ({
        "& .MuiPaper-root": {
            backgroundColor: success.main,
            borderRadius: borderRadius.lg,
            fontSize: size.sm,
            fontWeight: 400,
            boxShadow: lg,
            px: 2,
            py: 0.5,
        },
    });
    
    const toastTemplate = (
        <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
            {snackbarText}
            <CloseIcon
            fontSize="medium"
            sx={{ ml: 4, mr: -1, cursor: "pointer" }}
            onClick={toggleSnackbar}
            />
        </MKBox>
    );

    return (
        <MDBox mx={3}>
            {
                snackbarColor === "success" && 
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={showSnackbar}
                    autoHideDuration={3000}
                    onClose={hideSnackbar}
                    message={toastTemplate}
                    action={toggleSnackbar}
                    sx={successStyle}
                />
            }
            {
                snackbarColor === "error" && 
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={showSnackbar}
                    autoHideDuration={3000}
                    onClose={hideSnackbar}
                    message={toastTemplate}
                    action={toggleSnackbar}
                    sx={errorStyle}
                />
            }
            <MDBox mt={3}>
                <Grid item xs={12} sm={12} mb={1}>
                    <FormField 
                        id="feedback-q1"
                        label="Do you have any issues with the application or anything you would like to see fixed?"
                        variant="standard"
                        mb={3}
                        fullWidth
                        error={!validQ1}
                        helperText={q1HelpText}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setQ1(e.target.value);
                            if (e.target.value == "" || e.target.value.length < 25) {
                                setValidQ1(false);
                                setQ1HelpText("responses must contain at least 25 characters.");
                            } else if (e.target.value.length > 256) {
                                setValidQ1(false);
                                setQ1HelpText("please limit your response to 256 characters.");
                            } else {
                                setValidQ1(true);
                                setQ1HelpText("");
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} mb={1}>
                    <FormField 
                        id="feedback-q2"
                        label="Are there any new features you would like to see us add to your experience?"
                        variant="standard"
                        mb={3}
                        fullWidth
                        error={!validQ2}
                        helperText={q2HelpText}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setQ2(e.target.value);
                            if (e.target.value == "" || e.target.value.length < 25) {
                                setValidQ2(false);
                                setQ2HelpText("responses must contain at least 25 characters.");
                            } else if (e.target.value.length > 256) {
                                setValidQ2(false);
                                setQ2HelpText("please limit your response to 256 characters.");
                            } else {
                                setValidQ2(true);
                                setQ2HelpText("");
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} mb={1}>
                    <FormField 
                        id="feedback-q3"
                        label="What can we do to help improve your experience?"
                        variant="standard"
                        mb={3}
                        fullWidth
                        error={!validQ3}
                        helperText={q3HelpText}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setQ3(e.target.value);
                            if (e.target.value == "" || e.target.value.length < 25) {
                                setValidQ3(false);
                                setQ3HelpText("responses must contain at least 25 characters.");
                            } else if (e.target.value.length > 256) {
                                setValidQ3(false);
                                setQ3HelpText("please limit your response to 256 characters.");
                            } else {
                                setValidQ3(true);
                                setQ3HelpText("");
                            }
                        }}
                    />
                </Grid>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
              >
                <MDBox ml="auto" mt={2} mb={2}>
                  <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    Submit Feedback
                  </MDButton>
                </MDBox>
            </MDBox>
        </MDBox>
    )
}

export default FeedbackUpload;
