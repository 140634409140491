/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React related
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

// Validation
import validator from "validator";

// API
import axios from "axios";
import { PanoramaSharp } from "@mui/icons-material";

async function createUser(userInfo, trainerId) {
  try {
    if (userInfo.name === undefined || userInfo.name.trim().length === 0) {
      const message = "please enter your full name.";
      return { type: ["name"], message: [message] };
    }
    if (userInfo.email === undefined || userInfo.email.trim().length === 0) {
      const message = "please enter an email address.";
      return { type: ["email"], message: [message] };
    }
    if (!validator.isEmail(userInfo.email)) {
      const message = "please enter a valid email.";
      return { type: ["email"], message: [message] };
    }
    if (userInfo.password === undefined || userInfo.password.trim().length === 0) {
      const message = "please enter a password.";
      return { type: ["password"], message: [message] };
    }
    if (!userInfo.password.match(/\d/) || !userInfo.password.match(/[a-zA-Z]/)) {
      const message = "password must contain at least one letter and one number.";
      return { type: ["password"], message: [message] };
    }
    if (userInfo.accountType !== "Trainer" && userInfo.accountType !== "Trainee") {
      const message = "account type must be trainer or trainee";
      return { type: ["accountType"], message: [message] };
    }
    if (userInfo.policy === undefined || userInfo.policy === false) {
      const message = "agree to the terms and conditions to create an account.";
      return { type: ["policy"], message: [message] };
    }
    var trainerString = "";
    if (trainerId) {
      trainerString = "?trainer=" + trainerId;
    }
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/register` + trainerString, {
      name: userInfo.name,
      email: userInfo.email,
      password: userInfo.password,
      accountType: userInfo.accountType,
      termsAndConditions: userInfo.termsAndConditions,
    });

    return response;
  } catch (error) {
    return { type: ["email"], message: ["this email has already been taken."] };
  }
}

function SignUp() {
  const [accessToken, setAccessToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [urlPrefix, setUrlPrefix] = useState("");
  const [productId, setProductId] = useState("");
  /* Values */
  const [name, setName] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();
  const [policy, setPolicy] = useState();
  /* Validation */
  const [validName, setValidName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [validPolicy, setValidPolicy] = useState(true);
  /* Text */
  const [nameHelpText, setNameHelpText] = useState();
  const [emailHelpText, setEmailHelpText] = useState();
  const [passwordHelpText, setPasswordHelpText] = useState();
  const [policyHelpText, setPolicyHelpText] = useState();
  /* Navigation */
  const navigate = useNavigate();

  const [show, setShow] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalButtonColor, setModalButtonColor] = useState("error");
  const [modalButtonText, setModalButtonText] = useState("delete");
  const [modalTitle, setModalTitle] = useState();
  const toggleModal = () => setShow(!show);

  const accountType = "Trainee";
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const trainerId = urlParams.get("trainer");
  var signInLink = "/sign-in";
  if (trainerId) {
    signInLink = "/sign-in?trainer=" + trainerId;
  } else if (urlPrefix && productId) {
    signInLink = `/sign-in?urlPrefix=${urlPrefix}&productId=${productId}`;
  }

  const navigateToDashboard = async () => {
    navigate("/dashboards/trainee");
  }

  useEffect(() => {
    // const fetchData = async() => await setEmail();
    // fetchData().catch(console.error);
    // setRefreshToken(urlParams.get("token"));
    setUrlPrefix(urlParams.get("urlPrefix"));
    setProductId(urlParams.get("productId"));
    setAccessToken(localStorage.getItem("access_token"));
  });

  const handleKeyPress = (event) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
      handleSubmit(event)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await createUser({
      name,
      email,
      password,
      accountType,
      policy,
    },
      trainerId
    );
    if (response.type && response.message) {
      for (let i = 0; i < response.type.length; i += 1) {
        if (response.type.length === response.message.length) {
          switch (response.type[i]) {
            case "name":
              setValidName(false);
              setNameHelpText(response.message[i]);
              break;
            case "email":
              setValidEmail(false);
              setEmailHelpText(response.message[i]);
              break;
            case "password":
              setValidPassword(false);
              setPasswordHelpText(response.message[i]);
              break;
            case "policy":
              setValidPolicy(false);
              setPolicyHelpText(response.message[i]);
              break;
            default:
              break;
          }
        }
      }
    }
    if (response.status && response.status === 201) {
      const access_token = response.data.tokens.access.token;
      const refresh_token = response.data.tokens.refresh.token;
      const access_token_expires = response.data.tokens.access.expires;
      const refresh_token_expires = response.data.tokens.refresh.expires;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("access_token_expires", access_token_expires);
      localStorage.setItem("refresh_token_expires", refresh_token_expires);
      /* EMAIL VERIFICATION */
      if (response?.user?.isEmailVerified != true) {
        const response2 = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/auth/send-verification-email`,
          {
            user: response.user,
            urlPrefix: urlPrefix,
            productId: productId,
          },
          {
            headers: {
              Authorization: `Bearer ${response.data.tokens.access.token}`,
            },
          }
        );
        setValidName(undefined);
        setValidEmail(undefined);
        setValidPassword(undefined);
        setValidPolicy(undefined);
        if (response2.status === 204) {
          setModalTitle("Email Sent")
          setModalMessage("Please check your inbox for a link to verify your email.");
          setModalButtonColor("info");
          setModalButtonText("Okay");
          toggleModal();
        } else {
          setModalTitle("Error");
          setModalMessage("There was an error processing your request. Please try again later.");
          setModalButtonColor("error");
          setModalButtonText("Okay");
          toggleModal();
        }
      } else {
        navigateToDashboard();
      }
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Container>
          <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={show} timeout={500}>
              <MKBox
                position="relative"
                width="500px"
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
              >
                <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                  <MKTypography variant="h5">{modalTitle}</MKTypography>
                  <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox p={2}>
                  <MKTypography variant="body2" color="secondary" fontWeight="regular">
                    {modalMessage}
                  </MKTypography>
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                  <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                    close
                  </MKButton>
                  <MKButton variant="gradient" color={modalButtonColor} onClick={navigateToDashboard}>
                    {modalButtonText}
                  </MKButton>
                </MKBox>
              </MKBox>
            </Slide>
          </Modal>
        </Container>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Name"
                variant="standard"
                fullWidth
                error={!validName}
                helperText={nameHelpText}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setName(e.target.value);
                  if (e.target.value) {
                    if (e.target.value.length > 50) {
                      // if not valid name
                      if (validName) {
                        setValidName(false);
                        setNameHelpText("Please limit your name to 50 characters or less.");
                      }
                    } else {
                      setValidName(true);
                      setNameHelpText("");
                    }
                  } else {
                    setValidName(false);
                  }
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                error={!validEmail}
                helperText={emailHelpText}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (e.target.value) {
                    if (validator.isEmail(e.target.value)) {
                      if (validEmail === false) {
                        setValidEmail(true);
                        setEmailHelpText("");
                      }
                    } else {
                      setValidEmail(false);
                    }
                  } else {
                    setValidEmail(false);
                  }
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                error={!validPassword}
                helperText={passwordHelpText}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (e.target.value) {
                    if (e.target.value.length >= 8 && e.target.value.length <= 128) {
                        if (e.target.value.match(/\d/) && e.target.value.match(/[a-zA-Z]/)) {
                          if (validPassword === false) {
                            setValidPassword(true);
                            setPasswordHelpText("");
                          }
                        } else {
                          setValidPassword(false);
                          setPasswordHelpText("password must contain at least one letter and one number.");
                        }
                    } else if (e.target.value.length > 128) {
                        setValidPassword(false)
                        setPasswordHelpText("password cannot be any more than 128 characters.");
                    } else if (e.target.value.length < 8) {
                        setValidPassword(false)
                        setPasswordHelpText("password must be at least 8 characters.");
                    }
                  } else if (validPassword === true) {
                      setValidPassword(false);
                  }
                }}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                error={!validPolicy}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setPolicy(true);
                  } else {
                    setPolicy(false);
                    setPolicyHelpText("");
                  }
                }}
              />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree to the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="/terms"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDTypography
                component="a"
                href="/terms"
                variant="button"
                fontWeight="light"
                color="dark"
                textGradient
              >
                {policyHelpText}
            </MDTypography>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                Create Account
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to={signInLink}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default SignUp;
