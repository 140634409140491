/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import axios from "axios";

async function resetPassword(password, password2) {
  try {
    // password validation
    if (password === undefined || password.trim().length === 0) {
      const message = "please enter your new password.";
      return { type: ["password"], message: [message] };
    }
    if (password2 === undefined || password2.trim().length === 0) {
      const message = "please enter your new password again.";
      return { type: ["password2"], message: [message] };
    }
    if (!password.match(/\d/) || !password.match(/[a-zA-Z]/)) {
      const message = "password must contain at least one letter and one number.";
      return { type: ["password"], message: [message] };
    }
    if (password !== password2) {
      const message = "new password and confirmation password must match.";
      return { type: ["password2"], message: [message] };
    }
  } catch (error) {
    return {
      status: 401,
      message: "There was an error with the request.",
    };
  }
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");
  const url = `${process.env.REACT_APP_API_URL}/v1/auth/reset-password?token=`+token;
  const request = await axios.post(url, {
    password,
  });

  return request;
}

function ResetPassword() {
  /* Values */
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  /* Validation */
  const [validPassword, setValidPassword] = useState();
  const [validPassword2, setValidPassword2] = useState();
  /* Text */
  const [passwordHelpText, setPasswordHelpText] = useState();
  const [passwordHelpText2, setPasswordHelpText2] = useState();
  /* Navigation */
  const navigate = useNavigate();

  const handleKeyPress = (event) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
      handleSubmit(event)
    }
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await resetPassword(password, password2);
    if (response && response.type && response.message) {
      if (response.type == "password") {
        setValidPassword(false);
        setPasswordHelpText(response.message);
      } else if (response.type == "password2") {
        setValidPassword2(false);
        setPasswordHelpText2(response.message);
      }
      return;
    }
    if (response.status === 204) {
      navigate("/account/settings");
    }
  };

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="password"
                label="New Password"
                variant="standard"
                fullWidth
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (e.target.value) {
                    if (
                      e.target.value.match(/\d/) &&
                      e.target.value.match(/[a-zA-Z]/) &&
                      e.target.value.length >= 8 &&
                      e.target.value.length <= 256
                    ) {
                      if (validPassword === false) {
                        setValidPassword(true);
                        setPasswordHelpText("");
                      }
                    } else if (validPassword === true) {
                      setValidPassword(false);
                    } else if (validPassword === false) {
                      setPasswordHelpText(
                        "password must contain at least one letter and one number."
                      );
                    }
                  }
                }}
                onFocus={() => {
                  if (validPassword2) {
                    setValidPassword2(undefined);
                  }
                }}
              />
              <MDTypography color="error" variant="caption">
                {passwordHelpText}
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Confirm Password"
                variant="standard"
                fullWidth
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setPassword2(e.target.value);
                  setValidPassword2(undefined);
                  setPasswordHelpText2("");
                }}
                onFocus={() => {
                  if (validPassword) {
                    setValidPassword(undefined);
                  }
                }}
              />
              <MDTypography color="error" variant="caption">
                {passwordHelpText2}
              </MDTypography>
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                reset
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default ResetPassword;
