/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Overview page components
import Header from "layouts/profile/components/Header";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

function Messaging() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* 
        <Header>
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} xl={12}>
                <ProfilesList title="conversations" profiles={profilesListData} shadow={false} />
              </Grid>
            </Grid>
          </MDBox>
        </Header>
      */}
      
      <MDBox py={3}>
          <MDBox mb={3}>
              <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                      <DefaultInfoCard
                        title="Messages"
                        description="You don't have any messages yet (coming soon)."
                        icon={<Icon>message</Icon>}
                        color="primary"
                      />
                  </Grid>
              </Grid>
          </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Messaging;
