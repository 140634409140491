import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";

import axios from "axios";

// Data
import dataTableData from "layouts/pages/applications/data-tables/data/dataTableData";
//import programTableData from "layouts/pages/applications/data-tables/data/programTableData";

function AssignProgram() {

  /* Navigation */
  const navigate = useNavigate();
  const location = useLocation();
  const navigateBack = () => navigate(-1);
  const navigateToDashboard = () => navigate("/dashboards/trainer");
  const navigateToCreateProgram = () => navigate("/dashboards/trainer/program-create");

  const [programTableData, setProgramTableData] = useState({
    columns: [
      { Header: "name", accessor: "name", width: "50%" },
      { Header: "users", accessor: "users", width: "40%"},
      { Header: "select", accessor: "select", width: "10%"},
    ],
    rows: [],
  });

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await setPrograms();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/dashboards/trainer/programs`]);

  async function setPrograms() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/programs`, {
      refreshToken: refreshToken,
      queryParams: {}
    }, {
      headers: {
        "Authorization": "Bearer " + accessToken,
      }
    })
    .then((response) => {
      for (var i=0; i<response.data.length; i++) {
        // append any new information we want to view or otherwise need from the program
        response.data[i].link = `/dashboards/trainer/program/${response.data[i].id}`;
        response.data[i].selectButtonFunction = "assignProgram";
      }
      setProgramTableData({
        "columns": [
          { Header: "name", accessor: "name", width: "50%" },
          { Header: "users", accessor: "users", width: "40%"},
          { Header: "select", accessor: "select", width: "10%"},
        ],
        "rows": response.data,
      });
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid mt={6} mb={18}>
            <MDBox pb={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Select Program
              </MDTypography>
              <MDTypography variant="button" color="text">
                Assign a program to your trainee
              </MDTypography>
            </MDBox>
            {/*
            <Grid pb={1}>
              <MDButton
                variant="gradient"
                color="secondary"
                size="small"
                onClick={navigateBack}
                pr={3}
              >
                Back
              </MDButton>
            </Grid>
            */}
            <DataTable table={programTableData} />
        </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default AssignProgram;
