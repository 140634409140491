
import { useState, useEffect } from "react";

import DefaultCell from "layouts/pages/dashboards/sales/components/DefaultCell";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import Grid from "@mui/material/Grid";
import axios from "axios";

function TraineeLineChartData(){

  const [data, setData] = useState();

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await getLineChartData();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/dashboards/trainer/trainee-line-chart-data`]);

  async function getLineChartData() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/trainee-line-chart-data`, {
      refreshToken: refreshToken,
    }, {
      headers: {
        "Authorization": "Bearer " + accessToken,
      }
    })
    .then((response) => {
      setData(response.data);
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject();
    });
  }

  return (
    <Grid>
      { data && (
        <DefaultLineChart
          title="# Trainees"
          description={
            <MDBox display="flex" justifyContent="space-between">
              <MDBox display="flex" ml={-1}>
                <MDBadgeDot color="info" size="sm" badgeContent="New" />
                <MDBadgeDot color="dark" size="sm" badgeContent="Existing" />
              </MDBox>
              <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
              </MDBox>
            </MDBox>
          }
          chart={data} />)
        }
    </Grid>
  );
}
  
export default TraineeLineChartData;
