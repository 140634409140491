// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";

// @mui material components
import Grid from "@mui/material/Grid";

// Pricing page components
import DataSubjectAccessRequestForm from "layouts/main/components/DataSubjectAccessRequestForm";
import FooterThree from "layouts/main/components/FooterThree";
import Header from "layouts/main/components/Header";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// rellax
import Rellax from "rellax";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function DataSubjectAccessRequest() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const headerRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });
    return () => parallax.destroy();
  }, []);

  return (
    <PageLayout>
      <Header />
      <Grid container spacing={3} pt={6}>
        <Grid item xs={12} sm={3}></Grid>
        <Grid item xs={12} sm={6}>
          <MKBox
            ref={headerRef}
            minHeight="10vh"
            width="100%"
          >
              <MKTypography ml={5} mt={5} variant="h4">Data Subject Access Request Form</MKTypography>
          </MKBox>
          <Card
            sx={{
              mx: { xs: 2, lg: 3 },
              mt: -8,
              mb: 4,
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
            <DataSubjectAccessRequestForm />
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}></Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <FooterThree />
      </MKBox>
    </PageLayout>
  );
}

export default DataSubjectAccessRequest;
