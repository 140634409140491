import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MKBox from "components/MKBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import CustomModal from "./components/CustomModal";
import { toggleModal } from "./components/CustomModal";

function Product() {
  const params = useParams();
  const [modalMessage, setModalMessage] = useState();
  const [modalButtonColor, setModalButtonColor] = useState();
  const [modalButtonText, setModalButtonText] = useState();
  const [modalButtonFunction, setModalButtonFunction] = useState();
  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToProducts = () => navigate("/dashboards/trainer/products");

  const [productName, setProductName] = useState();
  const [productDescription, setProductDescription] = useState();
  const navigateToDashboard = () => navigate("/dashboards/trainer");
  // const navigateToCreateExercise = () => navigate("/dashboards/trainer/exercise-create");
  const navigateToEditProduct = () => {
    localStorage.removeItem('product');
    navigate(`/dashboards/trainer/product/${params.productId}/edit`);
  }

  const queryString = window.location.href;
  const s = queryString.split("/");
  const productId = s[s.length-1];

  const pressDeleteProduct = async () => {
    var result;
    var accessToken = localStorage.getItem('access_token');
    var refreshToken = localStorage.getItem('refresh_token');
    result = await axios.post(`${process.env.REACT_APP_API_URL}/v1/subscription/product/${productId}`, {
      refreshToken: refreshToken,
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
    if (result && result.data && result.data.productId) {
      setModalMessage("You have one or more clients assigned to this product. Please reassign all clients using this product to a different one before deleting.");
      setModalButtonColor("info");
      setModalButtonText("view clients");
      setModalButtonFunction("navigateToClients");
      toggleModal();
    } else {
      setModalMessage("Are you sure you want to delete this product?");
      setModalButtonColor("error");
      setModalButtonText("delete");
      setModalButtonFunction("deleteProduct");
      toggleModal();
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await setProduct();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/trainer/product/${productId}`]);
  
  async function setProduct() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/product/${productId}`, {
      refreshToken: refreshToken,
    }, {
      headers: {
        "Authorization": "Bearer " + accessToken,
      }
    })
    .then((response) => {
      // append any new information we want to view or otherwise need from the exercise
      setProductName(response.data.name);
      setProductDescription(response.data.description);
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject();
    });
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
         <MKBox component="section">
          <Container>
            <CustomModal
              message={modalMessage}
              buttonColor={modalButtonColor}
              buttonText={modalButtonText}
              buttonFunction={modalButtonFunction}
              redirect="/dashboards/trainer/products"
              toDelete={productId}
            />
          </Container>
        </MKBox>
        <Grid mt={6}>
            <MDBox pb={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {productName}
              </MDTypography>
              <MDTypography variant="button" color="text">
                {productDescription}
              </MDTypography>
            </MDBox>
            <Grid pb={1}>
              <MDButton
                variant="gradient"
                color="secondary"
                size="small"
                onClick={navigateBack}
                pr={3}
              >
                Back
              </MDButton>
              &nbsp;
              <MDButton
                onClick={navigateToEditProduct}
                variant="gradient"
                color="warning"
                size="small"
              >
                edit
              </MDButton>
              &nbsp;
              <MDButton
                onClick={pressDeleteProduct}
                variant="gradient"
                color="error"
                size="small"
              >
                delete
              </MDButton>
            </Grid>
        </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Product;
