import { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

import { useMaterialUIController } from "context";
import axios from "axios";

function StatsOne() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [trainers, setTrainers] = useState(0);
  const [programs, setPrograms] = useState(0);
  const [trainees, setTrainees] = useState(0);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchUserData = async() => await getStatData();
    fetchUserData();
    return () => {
    abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/subscription/getStatData`]);

  async function getStatData() {
    var accessToken = localStorage.getItem('access_token');
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/subscription/getStatData`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    setTrainers(response.data.numTrainers);
    setPrograms(response.data.numPrograms);
    setTrainees(response.data.numTrainees);
  }

  return (
    <MKBox component="section" pb={3} pt={10}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={trainers}
              title="Trainers"
              description="Scaling their business with us"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={programs}
              title="Programs"
              description="Customized by trainers on our platform"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={trainees}
              title="Trainees"
              description="Signed up with trainers on our platform"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default StatsOne;
