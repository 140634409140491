/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

// Imags
const bgImage =
  "https://images.unsplash.com/photo-1467541473380-93479a5a3ffa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=2246&amp;q=80";

function Pricing() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("monthly");

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  return (
      
      <MKBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={6}>
            <DefaultPricingCard
              color={darkMode ? "dark" : "white"}
              badge={{ color: darkMode ? "warning" : "light", label: "starter" }}
              price={{ currency: "$", value: "0", type: "mo" }}
              specifications={[
                { label: "Unlimited free trial", includes: true },
                { label: "Includes 5 clients", includes: true },
                { label: "Customized Programs", includes: true },
                { label: "Landing Page", includes: false},
                { label: "Accept Payments", includes: false },
                { label: "Workout Analytics", includes: false},
                { label: "Session Scheduling", includes: false},
                { label: "Messaging & Automation", includes: false},
              ]}
              action={{
                type: "internal",
                route: "/free-trial",
                color: darkMode ? "warning" : "light",
                label: "join",
              }}
              shadow={true}
            />
          </Grid>
          {/*
          { label: "Workout Analytics", includes: false },
          { label: "Session Scheduling", includes: false },
          { label: "Messaging & Automation", includes: false },
          { label: "Marketing Templates", includes: false },
          */}
          <Grid item xs={12} lg={6}>
            <DefaultPricingCard
              color="dark"
              badge={{ color: "info", label: "professional" }}
              price={{ currency: "$", value: "25", type: "mo" }}
              specifications={[
                { label: "30 day free trial", includes: true },
                { label: "Includes 15 clients (then $1.50/mo ea.)", includes: true },
                { label: "Customized Programs", includes: true },
                { label: "Landing Page", includes: true},
                { label: "Accept Payments", includes: true },
                { label: "Workout Analytics", includes: false},
                { label: "Session Scheduling", includes: false},
                { label: "Messaging & Automation", includes: false},
              ]}
              action={{
                type: "internal",
                color: "info",
                route: "/free-trial",
                label: "select",
              }}
              shadow={true}
            />
          </Grid>
          {/*
            { label: "Workout Analytics", includes: false },
            { label: "Session Scheduling", includes: false },
            { label: "Messaging & Automation", includes: false },
            { label: "Marketing Templates", includes: false },
          */}
          {/*
          <Grid item xs={12} lg={4}>
            <DefaultPricingCard
                color={darkMode ? "dark" : "white"}
                badge={{ color: darkMode ? "warning" : "light", label: "master" }}
                price={{ currency: "$", value: "49", type: "mo" }}
                specifications={[
                  { label: "30 day free trial", includes: true },
                  { label: "Includes 40 clients (then $1.00/mo ea.)", includes: true },
                  { label: "Customized Programs", includes: true },
                  { label: "Landing Page", includes: true},
                  { label: "Accept Payments", includes: true },
                  { label: "Workout Analytics", includes: true},
                  { label: "Session Scheduling", includes: true},
                  { label: "Messaging & Automation", includes: true},
                ]}
                action={{
                  type: "internal",
                  color: darkMode ? "warning" : "light",
                  route: "/pricing",
                  label: "coming soon",
                }}
                shadow={darkMode}
              />
          </Grid>
              */}
        </Grid>
      </MKBox>
  );
}

export default Pricing;
