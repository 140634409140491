import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import axios from "axios";

function PaymentSuccess() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [responseHTML, setResponseHTML] = useState();
  const sessionID = searchParams.get("session_id")
  const accessToken = localStorage.getItem("access_token");
  const refreshToken = localStorage.getItem("refresh_token");
  const navigate = useNavigate();

  useEffect(() => {
    const abortController = new AbortController();
    axios.post(`${process.env.REACT_APP_API_URL}/v1/stripe/order/success?session_id=${sessionID}`,
    {
        refreshToken: refreshToken,
    },
    {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
    .then(response => {
        setResponseHTML(
            <React.Fragment>
                <MDTypography color="dark" variant="h2" fontWeight="bold" dangerouslySetInnerHTML={{__html: response.data}} />
            </React.Fragment>
        )
    })
    .catch(err => {
        navigate("/canceled");
    });
    return () => {
        abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/stripe/order/success?session_id=${sessionID}`]);

  window.onload = () => {
    let reloading = sessionStorage.getItem("reloading");
    if (reloading) {
        sessionStorage.removeItem("reloading");
        navigate("/authenticate");
    } else {
        sessionStorage.setItem("reloading", "true");
    }
  }

  const handleGetStarted = async (e) => {
    window.location.reload(false);
  }

  return (
    <DashboardLayout>
        <DashboardNavbar />
        <MDBox textAlign="center" mt={13} mb={72}>
            {responseHTML}
            <MDTypography mt={3}>Your 30 day free trial has started. Cancel your subscription any time before then and you won't be charged.</MDTypography>
            <MDTypography mb={3}>Finish verifying your email to get started improving your fitness services.  Click 'Get Started' below or refresh the page to continue.</MDTypography>
            <MDButton variant="gradient" color="dark" onClick={handleGetStarted}>Get Started</MDButton>
        </MDBox>
        <Footer />
    </DashboardLayout>
  );
}

export default PaymentSuccess;
