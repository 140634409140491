import { useNavigate } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/bg3.jpg";

function CtaThree() {
  const navigate = useNavigate();

  const navigateToFreeTrial = async () => {
    navigate("/free-trial");
  }

  return (
    <MKBox
      display="flex"
      mt={12}
      py={6}
      sx={{
        backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.8), rgba(gradients.dark.state, 0.8))}, url(${bgImage})`,
        borderBottomRightRadius: 24/ 2,
        borderBottomLeftRadius: 24/ 2,
      }}
    >
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={8} lg={5}>
            <MKTypography variant="h5" color="white" fontWeight="bold">
              Take your training business to the next level.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: "auto" }}>
            <MKBox width="12rem" ml="auto">
              <MKButton variant="gradient" color="info" fullWidth sx={{ boxShadow: "none" }} onClick={navigateToFreeTrial}>
                get started for free
              </MKButton>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default CtaThree;
