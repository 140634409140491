/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import MDButton from "components/MDButton";
import axios from "axios";


function LandingPageLink({ user }) {
    const [landingPageLink, setLandingPageLink] = useState();
    const [showCopyLinkButton, setShowCopyLinkButton] = useState();
    const [helpText, setHelpText] = useState();
    const navigate = useNavigate();
    const handleCopyLink = async (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(landingPageLink);
    };
    const handleCreateLandingPage = async (e) => {
        e.preventDefault();
        navigate("/dashboards/trainer/page/edit");
    }

    useEffect(() => {
        const abortController = new AbortController();
        const refreshToken = localStorage.getItem("refresh_token");
        const accessToken = localStorage.getItem("access_token");
        if (user && user.urlPrefix) {
            if (!(refreshToken === undefined || accessToken === undefined)) {
                const fetchLandingPageLink = async() => await getLink();
                fetchLandingPageLink().catch(console.error);
            }
        }
        return () => {
            abortController.abort();
        };
    }, [`${process.env.REACT_APP_API_URL}/v1/trainer/page/${user.urlPrefix}`]);
    
    async function getLink() {
        if (user) {
            await axios.get(`${process.env.REACT_APP_API_URL}/v1/trainer/page/${user.urlPrefix}`)
            .then((response) => {
                let pageData = response.data;
                if (pageData.url) {
                    setShowCopyLinkButton(true);
                    setLandingPageLink(pageData.url);
                } else {
                    setShowCopyLinkButton(false);
                    setHelpText("Create a landing page in the dashboard to start onboarding clients");
                }
                return Promise.resolve();
            })
            .catch((err) => {
                console.log(err);
                return Promise.reject();
            });
        }
    }

    return (
        <Card id="trainee-registration-link">
        <MDBox p={2}>
            <Grid container spacing={3} alignItems="center">
                { user && user._id &&
                <Grid item>
                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                        <MDTypography variant="h5" fontWeight="medium">
                            Landing Page
                        </MDTypography>
                        { user.urlPrefix == undefined &&
                        <MDTypography variant="button" color="text" fontWeight="medium">
                            Create a landing page in the dashboard to start getting clients
                        </MDTypography>
                        }
                         { user.urlPrefix &&
                          <MDTypography variant="button" color="text" fontWeight="medium">
                            {landingPageLink}
                          </MDTypography>
                        }
                    </MDBox>
                </Grid>
                }
                { user.urlPrefix == undefined &&
                <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                    <MDBox
                    display="flex"
                    justifyContent={{ md: "flex-end" }}
                    alignItems="center"
                    lineHeight={1}
                    >
                        <MDBox ml="auto">
                            <MDButton variant="gradient" color="dark" size="small" onClick={handleCreateLandingPage}>
                            create landing page
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </Grid>
                }
                { user.urlPrefix &&
                <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                    <MDBox
                    display="flex"
                    justifyContent={{ md: "flex-end" }}
                    alignItems="center"
                    lineHeight={1}
                    >
                        <MDBox ml="auto">
                            <MDButton variant="gradient" color="dark" size="small" onClick={handleCopyLink}>
                            copy link
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </Grid>
                }
            </Grid>
        </MDBox>
        </Card>
    );
}

export default LandingPageLink;
