/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import { useState, useEffect } from "react";

// Material Dashboard 2 PRO React layouts

//* Production Pages *//
import Main from "layouts/main/home";
import Home from "layouts/applications/home";
import Analytics from "layouts/applications/analytics";
import Scheduling from "layouts/applications/scheduling";
import Messages from "layouts/applications/messaging";
import TrainerDashboard from "layouts/dashboards/trainer";
import TraineeDashboard from "layouts/dashboards/trainee";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Profile from "layouts/profile";
import Settings from "layouts/account/settings";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignUpCover from "layouts/authentication/sign-up/cover";
import SignOut from "layouts/authentication/sign-out";
import FreeTrial from "layouts/authentication/free-trial";
import Register from "layouts/authentication/register";
import PaymentSuccess from "layouts/payments/success";
import PaymentCanceled from "layouts/payments/canceled";
import EmailVerification from "layouts/authentication/email-verification";
import ResetCover from "layouts/authentication/forgot-password/cover";
import ResetPassword from "layouts/authentication/reset-password";
import Authenticate from "layouts/authentication/authenticate";
import CheckYourEmail from "layouts/authentication/check-your-email";
import ConfirmEmail from "layouts/authentication/confirm-email";
import Subscribe from "layouts/authentication/subscribe";
import CompleteRegistration from "layouts/main/complete-registration";
import CompleteProfile from "layouts/main/complete-profile";
import Features from "layouts/main/features";
import OurStory from "layouts/main/our-story";
import Pricing from "layouts/main/pricing";
import ContactUs from "layouts/main/contact-us";
import DataSubjectAccessRequest from "layouts/main/data-subject-access-request";
import PrivacyPolicy from "layouts/main/privacy";
import TermsAndConditions from "layouts/main/terms";
import LearnMore from "layouts/main/learn-more";
import Client from "layouts/dashboards/trainer/Client";
import ExerciseCreate from "layouts/dashboards/trainer/ExerciseCreate";
import WorkoutCreate from "layouts/dashboards/trainer/WorkoutCreate";
import ProgramCreate from "layouts/dashboards/trainer/ProgramCreate";
import ProductCreate from "layouts/dashboards/trainer/ProductCreate";
import EditExercise from "layouts/dashboards/trainer/EditExercise";
import EditWorkout from "layouts/dashboards/trainer/EditWorkout";
import EditProgram from "layouts/dashboards/trainer/EditProgram";
import EditPage from "layouts/dashboards/trainer/EditPage";
import EditProduct from "layouts/dashboards/trainer/EditProduct";
import Exercise from "layouts/dashboards/trainer/Exercise";
import ExerciseTrainee from "layouts/dashboards/trainee/Exercise";
import Workout from "layouts/dashboards/trainer/Workout";
import Program from "layouts/dashboards/trainer/Program";
import Product from "layouts/dashboards/trainer/Product";
import Page from "layouts/dashboards/trainer/Page";
import Pages from "layouts/dashboards/trainer/Pages";
import AddExerciseToWorkout from "layouts/dashboards/trainer/workouts/AddExerciseToWorkout";
import AddExerciseToWorkoutEdit from "layouts/dashboards/trainer/workouts/AddExerciseToWorkoutEdit";
import AddWorkoutToProgram from "layouts/dashboards/trainer/programs/AddWorkoutToProgram";
import AddWorkoutToProgramEdit from "layouts/dashboards/trainer/programs/AddWorkoutToProgramEdit";
import Exercises from "layouts/dashboards/trainer/Exercises";
import Workouts from "layouts/dashboards/trainer/Workouts";
import Programs from "layouts/dashboards/trainer/Programs";
import Products from "layouts/dashboards/trainer/Products";
import AssignProgram from "layouts/dashboards/trainer/programs/AssignProgram";

/* Non-Production Pages */
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import NewProduct from "layouts/pages/ecommerce/products/new-product";
//import EditProduct from "layouts/pages/ecommerce/products/edit-product";
import ProductPage from "layouts/pages/ecommerce/products/product-page";
import OrderList from "layouts/pages/ecommerce/orders/order-list";
import OrderDetails from "layouts/pages/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import Kanban from "layouts/pages/applications/kanban";
import Wizard from "layouts/pages/applications/wizard";
import DataTables from "layouts/pages/applications/data-tables";
import Calendar from "layouts/pages/applications/calendar";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

// Images
import profilePicture from "assets/images/team-3.jpg";


function getAllRoutes(theUser) {
  const routes = [
    /* Non-Menu routes ******* */
    {
      name: "Main",
      key: "main",
      route: "/",
      component: <Main />,
    },
    {
      name: "Sign-Up",
      key: "sign-in-cover",
      route: "/sign-in",
      component: <SignInCover />/*<SignInIllustration />*/,
    },
    {
      name: "Sign-In",
      key: "sign-up-cover",
      route: "/sign-up",
      component: <SignUpCover />,
    },
    {
      name: "Sign-Out",
      key: "sign-out",
      route: "/sign-out",
      component: <SignOut />
    },
    {
      name: "Free Trial",
      key: "free-trial",
      route: "/free-trial",
      component: <FreeTrial />,
    },
    {
      name: "Complete Registration",
      key: "complete-registration",
      route: "/complete-registration",
      component: <CompleteRegistration user={theUser}/>,
      roles: ["user", "Trainer"],
      emailVerificationRequired: false,
      subscriptionRequired: false,
    },
    {/*
    {
      name: "Complete Profile",
      key: "complete-profile",
      route: "/complete-profile",
      component: <CompleteProfile />,
      roles: ["user"],
      emailVerificationRequired: true,
      subscriptionRequired: false,
    },
  */},
    {
      name: "Pricing",
      key: "pricing",
      route: "/pricing",
      component: <Pricing />,
    },
    {
      name: "Features",
      key: "features",
      route: "/features",
      component: <Features />,
    },
    {
      name: "Our Story",
      key: "our-story",
      route: "/our-story",
      component: <OurStory />,
    },
    {
      name: "Contact Us",
      key: "contact-us",
      route: "/contact-us",
      component: <ContactUs />,
    },
    {
      name: "Data Subject Access Request",
      key: "data-subject-access-request",
      route: "/data-subject-access-request",
      component: <DataSubjectAccessRequest />,
    },
    {
      name: "Terms",
      key: "terms",
      route: "/terms",
      component: <TermsAndConditions />,
    },
    {
      name: "Privacy Policy",
      key: "privacy",
      route: "/privacy",
      component: <PrivacyPolicy />,
    },
    {
      name: "Verify Email",
      key: "email-verification",
      route: "/verify-email",
      component: <EmailVerification />,
    },
    {
      name: "Register",
      key: "register",
      route: "/register",
      component: <Register />,
    },
    {
      name: "Forgot Password",
      key: "forgot-password",
      route: "/forgot-password",
      component: <ResetCover />,
    },
    {
      name: "Reset Password",
      key: "reset-password",
      route: "/reset-password",
      component: <ResetPassword />,
    },
    {/*
    {
      name: "Check Your Email",
      key: "check-your-email",
      route: "/check-your-email",
      component: <CheckYourEmail />,
    },
  */},
    {
      name: "Confirm Email",
      key: "confirm-email",
      route: "/confirm-email",
      component: <ConfirmEmail />,
    },
    {
      name: "Subscribe",
      key: "subscribe",
      route: "/subscribe",
      component: <Subscribe user={theUser} />,
    },
    {
      name: "Authenticate",
      key: "authenticate",
      route: "/authenticate",
      component: <Authenticate user={theUser} />,
    },
    {
      name: "Payment Success",
      key: "success",
      route: "/success",
      component: <PaymentSuccess />,
    },
    {
      name: "Payment Canceled",
      key: "canceled",
      route: "/canceled",
      component: <PaymentCanceled />,
    },
    {
      name: "Learn More",
      key: "learn-more",
      route: "/learn-more",
      component: <LearnMore />,
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
  
    /* Menu routes ******* */
  
    /* Profile */
    {/*icon: {/*<MDAvatar src={profilePicture} alt="Brooklyn Alice2" size="sm" />*/},
    {
      type: "collapse",
      name: "Profile",
      key: "profile",
      collapse: [
        {
          name: "Profile",
          key: "profile",
          route: "/account/profile",
          component: <Profile user={theUser} />,
          roles: ["user"],
          emailVerificationRequired: false,
          subscriptionRequired: false,
        },
        {
          name: "Settings",
          key: "settings",
          route: "/account/settings",
          component: <Settings user={theUser}/>,
          roles: ["user"],
          emailVerificationRequired: false,
          subscriptionRequired: false,
        },
      ],
    },
    { type: "divider", key: "divider-0" },
    /* Home */
    {/*
      {
        type: "collapse",
        name: "Home",
        key: "home",
        route: "/home",
        component: <Home />,
        icon: <Icon fontSize="medium">home</Icon>,
        noCollapse: true,
        roles: ["user"],
        emailVerificationRequired: true,
        subscriptionRequired: true,
      },
    */},
    /* Dashboards */
    {
      type: "collapse",
      name: "Trainer Dashboard",
      key: "trainer",
      route: "/dashboards/trainer",
      component: <TrainerDashboard user={theUser} />,
      icon: <Icon fontSize="medium">dashboard_customize</Icon>,
      noCollapse: true,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      type: "collapse",
      name: "Trainee Dashboard",
      key: "trainee",
      route: "/dashboards/trainee",
      component: <TraineeDashboard />,
      icon: <Icon fontSize="medium">dashboard</Icon>,
      noCollapse: true,
      roles: ["user"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {/*
    {
      type: "collapse",
      name: "Dashboards",
      key: "dashboards",
      icon: <Icon fontSize="medium">dashboard</Icon>,
      collapse: [
        {
          name: "Trainer Dashboard",
          key: "trainer",
          route: "/dashboards/trainer",
          component: <TrainerDashboard />,
          roles: ["user", "Trainer"],
          emailVerificationRequired: true,
          subscriptionRequired: true,
        },
        {
          name: "Trainee Dashboard",
          key: "trainee",
          route: "/dashboards/trainee",
          component: <TraineeDashboard />,
          roles: ["user"],
          emailVerificationRequired: true,
          subscriptionRequired: true,
        },
      ],
    },
  */},
    {
      name: "Client",
      key: "client",
      route: "/dashboards/trainer/client/:clientId",
      component: <Client user={theUser} />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Exercise",
      key: "exercise-trainer",
      route: "/dashboards/trainer/exercise/:exerciseId",
      component: <Exercise />,
      roles: ["user"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Exercise",
      key: "exercise-trainee",
      route: "/dashboards/trainee/exercise/:exerciseId",
      component: <ExerciseTrainee />,
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Workout",
      key: "workout",
      route: "/dashboards/trainer/workout/:workoutId",
      component: <Workout />,
      roles: ["user"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Program",
      key: "program",
      route: "/dashboards/trainer/program/:programId",
      component: <Program />,
      roles: ["user"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Product",
      key: "product",
      route: "/dashboards/trainer/product/:productId",
      component: <Product />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Page",
      key: "page",
      route: "/pages/:urlPrefix",
      component: <Page />,
    },
    {
      name: "Pages",
      key:"pages",
      route: "/pages",
      component: <Pages />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Exercise Create",
      key: "exercise-create",
      route: "/dashboards/trainer/exercise-create",
      component: <ExerciseCreate />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Workout Create",
      key: "workout-create",
      route: "/dashboards/trainer/workout-create",
      component: <WorkoutCreate />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Program Create",
      key: "program-create",
      route: "/dashboards/trainer/program-create",
      component: <ProgramCreate />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Product Create",
      key: "product-create",
      route: "/dashboards/trainer/product-create",
      component: <ProductCreate />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Edit Exercise",
      key: "edit-exercise",
      route: "/dashboards/trainer/exercise/:exerciseId/edit",
      component: <EditExercise />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Edit Workout",
      key: "edit-workout",
      route: "/dashboards/trainer/workout/:workoutId/edit",
      component: <EditWorkout />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Edit Program",
      key: "edit-program",
      route: "/dashboards/trainer/program/:programId/edit",
      component: <EditProgram />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Edit Page",
      key: "edit-page",
      route: "/dashboards/trainer/page/edit",
      component: <EditPage user={theUser} />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Edit Product",
      key: "edit-product",
      route: "/dashboards/trainer/product/:productId/edit",
      component: <EditProduct user={theUser} />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Exercises",
      key: "exercise-create",
      route: "/dashboards/trainer/exercises",
      component: <Exercises />,
      roles: ["user"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Workouts",
      key: "workout-create",
      route: "/dashboards/trainer/workouts",
      component: <Workouts />,
      roles: ["user"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Programs",
      key: "programs",
      route: "/dashboards/trainer/programs",
      component: <Programs />,
      roles: ["user"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Products",
      key: "products",
      route: "/dashboards/trainer/products",
      component: <Products />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Add Exercise",
      key: "add-exercise",
      route: "/dashboards/trainer/workout/add-exercise",
      component: <AddExerciseToWorkout />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Add Exercise Edit",
      key: "add-exercise-edit",
      route: "/dashboards/trainer/workout/:workoutId/add-exercise",
      component: <AddExerciseToWorkoutEdit />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Add Workout",
      key: "add-workout",
      route: "/dashboards/trainer/program/add-workout",
      component: <AddWorkoutToProgram />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Add Workout Edit",
      key: "add-workout-edit",
      route: "/dashboards/trainer/program/:programId/add-workout",
      component: <AddWorkoutToProgramEdit />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    {
      name: "Assign Program",
      key: "assign-program",
      route: "/dashboards/trainer/client/:clientId/assign-program",
      component: <AssignProgram />,
      roles: ["user", "Trainer"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    
    /* Analytics */
    {
      type: "collapse",
      name: "Analytics",
      key: "analytics",
      route: "/applications/analytics",
      component: <Analytics />,
      icon: <Icon fontSize="medium">analytics</Icon>,
      noCollapse: true,
      roles: ["user"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    /* Messaging */
    {
      type: "collapse",
      name: "Messages",
      key: "messages",
      route: "/applications/messages",
      component: <Messages />,
      icon: <Icon fontSize="medium">message</Icon>,
      noCollapse: true,
      roles: ["user"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    /* Scheduling */
    {
      type: "collapse",
      name: "Schedule",
      key: "schedule",
      route: "/applications/schedule",
      component: <Scheduling />,
      icon: <Icon fontSize="medium">schedule</Icon>,
      noCollapse: true,
      roles: ["user"],
      emailVerificationRequired: true,
      subscriptionRequired: true,
    },
    
  
    /* Reference Menu (remove in before production) */
    /*
    { type: "divider", key: "divider-1" },
    { type: "divider", key: "divider-2" },
    {
      type: "collapse",
      name: "Dashboards",
      key: "dashboards",
      icon: <Icon fontSize="medium">dashboard</Icon>,
      collapse: [
        {
          name: "Analytics",
          key: "analytics",
          route: "/dashboards/analytics",
          component: <Analytics />,
        },
        {
          name: "Sales",
          key: "sales",
          route: "/dashboards/sales",
          component: <Sales />,
        },
      ],
    },
    */
   /*
    { type: "title", title: "Pages", key: "title-pages" },
    {
      type: "collapse",
      name: "Pages",
      key: "pages",
      icon: <Icon fontSize="medium">image</Icon>,
      collapse: [
        {
          name: "Profile",
          key: "profile",
          collapse: [
            {
              name: "Profile Overview",
              key: "profile-overview",
              route: "/pages/profile/profile-overview",
              component: <ProfileOverview />,
            },
            {
              name: "All Projects",
              key: "all-projects",
              route: "/pages/profile/all-projects",
              component: <AllProjects />,
            },
          ],
        },
        {
          name: "Users",
          key: "users",
          collapse: [
            {
              name: "New User",
              key: "new-user",
              route: "/pages/users/new-user",
              component: <NewUser />,
            },
          ],
        },
        {
          name: "Account",
          key: "account",
          collapse: [
            {
              name: "Settings",
              key: "settings",
              route: "/pages/account/settings",
              component: <Settings />,
            },
            {
              name: "Billing",
              key: "billing",
              route: "/pages/account/billing",
              component: <Billing />,
            },
            {
              name: "Invoice",
              key: "invoice",
              route: "/pages/account/invoice",
              component: <Invoice />,
            },
          ],
        },
        {
          name: "Projects",
          key: "projects",
          collapse: [
            {
              name: "Timeline",
              key: "timeline",
              route: "/pages/projects/timeline",
              component: <Timeline />,
            },
          ],
        },
        {
          name: "Pricing Page",
          key: "pricing-page",
          route: "/pages/pricing-page",
          component: <PricingPage />,
        },
        { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
        { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
        { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
        {
          name: "Notfications",
          key: "notifications",
          route: "/pages/notifications",
          component: <Notifications />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Applications",
      key: "applications",
      icon: <Icon fontSize="medium">apps</Icon>,
      collapse: [
        {
          name: "Kanban",
          key: "kanban",
          route: "/applications/kanban",
          component: <Kanban />,
        },
        {
          name: "Wizard",
          key: "wizard",
          route: "/applications/wizard",
          component: <Wizard />,
        },
        {
          name: "Data Tables",
          key: "data-tables",
          route: "/applications/data-tables",
          component: <DataTables />,
        },
        {
          name: "Calendar",
          key: "calendar",
          route: "/applications/calendar",
          component: <Calendar />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Ecommerce",
      key: "ecommerce",
      icon: <Icon fontSize="medium">shopping_basket</Icon>,
      collapse: [
        {
          name: "Products",
          key: "products",
          collapse: [
            {
              name: "New Product",
              key: "new-product",
              route: "/ecommerce/products/new-product",
              component: <NewProduct />,
            },
            {
              name: "Edit Product",
              key: "edit-product",
              route: "/ecommerce/products/edit-product",
              component: <EditProduct />,
            },
            {
              name: "Product Page",
              key: "product-page",
              route: "/ecommerce/products/product-page",
              component: <ProductPage />,
            },
          ],
        },
        {
          name: "Orders",
          key: "orders",
          collapse: [
            {
              name: "Order List",
              key: "order-list",
              route: "/ecommerce/orders/order-list",
              component: <OrderList />,
            },
            {
              name: "Order Details",
              key: "order-details",
              route: "/ecommerce/orders/order-details",
              component: <OrderDetails />,
            },
          ],
        },
      ],
    },
    {
      type: "collapse",
      name: "Authentication",
      key: "authentication",
      icon: <Icon fontSize="medium">content_paste</Icon>,
      collapse: [
        {
          name: "Sign In",
          key: "sign-in",
          collapse: [
            {
              name: "Basic",
              key: "basic",
              route: "/authentication/sign-in/basic",
              component: <SignInBasic />,
            },
            {
              name: "Cover",
              key: "cover",
              route: "/authentication/sign-in/cover",
              component: <SignInCover />,
            },
            {
              name: "Illustration",
              key: "illustration",
              route: "/authentication/sign-in/illustration",
              component: <SignInIllustration />,
            },
          ],
        },
        {
          name: "Sign Up",
          key: "sign-up",
          collapse: [
            {
              name: "Cover",
              key: "cover",
              route: "/authentication/sign-up/cover",
              component: <SignUpCover />,
            },
          ],
        },
        {
          name: "Reset Password",
          key: "reset-password",
          collapse: [
            {
              name: "Cover",
              key: "cover",
              route: "/authentication/reset-password/cover",
              component: <ResetCover />,
            },
          ],
        },
      ],
    },
    { type: "divider", key: "divider-3" },
    { type: "title", title: "Docs", key: "title-docs" },
    {
      type: "collapse",
      name: "Basic",
      key: "basic",
      icon: <Icon fontSize="medium">upcoming</Icon>,
      collapse: [
        {
          name: "Getting Started",
          key: "getting-started",
          collapse: [
            {
              name: "Overview",
              key: "overview",
              href: "https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/",
            },
            {
              name: "License",
              key: "license",
              href: "https://www.creative-tim.com/learning-lab/react/license/material-dashboard/",
            },
            {
              name: "Quick Start",
              key: "quick-start",
              href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
            },
            {
              name: "Build Tools",
              key: "build-tools",
              href: "https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/",
            },
          ],
        },
        {
          name: "Foundation",
          key: "foundation",
          collapse: [
            {
              name: "Colors",
              key: "colors",
              href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
            },
            {
              name: "Grid",
              key: "grid",
              href: "https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/",
            },
            {
              name: "Typography",
              key: "base-typography",
              href: "https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/",
            },
            {
              name: "Borders",
              key: "borders",
              href: "https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/",
            },
            {
              name: "Box Shadows",
              key: "box-shadows",
              href: "https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/",
            },
            {
              name: "Functions",
              key: "functions",
              href: "https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/",
            },
            {
              name: "Routing System",
              key: "routing-system",
              href: "https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/",
            },
          ],
        },
      ],
    },
    {
      type: "collapse",
      name: "Components",
      key: "components",
      icon: <Icon fontSize="medium">view_in_ar</Icon>,
      collapse: [
        {
          name: "Alerts",
          key: "alerts",
          href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
        },
        {
          name: "Avatar",
          key: "avatar",
          href: "https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/",
        },
        {
          name: "Badge",
          key: "badge",
          href: "https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/",
        },
        {
          name: "Badge Dot",
          key: "badge-dot",
          href: "https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/",
        },
        {
          name: "Box",
          key: "box",
          href: "https://www.creative-tim.com/learning-lab/react/box/material-dashboard/",
        },
        {
          name: "Buttons",
          key: "buttons",
          href: "https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/",
        },
        {
          name: "Date Picker",
          key: "date-picker",
          href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
        },
        {
          name: "Dropzone",
          key: "dropzone",
          href: "https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/",
        },
        {
          name: "Editor",
          key: "editor",
          href: "https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/",
        },
        {
          name: "Input",
          key: "input",
          href: "https://www.creative-tim.com/learning-lab/react/input/material-dashboard/",
        },
        {
          name: "Pagination",
          key: "pagination",
          href: "https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/",
        },
        {
          name: "Progress",
          key: "progress",
          href: "https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/",
        },
        {
          name: "Snackbar",
          key: "snackbar",
          href: "https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/",
        },
        {
          name: "Social Button",
          key: "social-button",
          href: "https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/",
        },
        {
          name: "Typography",
          key: "typography",
          href: "https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/",
        },
      ],
    },
    {
      type: "collapse",
      name: "Change Log",
      key: "changelog",
      href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
      icon: <Icon fontSize="medium">receipt_long</Icon>,
      noCollapse: true,
    },
    */
  ];

  var processedRoutes = [];
  for (var i=0; i<routes.length; i++) {
    var route = routes[i];
    // Dynamically show the user's name on the profile tab
    if (route.key === "profile") {
      if (theUser !== undefined) {
        if (theUser.firstName) {
          route.name = theUser.firstName;
        } else if (theUser.name) {
          route.name = theUser.name;
        }
      } else {
        route.name = "Profile";
      }
      processedRoutes.push(route);
      // Trainer profile should only be visible to trainers
    } else if (route.key === "trainer") {
      if (theUser !== undefined && theUser.accountType === "Trainer") {
        processedRoutes.push(route);
      }
    } else {
      processedRoutes.push(route)
    }
  }
  return processedRoutes;
}

export default getAllRoutes;
