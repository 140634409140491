/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MKTypography from "components/MKTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MKTypographyRoot from "components/MKTypography/MKTypographyRoot";


function LearnMore() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
        <MDBox py={3}>
            <MDBox mb={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <Card
                            sx={{
                                boxShadow: ({ boxShadows: { xxl } }) => xxl,
                            }}
                        >
                            <Grid ml={3} mt={3}>
                                <MKTypography variant="h2">
                                    Accepting Payments from Clients
                                </MKTypography>
                            </Grid>
                            <Grid ml={3} mt={3}>
                                <MKTypography variant="h5" fontWeight="light">
                                    Now that you've been onboarded with Stripe, you're just a few steps away from accepting your first payments from clients.
                                </MKTypography>
                            </Grid>
                             <br></br>
                            <Grid mb={3} ml={3}>

                                <Grid mb={3}>
                                    <Grid mb={1}>
                                        <MKTypography variant="h5" fontWeight="bold">
                                            Step 1. Create a Product
                                        </MKTypography>
                                    </Grid>
                                    <MKTypography variant="h5" fontWeight="light">
                                       Before selling your fitness services online, you'll need to create a 'Product'.  A product determines the scope of the fitness services you'll provide to your client and will also include a name, monthly subscription price, description, and call to action that will be included on your landing page.
                                    </MKTypography>
                                </Grid>

                                <Grid mb={3}>
                                    <Grid mb={1}>
                                        <MKTypography variant="h5" fontWeight="bold">
                                            Step 2. Customize your Landing Page
                                        </MKTypography>
                                    </Grid>
                                    <MKTypography variant="h5" fontWeight="light">
                                        Once you have a product, you'll be able to customize your landing page. You'll choose a unique name that will be included in your landing page link, headings and subheadings, features, and a guarantee to include in your offer for your fitness services.
                                    </MKTypography>
                                </Grid>

                                <Grid mb={3}>
                                    <Grid mb={1}>
                                        <MKTypography variant="h5" fontWeight="bold">
                                            Step 3. Share your Link!
                                        </MKTypography>
                                    </Grid>
                                    <MKTypography variant="h5" fontWeight="light">
                                        Share your landing page link on social media by adding it to your bio, making a post, or share directly with new and existing clients.
                                    </MKTypography>
                                </Grid>

                                <Grid mb={3}>
                                    <Grid mb={1}>
                                        <MKTypography variant="h5" fontWeight="bold">
                                            Step 4. Create Exercises
                                        </MKTypography>
                                    </Grid>
                                    <MKTypography variant="h5" fontWeight="light">
                                        Exercises are the building block of workouts, and workouts are needed to build Programs for your clients. An Exercise consists of a name, muscle group, accessory, description, and optionally a YouTube imbedded link that will enable trainees to view your exercise's associated video.
                                    </MKTypography>
                                </Grid>

                                <Grid mb={3}>
                                    <Grid mb={1}>
                                        <MKTypography variant="h5" fontWeight="bold">
                                            Step 5. Create Workouts
                                        </MKTypography>
                                    </Grid>
                                    <MKTypography variant="h5" fontWeight="light">
                                        After creating some exercises, you're ready to create a workout. Workouts are just a collection of exercises that your client(s) will do on a given day. They will also have a name and optionally an emphasis.
                                    </MKTypography>
                                </Grid>

                                <Grid mb={3}>
                                    <Grid mb={1}>
                                        <MKTypography variant="h5" fontWeight="bold">
                                            Step 6. Build Programs
                                        </MKTypography>
                                    </Grid>
                                    <MKTypography variant="h5" fontWeight="light">
                                        Once you have some daily workouts, you're ready to build a Program. Programs need to be assigned to your client; they dictate which workouts (and exercises) a trainee will do on a given day.
                                    </MKTypography>
                                </Grid>

                                <Grid mb={3}>
                                    <Grid mb={1}>
                                        <MKTypography variant="h5" fontWeight="bold">
                                            Step 7. Assign Programs
                                        </MKTypography>
                                    </Grid>
                                    <MKTypography variant="h5" fontWeight="light">
                                        When clients purchase your subscription through the platform, they will show up in your client list in the trainer dashboard. You can assign them a program after clicking the client followed by the "Assign Program" button on the next page. Just select the program you've created for them from the list of programs you've already created.
                                    </MKTypography>
                                </Grid>
                            </Grid>
                            <Grid ml={3} mb={3}>
                                <MKTypography variant="h5" fontWeight="light">
                                    Congratulations, you're now accepting monthly payments and you're trainees are receiving workouts to do based on the custom program you've assigned!
                                </MKTypography>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LearnMore;
