import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MKBox from "components/MKBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import mongoose from "mongoose";
import CustomModal from "./components/CustomModal";
import { toggleModal } from "./components/CustomModal";

function Workout() {
  const params = useParams();
  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToWorkouts = () => navigate("/dashboards/trainer/workouts");

  const [workoutName, setWorkoutName] = useState();
  const [emphasis, setEmphasis] = useState();
  const navigateToDashboard = () => navigate("/dashboards/trainer");
  const navigateToEditWorkout = () => {
    localStorage.removeItem('workout');
    navigate(`/dashboards/trainer/workout/${params.workoutId}/edit`);
  }
  // const navigateToCreateExercise = () => navigate("/dashboards/trainer/exercise-create");

  const queryString = window.location.href;
  const s = queryString.split("/");
  const workoutId = s[s.length-1];
  var theEmphasis = "";

  const [exerciseTableData, setExerciseTableData] = useState({
    columns: [
      { Header: "name", accessor: "exerciseName", width: "16%"},
      { Header: "muscle group", accessor: "muscleGroup", width: "16%"},
      { Header: "accessory", accessor: "accessory", width: "16%"},
      { Header: "sets", accessor: "sets", width: "16%"},
      { Header: "reps/interval", accessor: "reps", width: "16%"},
      { Header: "rest", accessor: "rest", width: "16%"},
    ],
    rows: [],
  });

  useEffect(() => {
    const abortController = new AbortController();
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");
    if (!(refreshToken === undefined || accessToken === undefined)) {
      const fetchData = async() => await setExercises();
      fetchData().catch(console.error);
    }
    return () => {
      abortController.abort();
    };
  }, [`${process.env.REACT_APP_API_URL}/v1/trainer/workout/${workoutId}`]);
  
  async function setExercises() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/workout/${workoutId}`, {
      refreshToken: refreshToken,
    }, {
      headers: {
        "Authorization": "Bearer " + accessToken,
      }
    })
    .then((response) => {
      for (var i=0; i<response.data.length; i++) {
        // append any new information we want to view or otherwise need from the exercise
        response.data[i].link = `/dashboards/trainer/exercise/${response.data[i].id}`;
        response.data[i].disabledSetsAndReps = true;
        
        if (i == 0) {
          var theWorkoutName = response.data[i].workoutName;
          theEmphasis = response.data[i].emphasis;
          setWorkoutName(theWorkoutName);
          setEmphasis(theEmphasis);
          // remove the empty exercise from view
          if (response.data[i].id == null) {
            response.data = [];
          }
        }
      }
      setExerciseTableData({
        "columns": [
          { Header: "name", accessor: "exerciseName", width: "16%"},
          { Header: "muscle group", accessor: "muscleGroup", width: "16%"},
          { Header: "accessory", accessor: "accessory", width: "16%"},
          { Header: "sets", accessor: "sets", width: "16%"},
          { Header: "reps/interval", accessor: "reps", width: "16%"},
          { Header: "rest", accessor: "rest", width: "16%"},
        ],
        "rows": response.data,
      });
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
  }

  const pressDeleteWorkout = async () => {
    toggleModal();
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <MKBox component="section">
          <Container>
            <CustomModal
              message="This workout will also be deleted from all programs which use it. Are you sure you want to delete this workout?"
              buttonColor="error"
              buttonText="delete"
              buttonFunction="deleteWorkout"
              redirect="/dashboards/trainer/workouts"
              toDelete={workoutId}
            />
          </Container>
        </MKBox>
        <Grid mt={6}>
            <MDBox pb={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {workoutName}
              </MDTypography>
              <MDTypography variant="button" color="text">
                {emphasis}
              </MDTypography>
            </MDBox>
            <Grid pb={1}>
              {/*
              <MDButton
                variant="gradient"
                color="secondary"
                size="small"
                onClick={navigateBack}
                pr={3}
              >
                Back
              </MDButton>
              &nbsp;
              */}
              <MDButton
                variant="gradient"
                color="secondary"
                size="small"
                onClick={navigateBack}
                pr={3}
              >
                Back
              </MDButton>
              &nbsp;
              <MDButton
                onClick={navigateToEditWorkout}
                variant="gradient"
                color="warning"
                size="small"
              >
                edit
              </MDButton>
              &nbsp;
              <MDButton
                onClick={pressDeleteWorkout}
                variant="gradient"
                color="error"
                size="small"
              >
                delete
              </MDButton>
            </Grid>
          <DataTable table={exerciseTableData} />
        </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Workout;
