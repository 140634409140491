
// Pricing page components
import Header from "layouts/main/components/Header";
import FooterThree from "layouts/main/components/FooterThree";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ListItem from "@mui/material/ListItem";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Policy from "./Policy";

// Routes
import routes from "routes";
import footerRoutes from "../footer.routes";
import MKTypographyRoot from "components/MKTypography/MKTypographyRoot";

function PrivacyPolicy() {
  return (
    <PageLayout>
      <Header />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
        <MKBox pb={6} px={6}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Privacy Policy
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: Dec 22 2023
                  </MKTypography>
                </MKBox>
                <MKBox py={6} px={6}>
                  This privacy notice for Fit to Scale, LLC (doing business as Fit to Scale) ("Fit to Scale," we." us, " or our"), describes how and why we might
collect, store, use, and/or share ("process") your information when you use our services (Services"), such as when you:<br></br>

<ListItem sx={{ display: 'list-item' }}>Visit our website at fittoscale.io, or an website of ours that links to this privacy notice</ListItem>

<ListItem sx={{ display: 'list-item' }}>Engage with us in other related ways, including any sales, marketing, or events</ListItem>


<MKTypography variant="h5" my={3}>Questions or concerns? <MKTypography>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies
and practices, please do not use our Services. If you still have any questions or concerns, please contact us at service@fittoscale.io.</MKTypography></MKTypography>


                  <MKTypography variant="h4" mt={6} mb={3}>SUMMARY OF KEY POINTS</MKTypography>
                  
<MKTypography>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the
link following each key point or by using our table of contents below to find the section you are looking for. You can also click <a href="#table-of-contents">here</a> to go
directly to our table of contents.</MKTypography><br></br>

<MKTypography variant="h5">What personal information do we process?</MKTypography><MKTypography >When you visit, use, or navigate our Services, we may process personal information depending on
how you interact with Fit to Scale and the Services, the choices you make, and the products and features you use. Click <a href="#tc-1">here</a> to learn more.
</MKTypography>
<br></br>
<MKTypography variant="h5">Do we process any sensitive personal information?</MKTypography><MKTypography>We may process sensitive personal information when necessary with your consent or as
otherwise permitted by applicable law. Click <a href="#sensitive-pi">here</a> to learn more.</MKTypography>
<br></br>
<MKTypography variant="h5">Do we receive any Information from third parties?</MKTypography><MKTypography> We do not receive any information from third parties.</MKTypography>
<br></br>
<MKTypography variant="h5">How do we process your information?</MKTypography><MKTypography>We process your information to provide, Improve, and administer our Services, communicate with you, for
security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your
Information only when we have a valid legal reason to do so. Click <a href="#tc-2">here</a> to learn more.</MKTypography>
<br></br>
<MKTypography variant="h5">In what situations and with which parties do we share personal Information?</MKTypography><MKTypography>We may share information in specific situations and with specific
third parties. Click <a href="#tc-3">here</a> to learn more.</MKTypography>
<br></br><MKTypography variant="h5">How do we keep your information safe?</MKTypography><MKTypography>We have organizational and technical processes and procedures in place to protect your personal
Information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we
cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly
collect, access, steal, or modify your information. Click <a href="#tc-5">here</a> to learn more.</MKTypography>
<br></br>
<MKTypography variant="h5">What are your rights?</MKTypography><MKTypography>Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding
your personal information. Click <a href="#tc-7">here</a> to learn more.</MKTypography>
<br></br>
<MKTypography variant="h5">How do you exercise your rights?</MKTypography><MKTypography>The easiest way to exercise your rights is by filling out our data subject request form available <a href="data-subject-access-request">here</a>, or by
contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
<br></br>
<br></br>
Want to learn more about what Fit to Scale does with any information we collect? Click <a href="#table-of-contents">here</a> to review the notice in full.</MKTypography>

<MKTypography id="table-of-contents" variant="h4" mt={6} mb={3}>TABLE OF CONTENTS</MKTypography>
<MKTypography sx={{textDecoration: 'underline'}} variant="h5"><a href="#tc-1">1. WHAT INFORMATION DO WE COLLECT?</a></MKTypography>
<MKTypography sx={{textDecoration: 'underline'}} variant="h5"><a href="#tc-2">2. HOW DO WE PROCESS YOUR INFORMATION?</a></MKTypography>
<MKTypography sx={{textDecoration: 'underline'}} variant="h5"><a href="#tc-3">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></MKTypography>
<MKTypography sx={{textDecoration: 'underline'}} variant="h5"><a href="#tc-4">4. HOW LONG DO WE KEEP YOUR INFORMATION?</a></MKTypography>
<MKTypography sx={{textDecoration: 'underline'}} variant="h5"><a href="#tc-5">5. HOW DO WE KEEP YOUR INFORMATION SAFE?</a></MKTypography>
<MKTypography sx={{textDecoration: 'underline'}} variant="h5"><a href="#tc-6">6. DO WE COLLECT INFORMATION FROM MINORS?</a></MKTypography>
<MKTypography sx={{textDecoration: 'underline'}} variant="h5"><a href="#tc-7">7. WHAT ARE YOUR PRIVACY RIGHTS?</a></MKTypography>
<MKTypography sx={{textDecoration: 'underline'}} variant="h5"><a href="#tc-8">8. CONTROLS FOR DO-NOT-TRACK FEATURES</a></MKTypography>
<MKTypography sx={{textDecoration: 'underline'}} variant="h5"><a href="#tc-9">9. DO CALIFORNIA RESIDENTS HAVER SPECIFIC PRIVACY RIGHTS?</a></MKTypography>
<MKTypography sx={{textDecoration: 'underline'}} variant="h5"><a href="#tc-10">10. DO VIRGINIA RESIDENTS HAVER SPECIFIC PRIVACY RIGHTS?</a></MKTypography>
<MKTypography sx={{textDecoration: 'underline'}} variant="h5"><a href="#tc-11">11. DO WE MAKE UPDATES TO THIS NOTICE?</a></MKTypography>
<MKTypography sx={{textDecoration: 'underline'}} variant="h5"><a href="#tc-12">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></MKTypography>
<MKTypography sx={{textDecoration: 'underline'}} variant="h5"><a href="#tc-13">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></MKTypography>

<br></br>
<MKTypography id="tc-1" variant="h4" mt={6} mb={3}>1. WHAT INFORMATION DO WE COLLECT?</MKTypography>

<MKTypography variant="h5" mt={6} mb={3}>Personal information you disclose to us</MKTypography>
<MKTypography mb={3}>In Short: We collect personal information that you provide to us.</MKTypography>
<MKTypography mb={3}>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products Services, when you participate in activities on the Services, or otherwise when you contact us.</MKTypography>
<MKTypography>The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</MKTypography>
<ListItem sx={{ display: 'list-item' }}>names</ListItem>
<ListItem sx={{ display: 'list-item' }}>phone numbers</ListItem>
<ListItem sx={{ display: 'list-item' }}>email addresses</ListItem>
<ListItem sx={{ display: 'list-item' }}>passwords</ListItem>
<ListItem sx={{ display: 'list-item' }}>contact or authentication data</ListItem>
<ListItem sx={{ display: 'list-item' }}>contact preferences</ListItem>

<MKTypography id="sensitive-pi" variant="h5" mt={6} mb={3}>Sensitive Information</MKTypography>
<MKTypography>When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:</MKTypography>
<ListItem sx={{ display: 'list-item' }}>health data</ListItem>

<MKTypography variant="h5" mt={6} mb={3}>Payment Data</MKTypography>
<MKTypography mb={3}>We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find their privacy notice link(s) here: <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.</MKTypography>
<MKTypography>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</MKTypography>

<MKTypography variant="h5" mt={6} mb={3}>Information automatically collected</MKTypography>
<MKTypography mb={3}>In Short: Some information - such as your Internet Protocol (IP) address and/or browser and device characteristics -- is collected automatically when you visit our Services.</MKTypography>
<MKTypography mb={3}>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</MKTypography>
<MKTypography mb={3}>The information we collect includes:</MKTypography>
<ListItem sx={{ display: 'list-item' }}>Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (somtimes called "crash dumps"), and hardware settings).</ListItem>


<MKTypography id="tc-2" variant="h4" mt={6} mb={3}>2. HOW DO WE PROCESS YOUR INFORMATION?</MKTypography>
<MKTypography mb={3}>In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</MKTypography>

<MKTypography mb={3}>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</MKTypography>
<ListItem sx={{ display: 'list-item' }}>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</ListItem>
<ListItem sx={{ display: 'list-item' }}>To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</ListItem>
<ListItem sx={{ display: 'list-item' }}>To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</ListItem>
<ListItem sx={{ display: 'list-item' }}>To enable user-to-user communications. We may process your information if you choose to use any of our offerings that allow for communication with another user.</ListItem>
<ListItem sx={{ display: 'list-item' }}>To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.</ListItem>
<ListItem sx={{ display: 'list-item' }}>To post testimonials. We post testimonials on our Services that may contain personal information.</ListItem>
<ListItem sx={{ display: 'list-item' }}>To evaluate and improve our Services, products, marketing, and your experience. We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.</ListItem>
<ListItem sx={{ display: 'list-item' }}>To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them.</ListItem>


<MKTypography id="tc-3" variant="h4" mt={6} mb={3}>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</MKTypography>

<MKTypography mb={3}>In Short: We may share information in specific situations described in this section and/or with the following third parties.</MKTypography>
<MKTypography mb={3}>We may need to share your personsal information in the following situations:</MKTypography>
<ListItem sx={{ display: 'list-item' }}>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</ListItem>
<ListItem sx={{ display: 'list-item' }}>Other Users. When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</ListItem>


<MKTypography id="tc-4" variant="h4" mt={6} mb={3}>4. HOW LONG DO WE KEEP YOUR INFORMATION?</MKTypography>

<MKTypography mb={3}>In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</MKTypography>

<MKTypography mb={3}>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</MKTypography>
<MKTypography mb={3}>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</MKTypography>

<MKTypography id="tc-5" variant="h4" mt={6} mb={3}>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</MKTypography>
<MKTypography mb={3}>In Short: We aim to protect your personal information through a system of organizational and technical security measures.</MKTypography>
<MKTypography mb={3}>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information transmission of personal information to and form our Services is at your own risk. You should only access the Services within a secure environment.</MKTypography>

<MKTypography id="tc-6" variant="h4" mt={6} mb={3}>6. DO WE COLLECT INFORMATION FROM MINORS?</MKTypography>
<MKTypography mb={3}>In Short: We do not knowingly collect data from or market to children under 18 years of age.</MKTypography>
<MKTypography mb={3}>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promtly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at service@fittoscale.io.</MKTypography>

<MKTypography id="tc-7" variant="h4" mt={6} mb={3}>7. WHAT ARE YOUR PRIVACY RIGHTS?</MKTypography>
<MKTypography mb={3}>In Short: You may review, change or terminate your account at any time.</MKTypography>
<MKTypography mb={3}>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="https://ec.europa.eu/newsroom/article29/items/612080/en">https://ec.europo.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</MKTypography>
<MKTypography mb={3}>If you are located in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</MKTypography>
<MKTypography mb={3}>Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable alaw, you have the right to withdraw your consent at any time. You can withdraw your consent at anytime by contacting us by using the contact details provided in the section "<a href="#tc-12">HOW CAN YOU CONTACT US ABOUT THIS NOTICE</a>?" below.</MKTypography>
<MKTypography mb={3}>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, whenapplicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</MKTypography>
<MKTypography variant="h5" mt={6} mb={3}>Account Information</MKTypography>
<MKTypography mb={3}>If you would at any time like to review or change the information in your account or terminate your account, you can:</MKTypography>
<ListItem sx={{ display: 'list-item' }}>Log in to your account settings and update your user account</ListItem>
<ListItem sx={{ display: 'list-item' }}>Contact us using the contact information provided.</ListItem>
<MKTypography my={3}>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</MKTypography>
<MKTypography mb={3}>If you have questions or comments about your privacy rights, you may email us at service@fittoscale.io.</MKTypography>

<MKTypography id="tc-8" variant="h4" mt={6} mb={3}>8. CONTROLS FOR DO-NOT-TRACK FEATURES</MKTypography>
<MKTypography mb={3}>Most web browsers and some operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preferences not to have data about your online browsing activities monitored and collected. At this stage no uniiform technology standard for recognizing and implmeenting DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</MKTypography>

<MKTypography id="tc-9" variant="h4" mt={6} mb={3}>9. DO CALIFORNIA RESIDENTS HAVER SPECIFIC PRIVACY RIGHTS?</MKTypography>
<MKTypography mb={3}>In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</MKTypography>
<MKTypography mb={3}>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</MKTypography>
<MKTypography mb={3}>If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and inlcude the email address associated with your account and a statement that you reside in California. We will make sure the data is notpublicly displayed on the Services, but please be aware that the data may not be copmletely or comprehensively removed fromall our systems (e.g., backups, etc.).</MKTypography>
<MKTypography variant="h5" mt={6} mb={3}>CCPA Privacy Notice</MKTypography>
<MKTypography mb={3}>The California Code of Regulations defines a "resident" as:</MKTypography>
<ListItem sx={{ display: 'list-item' }}>1. every individual who is in the State of California for other than a temporary or transitory purpose and</ListItem>
<ListItem sx={{ display: 'list-item' }}>2. every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</ListItem>
<MKTypography mb={3}>All other individuals are defined as "non-residents".</MKTypography>
<MKTypography mb={3}>If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.</MKTypography>
<MKTypography variant="h5" mt={6} mb={3}>What categories of personal information do we collect?</MKTypography>
<MKTypography mb={3}>We have collected the following categories of personal information in the past twelve (12) months:</MKTypography>

<table>
    <tr>
        <th>Category</th>
        <th>Examples</th>
        <th>Collected</th>
    </tr>
    <tr>
        <th>A. Identifiers</th>
        <th>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</th>
        <th>YES</th>
    </tr>
    <tr>
        <th>B. Personal information categories listed in the California Customer Records statute</th>
        <th>Name, contact information, education, employment, employment history, and financial information</th>
        <th>YES</th>
    </tr>
    <tr>
      <th>C. Protected classification characteristics under California or federal law</th>
      <th>Gender and date of birth</th>
      <th>YES</th>
    </tr>
    <tr>
      <th>D. Commercial information</th>
      <th>Transaction information, purchase history, financial details, and payment information</th>
      <th>NO</th>
    </tr>
    <tr>
      <th>E. Biometric information</th>
      <th>Fingerprints and voiceprints</th>
      <th>NO</th>
    </tr>
    <tr>
      <th>F. Internet or other similar network activity</th>
      <th>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</th>
      <th>NO</th>
    </tr>
    <tr>
      <th>G. Geolocation data</th>
      <th>Device location</th>
      <th>NO</th>
    </tr>
    <tr>
      <th>H. Audio, electronic, visual, thermal, olfactory, or similar information</th>
      <th> Images and audio, video or call recordings created in connection with our business activities</th>
      <th>NO</th>
    </tr>
    <tr>
      <th>I. Professional or employment-related information</th>
      <th>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for ajob with us</th>
      <th>NO</th>
    </tr>
    <tr>
      <th>J. Education Information</th>
      <th>Student records and directory information</th>
      <th>NO</th>
    </tr>
    <tr>
      <th>K. Inferences drawn from other personal information</th>
      <th>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual's preferences and characteristics</th>
      <th>NO</th>
    </tr>
    <tr>
      <th>L. Sensitivie Personal Information</th>
      <th>Account login information and health data</th>
      <th>YES</th>
    </tr>
</table>
<MKTypography mt={3}>We will use and retain the collected personal information as needed to provide the Services or for:</MKTypography>
<ListItem sx={{ display: 'list-item' }}>Category A - As long as the user has an account with us</ListItem>
<ListItem sx={{ display: 'list-item' }}>Category B - As long as the user has an account with us</ListItem>
<ListItem sx={{ display: 'list-item' }}>Category C - As long as the user has an account with us</ListItem>
<ListItem sx={{ display: 'list-item' }}>Category L - As long as the user has an account with us</ListItem>
<MKTypography mb={3}>Category L information may be used, or disclosed to a service provider or contractor, for additional, specified purposes. You have the right to limit the use or disclosure of your sensitive personal information.</MKTypography>
<MKTypography>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</MKTypography>
<ListItem sx={{ display: 'list-item' }}>Receiving help through our customer support channels;</ListItem>
<ListItem sx={{ display: 'list-item'}}>Participation in customer surveys or contests; and</ListItem>
<ListItem sx={{ display: 'list-item'}}>Facilitation in the delivery of our Services and to respond to your inquiries.</ListItem>
<MKTypography variant="h5" mt={6} mb={3}>How do we use and share your personal information?</MKTypography>
<MKTypography mb={3}>More information about our data collection and sharing practices can be found in this privacy notice.</MKTypography>
<MKTypography mb={3}>You may contact us by email at service@fittoscale.io, or by referring to the contact details at the bottom of this document.</MKTypography>
<MKTypography mb={3}>If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</MKTypography>
<MKTypography variant="h5" mt={6} mb={3}>Will your information be shared with anyone else?</MKTypography>
<MKTypography mb={3}>We may disclose your personal information with our service providers puruant to a written contract between us and each service provider. Each service provider isa for-profit entity that processes the information on our behalf, following the same strict privacy protection obligations mandated by the CCPA.</MKTypography>
<MKTypography mb={3}>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.</MKTypography>
<MKTypography mb={3}>Fit to Scale, LLC has not disclosed, sold, or shared any personal information to third parties for business or commercial purpose in the preceding twelve (12) months. Fit to Scale, LLC will not sell or share personal information in the future belonging to website visitors, users, and other consumers.</MKTypography>
<MKTypography variant="h5" mt={6} mb={3}>Your rights with respect to your personal data</MKTypography>
<MKTypography mb={3} sx={{textDecoration: 'underline'}}>Right to request deletion of the data -- Request to delete</MKTypography>
<MKTypography mb={3}>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</MKTypography>
<MKTypography mb={3} sx={{textDecoration: 'underline'}}>Right to be informed -- Request to know</MKTypography>
<MKTypography>Depending on the circumstances, you have a right to know:</MKTypography>
<ListItem sx={{ display: 'list-item' }}>whether we collect and use your personal information;</ListItem>
<ListItem sx={{ display: 'list-item' }}>the categories of personal information that we collect;</ListItem>
<ListItem sx={{ display: 'list-item' }}>the purposes for which the collected personal information is used;</ListItem>
<ListItem sx={{ display: 'list-item' }}>whether we sell or share personal information to third parties;</ListItem>
<ListItem sx={{ display: 'list-item' }}>the categories of personal information that we sold, shared, or disclosed for a business purpose;</ListItem>
<ListItem sx={{ display: 'list-item' }}>the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;</ListItem>
<ListItem sx={{ display: 'list-item' }}>the business or commercial purpose for collecting, selling, or sharing personal information; and</ListItem>
<ListItem sx={{ display: 'list-item' }}>the specific pieces of personal information we collected about you.</ListItem>
<MKTypography mb={3}>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</MKTypography>
<MKTypography mb={3} sx={{textDecoration: 'underline'}}>Right to Non-Discrimination for the Exercise of a Consumer's Privacy Rights</MKTypography>
<MKTypography mb={3}>We will not discriminate against you if you exercise your privacy rights.</MKTypography>
<MKTypography mb={3} sx={{textDecoration: 'underline'}}>Right to Limit Use and Disclosure of Sensitive Personal Information</MKTypography>
<MKTypography>If the business collects any of the following:</MKTypography>
<ListItem sx={{ display: 'list-item' }}>social security information, drivers' licenses, state ID cards, passport numbers</ListItem>
<ListItem sx={{ display: 'list-item' }}>account login information</ListItem>
<ListItem sx={{ display: 'list-item' }}>credit card numbers, financial account information, or credentials allowing access to such accounts</ListItem>
<ListItem sx={{ display: 'list-item' }}>precise geolocation</ListItem>
<ListItem sx={{ display: 'list-item' }}>racial or ethnic origin, religious or philosophical beliefs, union membership</ListItem>
<ListItem sx={{ display: 'list-item' }}>the contents of email and text, unless the business is the intended recipient of the communication</ListItem>
<ListItem sx={{ display: 'list-item' }}>genetic data, biometric data, and health data</ListItem>
<ListItem sx={{ display: 'list-item' }}>data concerning sexual orientation and sex life</ListItem>
<MKTypography mb={3}>you have the right to direct that business to limit its use of your sensitive personal information to that use which is necessary to perform the Services.</MKTypography>
<MKTypography mb={3}>Once a business receives your request, they are no longer allowed to use or disclose your sensitive personal information for any other purpose unless you provide consent for the use or disclosure of sensitive personal information for additional purposes.</MKTypography>
<MKTypography mb={3}>Please note that sensitive personal information that is collected or processed without the purpose of inferring characteristics about a consumer is not covered by this right, as well as the publicly available information.</MKTypography>
<MKTypography mb={3}>To exercise your right to limit use and disclosure of sensitive personal information, please email service@fittoscale.io or submit a request form by clicking <a href="data-subject-access-request">here</a>.</MKTypography>
<MKTypography mb={3} sx={{textDecoration: 'underline'}}>Verification Process</MKTypography>
<MKTypography mb={3}>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already haver on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</MKTypography>
<MKTypography mb={3}>We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already mainted by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</MKTypography>
<MKTypography mb={3} sx={{textDecoration: 'underline'}}>Other privacy rights</MKTypography>
<ListItem sx={{ display: 'list-item' }}>You may object to the processing of your personal information</ListItem>
<ListItem sx={{ display: 'list-item' }}>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</ListItem>
<ListItem sx={{ display: 'list-item' }}>You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.</ListItem>
<ListItem sx={{ display: 'list-item' }}>You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the data of the request submission.</ListItem>
<MKTypography my={3}>To exercise these right, you can contact us by email at service@fittoscale.io, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.</MKTypography>

<MKTypography id="tc-10" variant="h4" mt={6} mb={3}>10. DO VIRGINIA RESIDENTS HAVER SPECIFIC PRIVACY RIGHTS?</MKTypography>
<MKTypography mb={3}>In Short: Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to and use of your personal information.</MKTypography>
<MKTypography variant="h5" mt={6} mb={3}>Virginia CDPA Privacy Notice</MKTypography>
<MKTypography mb={3}>Under the Virginia Consumer Data Protection Act (CDPA):</MKTypography>
<MKTypography mb={3}>"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.</MKTypography>
<MKTypography mb={3}>"Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal data" does not include de-identified data or publicly available information.</MKTypography>
<MKTypography mb={3}>"Sale of personal data" means the exchange of personal data for monetary consideration.</MKTypography>
<MKTypography mb={3}>If this definition "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.</MKTypography>
<MKTypography>The information we collect, use, and disclose about you will vary depending on how you interact with Fit to Scale, LLC and our Services. To find out more, please visit the following links:</MKTypography>
<ListItem sx={{ display: 'list-item' }}><a href="#tc-1">Personal data we collect</a></ListItem>
<ListItem sx={{ display: 'list-item' }}><a href="#tc-2">How we use your personal data</a></ListItem>
<ListItem sx={{ display: 'list-item' }}><a href="#tc-3">When and with whom we share your personal data</a></ListItem>
<MKTypography my={3} sx={{textDecoration: 'underline'}}>Your rights with respect to your personal data</MKTypography>
<ListItem sx={{ display: 'list-item' }}>Right to be informed whether or not we are processing your personal data</ListItem>
<ListItem sx={{ display: 'list-item' }}>Right to access your personal data</ListItem>
<ListItem sx={{ display: 'list-item' }}>Right to correct inaccuracies in your personal data</ListItem>
<ListItem sx={{ display: 'list-item' }}>Right to request deletion of your personal data</ListItem>
<ListItem sx={{ display: 'list-item' }}>Right to obtain a copy of the personal data you previously shared with us</ListItem>
<ListItem sx={{ display: 'list-item' }}>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</ListItem>
<MKTypography mb={3}>Fit to Scale, LLC has not sold any personal data to third parties for business or commercial purposes. Fit to Scale, LLC will not sell personal data in the future belonging to website visitors, users, and other consumers.</MKTypography>
<MKTypography mb={3} sx={{textDecoration: 'underline'}}>Exercise your rights provided under the Virginia CDPA</MKTypography>
<MKTypography mb={3}>More information about our data collection and sharing practices can be found in this privacy notice.</MKTypography>
<MKTypography mb={3}>You may contact us by email at service@fittoscale.io, by visiting our <a href="data-subject-access-request">data subject request form</a>, or by referring to the contact details at the bottom of this document.</MKTypography>
<MKTypography mb={3}>If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</MKTypography>
<MKTypography mb={3} sx={{textDecoration: 'underline'}}>Verification process</MKTypography>
<MKTypography mb={3}>We may request that you provide additional information reasonably necessary to verify you and your consumer's requeset. If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request.</MKTypography>
<MKTypography mb={3}>Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.</MKTypography>
<MKTypography mb={3} sx={{textDecoration: 'underline'}}>Right to appeal</MKTypography>
<MKTypography mb={3}>If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our dicision, please email us at service@fittoscale.io. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal if denied, you may contact the Attorney General to <a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint">submit a complaint</a>.</MKTypography>

<MKTypography id="tc-11" variant="h4" mt={6} mb={3}>11. DO WE MAKE UPDATES TO THIS NOTICE?</MKTypography>
<MKTypography mb={3}>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</MKTypography>
<MKTypography mb={3}>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</MKTypography>

<MKTypography id="tc-12" variant="h4" mt={6} mb={3}>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</MKTypography>
<MKTypography mb={3}>If you have questions or comments about this notice, you may email us at service@fittoscale.io or by post to:</MKTypography>
<MKTypography>Fit to Scale, LLC</MKTypography>
<MKTypography>400 S 4th St Ste 410 PMB 404522</MKTypography>
<MKTypography>Minneapolis, MN 55415</MKTypography>
<MKTypography>United States</MKTypography>

<MKTypography id="tc-13" variant="h4" mt={6} mb={3}>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</MKTypography>
<MKTypography mb={3}>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please fill out and submit a <a href="data-subject-access-request">data subject access request</a>.</MKTypography>



                </MKBox>

                {/*
                <div name="termly-embed" data-id="374d1ca8-1c17-4ec9-8884-0b62d31cd3c7" data-type="iframe"></div>
                <script type="text/javascript">(function(d, s, id) {
                  var js, tjs = d.getElementsByTagName(s)[0];
                  if (d.getElementById(id)) return;
                  js = d.createElement(s); js.id = id;
                  js.src = "https://app.termly.io/embed-policy.min.js";
                  tjs.parentNode.insertBefore(js, tjs);
                  }(document, 'script', 'termly-jssdk'));
                </script>
                */}
                
                
                {/*<Policy></Policy>*/}
              </Card>
            </Grid>
          </Grid>
          </MKBox>
        </Container>
      </MKBox>
      <FooterThree />
    </PageLayout>
  );
}

export default PrivacyPolicy;
