import axios from "axios";
import { useState } from "react";
import { Navigate } from "react-router-dom";

async function verify(verifyEmailToken, accessToken) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/verify-email`, {
      verifyEmailToken,
    },{
      headers: {
          Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch(error) {
    // should redirect to a send verifcation email page instead
    console.log(error);
    document.location.href = `${process.env.REACT_APP_FRONT_END_URL}/confirm-email`;
    return error;
  }
}

async function EmailVerification() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const verifyEmailToken = urlParams.get("token");
  const urlPrefix = urlParams.get("urlPrefix");
  const productId = urlParams.get("productId");

  const priceId = undefined//urlParams.get("priceId");
  const accessToken = localStorage.getItem("access_token");
  const response = await verify(verifyEmailToken, accessToken);
  let user;
  await axios.post(`${process.env.REACT_APP_API_URL}/v1/users/getUserByToken`, {
    refreshToken: verifyEmailToken,
  }, {
      headers: {
          Authorization: `Bearer ${accessToken}`,
      },
  }).then((response) => {
    user = response.data;
    // TODO: delete the users verify email tokens
  }).catch((err) => console.log(err));

  if (response.status === 204) {
    if (urlPrefix && productId) {
      // priceId is undefined for now, this results in us using the default price
      if (user?.email) {
        await axios.post(
           `${process.env.REACT_APP_API_URL}/v1/stripe/create-checkout-session/${urlPrefix}/${productId}/${user.email}`,
            // We are using the default priceId when not specifying a price
            {
            // priceID: priceID,
            }
          ).then(response => {
            window.location.replace(response.data.checkoutSession.url);
          }).catch(err => {
            // TODO (ereilly89): handle stripe error
            console.log(err);
          }
        ).then((response) => {})
        .catch((err) => console.log(err));
      }
    } else {
      document.location.href = `${process.env.REACT_APP_FRONT_END_URL}/dashboards/trainer`;
    }
  }
}

export default EmailVerification;
