/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import axios from "axios";

// Validation
import validator from "validator";
import { NavigateNextTwoTone } from "@mui/icons-material";

async function resetPassword(email) {
  try {
    if (email === undefined || email.trim().length === 0) {
      return { type: "email", message: "please enter an email address" };
    }
    if (!validator.isEmail(email)) {
      return { type: "email", message: "please enter a valid email address" };
    }
  } catch (error) {
    return {
      status: 401,
      message: "There was an error with the request.",
    };
  }
  try {
    const request = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/forgot-password`, {
      email: email.trim(),
    });
    return request;

  } catch (err) {
    return err;
  }
}

function Cover() {
  /* Values */
  const [email, setEmail] = useState();
  /* Validation */
  const [validEmail, setValidEmail] = useState(true);
  /* Text */
  const [emailHelpText, setEmailHelpText] = useState();
  /* Navigation */
  const navigate = useNavigate();

  const [show, setShow] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalButtonColor, setModalButtonColor] = useState("error");
  const [modalButtonText, setModalButtonText] = useState("delete");
  const [modalTitle, setModalTitle] = useState();
  const toggleModal = () => setShow(!show);

  const navigateToDashboard = async () => {
    navigate("/account/settings")
  }

  const handleKeyPress = (event) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
      handleSubmit(event)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await resetPassword(email);
    if (response && response.type && response.message) {
      if (response.type === "email") {
        setValidEmail(false);
        setEmailHelpText(response.message);
      }
      return;
    }
    if (response.status === 204) {
      setModalTitle("Email Sent")
      setModalMessage("Please check your email inbox for a link to change your password.");
      setModalButtonColor("info");
      setModalButtonText("Okay");
      toggleModal();
    } else {
      setModalTitle("Error");
      setModalMessage("There was an error processing your request. Please try again later.");
      setModalButtonColor("error");
      setModalButtonText("Okay");
      toggleModal();
    }
  };

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
       <MKBox component="section">
        <Container>
          <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={show} timeout={500}>
              <MKBox
                position="relative"
                width="500px"
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
              >
                <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                  <MKTypography variant="h5">{modalTitle}</MKTypography>
                  <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox p={2}>
                  <MKTypography variant="body2" color="secondary" fontWeight="regular">
                    {modalMessage}
                  </MKTypography>
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                  <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                    close
                  </MKButton>
                  <MKButton variant="gradient" color={modalButtonColor} onClick={navigateToDashboard}>
                    {modalButtonText}
                  </MKButton>
                </MKBox>
              </MKBox>
            </Slide>
          </Modal>
        </Container>
      </MKBox>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                error={!validEmail}
                helperText={emailHelpText}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (email && validator.isEmail(email)) {
                    if (validEmail !== undefined) {
                      setValidEmail(true);
                      setEmailHelpText("");
                    }
                  } else if (email) {
                    if (validEmail) {
                      setValidEmail(false);
                    }
                  }
                }}
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                reset
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
