import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { useMaterialUIController } from "context";
import axios from "axios";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";
import { set } from "mongoose";

function ContactUsOne() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [snackbarText, setSnackbarText] = useState();
  const [show, setShow] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState();
  const toggleSnackbar = () => setShow(!show);
  const hideSnackbar = () => setShow(false);
  
  const [fullNameValue, setFullNameValue] = useState();
  const [emailValue, setEmailValue] = useState();
  const [textValue, setTextValue] = useState();

  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [text, setText] = useState();
  const [validFullName, setValidFullName] = useState();
  const [validEmail, setValidEmail] = useState();
  const [validText, setValidText] = useState();

  const errorStyle = ({
    palette: { error },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: error.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
    },
  });

  const successStyle = ({
    palette: { success },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: success.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
    },
  });

  const toastTemplate = (
    <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
      {snackbarText}
      <CloseIcon
        fontSize="medium"
        sx={{ ml: 4, mr: -1, cursor: "pointer" }}
        onClick={toggleSnackbar}
      />
    </MKBox>
  );

  const sendContactUsEmail = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/send-contact-us-email`, {
            fullName: fullName,
            email: email,
            text: text,
        });
        if (response.status == 200) {
            setShow(true);
            setFullName("");
            setFullNameValue("");
            setEmail("");
            setEmailValue("");
            setText("");
            setTextValue("");
            setSnackbarColor("success");
            setSnackbarText("Your message was sent successfully.");
        }
    } catch (err) {
        console.log(err);
        if (err.response.data.code === 400) {
            setShow(true);
            setSnackbarColor("error");
            setSnackbarText("There was a problem sending your message. Please try again later.");
        }
    }
  }

  
  return (
    <MKBox component="section" py={12}>
      <MKBox component="section">
        <Container>
            {
                snackbarColor === "success" && 
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={show}
                    autoHideDuration={3000}
                    onClose={hideSnackbar}
                    message={toastTemplate}
                    action={toggleSnackbar}
                    sx={successStyle}
                />
            }
            {
                snackbarColor === "error" && 
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={show}
                    autoHideDuration={3000}
                    onClose={hideSnackbar}
                    message={toastTemplate}
                    action={toggleSnackbar}
                    sx={errorStyle}
                />
            }
            
        </Container>
      </MKBox>
      <Container>
        <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
          <MKBox width="100%" component="form" method="post" autocomplete="off">
            <MKBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKInput 
                    label="Full Name"
                    fullWidth
                    value={fullNameValue}
                    onChange={(e) => {
                        setFullName(e.target.value);
                        if (e.target.value) {
                        if (e.target.value.length > 50) {
                            // if not valid name
                            if (validName) {
                            setValidFullName(false);
                            }
                        } else {
                            setValidFullName(true);
                        }
                        } else {
                            setValidFullName(false);
                        }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput
                    type="email"
                    label="Email"
                    fullWidth 
                    value={emailValue}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        if (e.target.value) {
                        if (e.target.value.length > 50) {
                            // if not valid name
                            if (validName) {
                                setValidEmail(false);
                            }
                        } else {
                            setValidEmail(true);
                        }
                        } else {
                            setValidEmail(false);
                        }
                    }}/>
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    label="How can we help you?"
                    multiline
                    fullWidth
                    rows={6}
                    value={textValue}
                    onChange={(e) => {
                        setText(e.target.value);
                        if (e.target.value) {
                            if (e.target.value.length > 250) {
                                // if not valid name
                                if (validName) {
                                    setValidText(false);
                                }
                            } else {
                                setValidText(true);
                            }
                        } else {
                            setValidText(false);
                        }
                    }}/>
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" xs={12} my={6}>
                <MKButton type="submit" variant="gradient" color="info" onClick={sendContactUsEmail}>
                  Send Message
                </MKButton>
              </Grid>
              {/*
              &nbsp;
              <Grid container item justifyContent="center" xs={12} my={6}>
                <MKBox alignItems="center" justifyContent="center">
                    <MKTypography variant="body1" color="text" align="center">Designated Agent: </MKTypography>
                  
                    <MKTypography variant="body1" color="text" align="center"> Evan Reilly - Fit to Scale, LLC </MKTypography>
                    
                    <MKTypography variant="body1" color="text" align="center">400 S 4th St Ste 410 PMB 404522</MKTypography>
                  
                    <MKTypography variant="body1" color="text" align="center">Minneapolis, Minnesota 55415-1419</MKTypography>
                  
                    <MKTypography variant="body1" color="text" align="center">service@fittoscale.io</MKTypography>
                    
                    <MKTypography variant="body1" color="text" align="center">(608)728-1207</MKTypography>
                </MKBox>
              </Grid>
                  */}
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ContactUsOne;
