import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MKBox from "components/MKBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import FormField from "layouts/account/components/FormField";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import axios from "axios";
// @mui icons
import CloseIcon from "@mui/icons-material/Close";

async function createProgram(programInfo) {
  if (programInfo.name === undefined || programInfo.name.trim().length === 0) {
    const message = "please enter a program name.";
    return { type: ["name"], message: [message] };
  } else if (programInfo.name.length > 32) {
    const message = "Please limit the program name to 32 characters or less.";
    return { type: ["name"], message: [message] };
  }
  const accessToken = localStorage.getItem("access_token");
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/program`, {
    name: programInfo.name,
    workouts: programInfo.workouts ? programInfo.workouts : [],
    refreshToken: programInfo.refreshToken,
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  return response;
}

function ProgramCreate() {
  var programLocal = JSON.parse(localStorage.getItem('program'));
  /* Navigation */
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigateToPrograms = () => navigate("/dashboards/trainer/programs");
  const navigateToAddWorkout = () => navigate("/dashboards/trainer/program/add-workout");
  
  /* Values */
  const [programName, setProgramName] = useState();
  const newWorkout = JSON.parse(searchParams.get("workout"));
  const [workoutTableData, setWorkoutTableData] = useState({
    columns: [
      { Header: "workout name", accessor: "workoutName", width: "25%" },
      { Header: "emphasis", accessor: "emphasis", width: "25%" },
      { Header: "exercises", accessor: "exercises", width: "25%" },
      { Header: "delete", accessor: "delete", width: "25%"},
    ],
    rows: [],
  });
  /* Validation */
  const [validName, setValidName] = useState(true);
  const [validWorkouts, setValidWorkouts] = useState();
  /* Text */
  const [nameHelpText, setNameHelpText] = useState();
  const [workoutHelpText, setWorkoutHelpText] = useState();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
  const hideSnackbar = () => setShowSnackbar(false);
  const [snackbarText, setSnackbarText] = useState();

  useEffect(() => {
    const fetchData = async() => await setWorkouts();
    fetchData().catch(console.error);
  }, []);

  async function setWorkouts() {
    programLocal = JSON.parse(localStorage.getItem('program'));

    if (!programLocal) {
        var workoutIds;
        if (JSON.parse(localStorage.getItem('program')) === null) {
          workoutIds = [];
        } else {
          workoutIds = JSON.parse(localStorage.getItem('program'));
        }
        // verify and get the workouts from the list of ids
        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');
        await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/workouts-by-ids`, {
          programId: undefined,
          workoutIds: workoutIds.workouts,
          refreshToken: refreshToken,
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then((response) => {
          for (var i=0; i<response.data.length; i++) {
            response.data[i].link = `/dashboards/trainer/workout/${response.data[i].id}`;
            response.data[i].deleteButtonFunction = "createDeleteWorkout";
          }
          setWorkoutTableData({
            columns: [
              { Header: "workout name", accessor: "workoutName", width: "25%" },
              { Header: "emphasis", accessor: "emphasis", width: "25%" },
              { Header: "exercises", accessor: "exercises", width: "25%" },
              { Header: "delete", accessor: "delete", width: "25%"},
            ],
            rows: response.data,
          });
          return Promise.resolve();
        })
        .catch((err) => {
          console.log(err);
          return Promise.reject();
        });
    } else {
      // populate exercise table data from local storage
      for (var i=0; i<programLocal.workouts.length; i++) {        
          programLocal.workouts[i].link = `/dashboards/trainer/workout/${programLocal.workouts[i].id}`;
          programLocal.workouts[i].deleteButtonFunction = "createDeleteWorkout";
      }
      var theProgramName =  programLocal.programName;
      setProgramName(theProgramName);
      setWorkoutTableData({
        columns: [
          { Header: "workout name", accessor: "workoutName", width: "25%" },
          { Header: "emphasis", accessor: "emphasis", width: "25%" },
          { Header: "exercises", accessor: "exercises", width: "25%" },
          { Header: "delete", accessor: "delete", width: "25%" },
        ],
        rows: programLocal.workouts,
      });
      return Promise.resolve();
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    var workoutIds;
    if (localStorage.getItem('program') === null) {
      workoutIds = {workouts: []};
    } else {
      workoutIds = JSON.parse(localStorage.getItem('program'));
    }
    // verify and get the workouts from the list of ids
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const response1 = await axios.post(`${process.env.REACT_APP_API_URL}/v1/trainer/workouts-by-ids`, {
      // programId: undefined,
      workoutIds: workoutIds.workouts,
      refreshToken: refreshToken,
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    var workouts = response1.data;

    await createProgram({
      name: programName,
      workouts: workouts,
      refreshToken: refreshToken,
    }).then((response) => {
      if (response.type && response.message) {
        for (let i = 0; i < response.type.length; i += 1) {
          if (response.type.length === response.message.length) {
            switch (response.type[i]) {
              case "name":
                setValidName(false);
                setNameHelpText(response.message[i]);
                return;
              case "exercises":
                setValidWorkouts(false);
                setWorkoutsHelpText(response.message[i]);
                return;
              default:
                break;
            }
          }
        }
      }
      if (response.status && response.status === 201) {
        setProgramName("");
        setWorkoutTableData({
          columns: [
            { Header: "workout name", accessor: "workoutName", width: "25%" },
            { Header: "emphasis", accessor: "emphasis", width: "25%" },
            { Header: "exercises", accessor: "exercises", width: "25%" },
            { Header: "delete", accessor: "delete", width: "25%" },
          ],
          rows: [],
        });
        localStorage.removeItem('program');
        navigate("/dashboards/trainer/programs");
      }
    }).catch((err) => {
      setSnackbarText(err.response.data);
      toggleSnackbar(true);
    });
  }

  const errorStyle = ({
    palette: { error },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: error.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
    },
  });

  const toastTemplate = (
    <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
      {snackbarText}
      <CloseIcon
        fontSize="medium"
        sx={{ ml: 4, mr: -1, cursor: "pointer" }}
        onClick={toggleSnackbar}
      />
    </MKBox>
  );

  return (
    <DashboardLayout>
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={hideSnackbar}
          message={toastTemplate}
          action={toggleSnackbar}
          sx={errorStyle}
      />
      <DashboardNavbar />
        <Grid mt={6}>
            <MDBox pb={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Create Program
              </MDTypography>
              <MDTypography variant="button" color="text">
                Customize your program by selecting a number of workouts
              </MDTypography>
            </MDBox>
            <MDBox component="form" pb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Program Name"
                    value={programName}
                    error={!validName}
                    helperText={nameHelpText}
                    onChange={(e)=>{
                      setProgramName(e.target.value);
                      if (e.target.value.length > 0) {
                        if (e.target.value.length > 32) {
                          if (validName) {
                            setValidName(false);
                            setNameHelpText("Please limit the program name to 32 characters or less.");
                          }
                        } else {
                          setValidName(true);
                          setNameHelpText("");
                        }
                      } else {
                        setValidName(false);
                        setNameHelpText("please enter a program name.");
                      }
                      var programLocal = JSON.parse(localStorage.getItem('program'));
                      if (!programLocal) {
                        localStorage.setItem('program', JSON.stringify({
                          programName: e.target.value,
                          workouts: [],
                        }));
                      }
                      programLocal.programName = e.target.value;
                      localStorage.setItem('program', JSON.stringify(programLocal));
                    }
                  }/>
                </Grid>
              </Grid>
            </MDBox>

            <Grid pb={1}>
              <MDButton
                  variant="gradient"
                  color="secondary"
                  size="small"
                  onClick={navigateBack}
                  pr={3}
                  >
                  Back
              </MDButton>
              &nbsp;
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={navigateToAddWorkout}
                pr={3}
              >
                Add Workout
              </MDButton>
              </Grid>
              <Grid pb={1}>
                <DataTable table={workoutTableData} />
              </Grid>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={handleSubmit}
                pr={3}
              >
                Create
              </MDButton>
          </Grid>  
      <Footer />
    </DashboardLayout>
  );
}

export default ProgramCreate;
