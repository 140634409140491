import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

let toggleModal = () => {};

function CustomModal(props) {
    const [show, setShow] = useState(false);
    toggleModal = () => setShow(!show);

    /* Navigation */
    const navigate = useNavigate();
    const navigateToClients = () => navigate(`/dashboards/trainer`);
    const navigateToManageSubscription = () => navigate(`/account/settings`);
    const navigateRedirect = () => navigate(props.redirect);

    const executeModalButtonFunction = () => {
        switch (props.buttonFunction) {
          case "deleteExercise":
            deleteExercise();
            break;
          case "deleteWorkout":
            deleteWorkout();
            break;
          case "deleteProgram":
            deleteProgram();
            break;
          case "navigateToClients":
            navigateToClients();
            break;
          case "navigateToManageSubscription":
            navigateToManageSubscription();
            break;
          default:
            //console.log(`Button function '${props.buttonFunction}' not recognized.`);
            break;
        }
    }
    // delete exercise
    const deleteExercise = async () => {
        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');

        const result = await axios.delete(`${process.env.REACT_APP_API_URL}/v1/trainer/exercise/${props.toDelete}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                refreshToken: refreshToken,
            }
        });
        if (result.status === 200) {
            // TODO: refactor such that we remove the element from the table instead of refreshing
            // (which ultimately makes unnecessary calls to the API)'
            navigateRedirect();
        }
    }
    // delete workout
    const deleteWorkout = async () => {
        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');

        const result = await axios.delete(`${process.env.REACT_APP_API_URL}/v1/trainer/workout/${props.toDelete}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                refreshToken: refreshToken,
            }
        });
        if (result.status === 200) {
            // TODO: refactor such that we remove the element from the table instead of refreshing
            // (which ultimately makes unnecessary calls to the API)'
            navigateRedirect();
        }
    }
    // delete program
    const deleteProgram = async () => {
        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');
        const result = await axios.delete(`${process.env.REACT_APP_API_URL}/v1/trainer/program/${props.toDelete}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                refreshToken: refreshToken,
            }
        });
        if (result.status === 200) {
            // TODO: refactor such that we remove the element from the table instead of refreshing
            // (which ultimately makes unnecessary calls to the API)'
            navigateRedirect();
        }
    }

    return (
        <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={show} timeout={500}>
              <MKBox
                position="relative"
                //width="500px"
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
              >
                <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                  <MKTypography variant="h5">Warning</MKTypography>
                  <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox p={2}>
                  <MKTypography variant="body2" color="secondary" fontWeight="regular">
                    {props.message}
                  </MKTypography>
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                  <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                    close
                  </MKButton>
                  <MKButton variant="gradient" color={props.buttonColor} onClick={executeModalButtonFunction}>
                    {props.buttonText}
                  </MKButton>
                </MKBox>
              </MKBox>
            </Slide>
        </Modal>
    )
}

export { toggleModal };

export default CustomModal;
