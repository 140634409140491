/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import burceMars from "assets/images/bruce-mars.jpg";

import axios from "axios";

function TraineeSubscriptions({ subscription }) {

    const [modalMessage, setModalMessage] = useState();
    const [modalButtonColor, setModalButtonColor] = useState("error");
    const [modalButtonText, setModalButtonText] = useState("delete");
    const [modalButtonFunction, setModalButtonFunction] = useState();
    const [clientToDelete, setClientToDelete] = useState();
    const [show, setShow] = useState(false);
    const toggleModal = () => setShow(!show);

    const cancelSubscription = async (e) => {
        e.preventDefault();
        const accessToken = localStorage.getItem("access_token");

        const session = await axios.post(`${process.env.REACT_APP_API_URL}/v1/billing_portal/sessions`,
        {
            trainerId: subscription.trainerId.toString(),
            customer: subscription.customerId,
            subscriptionId: subscription.subscriptionId,
            return_url: `${process.env.REACT_APP_FRONT_END_URL}/account/settings#subscriptions`,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        }).then((resp) => {
            window.location.reload();
        });
        if (session.data.portalSession?.url) {
            window.location.replace(session.data.portalSession.url);
        }
    };

    const pressCancelSubscription = async () => {
        setModalMessage("Your subscription will be cancelled at the end of the billing period. Are you sure you want to cancel your subscription?");
        setModalButtonColor("error");
        setModalButtonText("cancel");
        toggleModal();
    }

    return (

        <Card id="subscriptions">
            <MKBox component="section">
                <Container>
                    <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
                    <Slide direction="down" in={show} timeout={500}>
                        <MKBox
                        position="relative"
                        width="500px"
                        display="flex"
                        flexDirection="column"
                        borderRadius="xl"
                        bgColor="white"
                        shadow="xl"
                        >
                        <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                            <MKTypography variant="h5">Warning</MKTypography>
                            <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
                        </MKBox>
                        <Divider sx={{ my: 0 }} />
                        <MKBox p={2}>
                            <MKTypography variant="body2" color="secondary" fontWeight="regular">
                            {modalMessage}
                            </MKTypography>
                        </MKBox>
                        <Divider sx={{ my: 0 }} />
                        <MKBox display="flex" justifyContent="space-between" p={1.5}>
                            <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                            close
                            </MKButton>
                            <MKButton variant="gradient" color={modalButtonColor} onClick={cancelSubscription}>
                            {modalButtonText}
                            </MKButton>
                        </MKBox>
                        </MKBox>
                    </Slide>
                    </Modal>
                </Container>
             </MKBox>
            <MDBox p={2}>
                <Grid container spacing={3} alignItems="center">
                <Grid item>
                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                        Trainee Subscription
                    </MDTypography>
                    <MDTypography variant="button" color="text" fontWeight="medium">
                        Cancel your existing subscription
                    </MDTypography>
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                    <MDBox
                    display="flex"
                    justifyContent={{ md: "flex-end" }}
                    alignItems="center"
                    lineHeight={1}
                    >
                        <MDBox ml="auto">
                            <MDButton variant="gradient" color="dark" size="small" onClick={pressCancelSubscription}>
                                cancel subscription
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default TraineeSubscriptions;
